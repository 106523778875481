import React from 'react';

import { IFacebookProps } from './facebook.interfaces';

const Facebook: React.FC<IFacebookProps> = ({ post }) => {
  const {
    statistics: {
      actual: {
        likeCount,
        shareCount,
        commentCount,
        loveCount,
        wowCount,
        hahaCount,
        sadCount,
        angryCount,
        thankfulCount,
        careCount,
      },
    },
  } = post;

  return (
    <>
      <p className="post-popup__data-name">Likes</p>
      <p className="post-popup__data-content">{likeCount}</p>
      <p className="post-popup__data-name">Shares</p>
      <p className="post-popup__data-content">{shareCount}</p>
      <p className="post-popup__data-name">Comments</p>
      <p className="post-popup__data-content">{commentCount}</p>
      <p className="post-popup__data-name">Loves</p>
      <p className="post-popup__data-content">{loveCount}</p>
      <p className="post-popup__data-name">Wows</p>
      <p className="post-popup__data-content">{wowCount}</p>
      <p className="post-popup__data-name">Haha</p>
      <p className="post-popup__data-content">{hahaCount}</p>
      <p className="post-popup__data-name">Sad</p>
      <p className="post-popup__data-content">{sadCount}</p>
      <p className="post-popup__data-name">Angry</p>
      <p className="post-popup__data-content">{angryCount}</p>
      <p className="post-popup__data-name">Thankful</p>
      <p className="post-popup__data-content">{thankfulCount}</p>
      <p className="post-popup__data-name">Care</p>
      <p className="post-popup__data-content">{careCount}</p>
    </>
  );
};

export default Facebook;
