import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';

import { selectTopicAction } from '../../store/topics/topics.reducer';
import { Header, Navbar } from '../../shared/components';
import {
  Content,
  Filter,
  Grid,
  SavedViews,
  Sort,
  Summary,
} from './components';
import { setPostsAction } from '../../store/posts/posts.reducer';
import { getLabels } from '../../store/labels/labels.reducer';
import { StateType } from '../../store/store';
import { topicType } from '../../types/types';

import './topic-page.css';

const TopicPage = () => {
  const { topicId } = useParams<{ topicId: string }>();
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );
  const isLoading = useSelector<StateType, boolean>(
    (state) => state.posts.isLoading,
  );
  const topics = useSelector<StateType, Array<topicType>>(
    (state) => state.topics,
  );

  const [selectedTopic] = topics.filter(({ isSelected }) => isSelected);
  const titleValue = (
    selectedTopic && selectedTopic.name ? selectedTopic.name : '');

  useEffect(() => {
    dispatch(getLabels(loginToken));

    return () => {
      dispatch(setPostsAction([]));
    };
  }, []);
  useEffect(() => {
    if (+topicId && topics.length) {
      dispatch(selectTopicAction(+topicId));
    }
  }, [topicId, topics.length]);

  return (
    <div className="main__wrapper">
      <Header title={titleValue}>
        <div className={`topic__control-panel ${
          isLoading ? 'topic__block-lock' : ''
        }`}
        >
          <SavedViews topicId={+topicId} />
          <Filter />
          <Sort />
          <Grid />
        </div>
      </Header>
      <Navbar />
      {!Number.isNaN(+topicId) && <Content topicId={+topicId} />}
      <Summary topicId={+topicId} />
    </div>
  );
};

export default TopicPage;
