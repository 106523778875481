import React from 'react';

const StarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      stroke="#141414"
      strokeWidth="1.5"
      d="M7.388 10.31L7 10.076l-.388.235-3.113 1.882.826-3.545.103-.441-.342-.296-2.752-2.384 3.622-.307.45-.038.177-.417L7 1.425l1.417 3.34.177.417.45.038 3.628.308-2.75 2.383-.343.296.103.44.826 3.547-3.12-1.884z"
    />
  </svg>
);

export default StarIcon;
