import ActionTypes from '../../action-types/action-types';

type gridToggleType = {
  isGridOn: boolean,
}

export const initialState: gridToggleType = {
  isGridOn: false,
};

type InitialStateType = typeof initialState

export const gridToggleReducer = (
  state: InitialStateType = initialState,
  action: GridToggleActionType,
): InitialStateType => {
  switch (action.type) {
    case ActionTypes.SET_GRID:
      return { isGridOn: action.isGridOn };

    default:
      return { ...state };
  }
};

export const setGridAction = (
  isGridOn: boolean,
) => ({
  type: ActionTypes.SET_GRID,
  isGridOn,
} as const);

export type GridToggleActionType =
  | ReturnType <typeof setGridAction>;
