import { setFilterByAction } from '../store/filter/filter.reducer';
import { setSortByAction } from '../store/sort-by/sort-by.reducer';
import { setGridAction } from '../store/grid-toggle/grid-toggle.reducer';
import { viewType } from '../types/types';

const setDataFromView = (data: viewType, dispatch: any) => {
  dispatch(setFilterByAction(JSON.parse(data.filters)));
  dispatch(setSortByAction(JSON.parse(data.sorting)));
  dispatch(setGridAction(data.is_tile));
};

export default setDataFromView;
