import React from 'react';

import { ISourcesProps } from './sources.interfaces';

const getIndicatorClass = (length: number) => (length
  ? 'sources__is-on-indicator'
  : 'sources__is-off-indicator');

const Sources: React.FC<ISourcesProps> = ({ data }) => {
  const count = data.reduce((acc, source) => acc + source.count, 0);

  return (
    <div className="summary__sources">
      <div className="sources">
        <div className="sources__header">
          <div className="sources__header-item sources__header-item--source">
            Source
          </div>
          <div className="sources__header-item sources__header-item--posts">
            Posts
          </div>
          <div className="sources__header-item sources__header-item--value">
            Value
          </div>
        </div>
        <div className="sources__source-items">
          {data.map((source) => (
            <div className="sources__source-item" key={source.indicator}>
              <div className="sources__source-name-wrapper">
                <div
                  className={`sources__indicator sources__indicator--${source.indicator}`}
                />
                <div className="sources__source-name">{source.title}</div>
                <div className={getIndicatorClass(source.count)} />
              </div>
              <div className="sources__source-posts">{source.count}</div>
              <div className="sources__source-value">
                {`${((source.count / count || 0) * 100).toFixed(1)}%`}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sources;
