import ActionTypes from '../../action-types/action-types';
import { addNewTopicAction } from '../topics/topics.reducer';
import { AppThunkType } from '../store';
import authAPI from '../../api/api';
import {
  addNewTopicType,
  keywordType,
  excludedKeywordType,
} from '../../types/types';

const initialState = {
  name: '',
  description: '',
  keywords: [],
  excluded_keywords: [],
};

type InitialStateType = addNewTopicType;

export const addNewTopicReducer = (
  state: InitialStateType = initialState,
  action: AddNewTopicActionType,
): InitialStateType => {
  switch (action.type) {
    case ActionTypes.SET_NEW_TOPIC_NAME:
      return { ...state, name: action.name };

    case ActionTypes.SET_NEW_TOPIC_DESCRIPTION:
      return { ...state, description: action.description };

    case ActionTypes.SET_NEW_TOPIC_KEYWORDS:
      return { ...state, keywords: action.keywords };

    case ActionTypes.ADD_TOPIC_KEYWORD_BY_NAME: {
      const { keywords: curKeywords } = state;
      const names = curKeywords.map(({ name }) => name);
      const { keywordName } = action;

      if (!names.includes(keywordName)) {
        return { ...state, keywords: [...curKeywords, { name: keywordName }] };
      }
      return { ...state };
    }

    case ActionTypes.REMOVE_TOPIC_KEYWORD_BY_NAME: {
      const { keywords: curKeywords } = state;
      return {
        ...state,
        keywords: curKeywords.filter(
          ({ name }) => name !== action.keywordName,
        ),
      };
    }

    case ActionTypes.SET_NEW_TOPIC_EXCLUDED_KEYWORDS:
      return { ...state, excluded_keywords: action.excludedKeywords };

    case ActionTypes.ADD_TOPIC_EXCLUDED_KEYWORD_BY_NAME: {
      const { excluded_keywords: curKeywords } = state;
      const names = curKeywords.map(({ name }) => name);
      const { excludedKeywordName } = action;

      if (!names.includes(excludedKeywordName)) {
        return { ...state, excluded_keywords: [...curKeywords, { name: excludedKeywordName }] };
      }
      return { ...state };
    }

    case ActionTypes.REMOVE_TOPIC_EXCLUDED_KEYWORD_BY_NAME: {
      const { excluded_keywords: curKeywords } = state;
      return {
        ...state,
        excluded_keywords: curKeywords.filter(
          ({ name }) => name !== action.excludedKeywordName,
        ),
      };
    }

    case ActionTypes.RESET_NEW_TOPIC_STATE:
      return {
        ...state,
        name: '',
        description: '',
        keywords: [],
        excluded_keywords: [],
      };

    default:
      return { ...state };
  }
};

export const setTopicNameAction = (
  name: string,
) => ({
  type: ActionTypes.SET_NEW_TOPIC_NAME,
  name,
} as const);

export const setTopicDescriptionAction = (
  description: string,
) => ({
  type: ActionTypes.SET_NEW_TOPIC_DESCRIPTION,
  description,
} as const);

export const setTopicKeywordsAction = (
  keywords: Array<keywordType>,
) => ({
  type: ActionTypes.SET_NEW_TOPIC_KEYWORDS,
  keywords,
} as const);

export const AddTopicKeywordByNameAction = (
  keywordName: string,
) => ({
  type: ActionTypes.ADD_TOPIC_KEYWORD_BY_NAME,
  keywordName,
} as const);

export const removeTopicKeywordByNameAction = (
  keywordName: string,
) => ({
  type: ActionTypes.REMOVE_TOPIC_KEYWORD_BY_NAME,
  keywordName,
} as const);

export const AddTopicExcludedKeywordByNameAction = (
  excludedKeywordName: string,
) => ({
  type: ActionTypes.ADD_TOPIC_EXCLUDED_KEYWORD_BY_NAME,
  excludedKeywordName,
} as const);

export const removeTopicExcludedKeywordByNameAction = (
  excludedKeywordName: string,
) => ({
  type: ActionTypes.REMOVE_TOPIC_EXCLUDED_KEYWORD_BY_NAME,
  excludedKeywordName,
} as const);

export const setTopicExcludedKeywordsAction = (
  excludedKeywords: Array<excludedKeywordType>,
) => ({
  type: ActionTypes.SET_NEW_TOPIC_EXCLUDED_KEYWORDS,
  excludedKeywords,
} as const);

export const resetAddNewTopicStateAction = () => ({
  type: ActionTypes.RESET_NEW_TOPIC_STATE,
} as const);

export const createTopic = (
  token: string, data: addNewTopicType,
): AppThunkType => async (dispatch) => {
  const response = await authAPI.createTopicRequest(token, data);
  const { id } = response.data;
  dispatch(addNewTopicAction(data, id));
};

export type AddNewTopicActionType =
  | ReturnType<typeof setTopicNameAction>
  | ReturnType<typeof setTopicDescriptionAction>
  | ReturnType<typeof setTopicKeywordsAction>
  | ReturnType<typeof setTopicExcludedKeywordsAction>
  | ReturnType<typeof AddTopicKeywordByNameAction>
  | ReturnType<typeof removeTopicKeywordByNameAction>
  | ReturnType<typeof AddTopicExcludedKeywordByNameAction>
  | ReturnType<typeof removeTopicExcludedKeywordByNameAction>
  | ReturnType<typeof resetAddNewTopicStateAction>;
