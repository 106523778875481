import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import SmallRedButton from '../../../../../../shared/components/buttons/small-red-button';
import SmallWhiteButton from '../../../../../../shared/components/buttons/small-white-button';
import Input from '../../../../../../shared/components/Input/input';
import { IAddNewViewProps } from './add-new-view.interfaces';
import { StateType } from '../../../../../../store/store';
import { filterReducerType } from '../../../../../../store/filter/filter.reducer';
import { sortByType } from '../../../../../../store/sort-by/sort-by.reducer';
import { addNewViewType } from '../../../../../../types/types';

import './add-new-view.css';

const AddNewView: React.FC<IAddNewViewProps> = ({
  topicId,
  onClose,
  onSubmit,
}) => {
  const [newViewName, setNewViewName] = useState('New View Name');

  const filterState = useSelector<StateType, filterReducerType>(
    (state) => state.filter,
  );
  const sortByState = useSelector<StateType, Array<sortByType>>(
    (state) => state.sortBy,
  );
  const isGridOn = useSelector<StateType, boolean>(
    (state) => state.gridToggle.isGridOn,
  );

  const onChangeNewViewNameInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setNewViewName(value);
  };

  const onClickSaveViewButton = () => {
    const data: addNewViewType = {
      name: newViewName,
      filters: JSON.stringify(filterState),
      sorting: JSON.stringify(sortByState),
      is_tile: isGridOn,
      is_active: true,
      topic_id: topicId,
    };

    onSubmit(data);
  };

  return (
    <>
      <h3 className="add-new-view__title">Save new view</h3>
      <p className="add-new-view__text">View name</p>
      <Input
        value={newViewName}
        type="text"
        placeholder="Enter new view name"
        style={{ height: 48 }}
        onChangeCallback={onChangeNewViewNameInput}
      />
      <div className="add-new-view__button-wrapper">
        <SmallWhiteButton onClick={onClose}>Cancel</SmallWhiteButton>
        <SmallRedButton
          style={{ marginRight: 0 }}
          onClick={onClickSaveViewButton}
        >
          Save view
        </SmallRedButton>
      </div>
    </>
  );
};

export default AddNewView;
