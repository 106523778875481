import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IRecoveryForm } from './components/form/form.interfaces';
import {
  setErrorFlagAction,
  setErrorMessageAction,
} from '../../store/error/error.reducer';
import { axiosGetError } from '../../helpers/axios-errors';
import { Form } from './components';
import authAPI from '../../api/api';

import './recovery-page.css';

const RecoveryPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onCancelClickButton = () => {
    history.push('/login');
  };

  const onSendClickButton = ({ email }: IRecoveryForm) => {
    if (email) {
      let message = '';

      authAPI
        .passwordRecoveryRequest(email)
        .then(() => {
          message = 'Recovery link has been sent to your email';
          history.push('/login');
        })
        .catch((error) => {
          message = axiosGetError(error);
        })
        .finally(() => {
          dispatch(setErrorFlagAction(true));
          dispatch(setErrorMessageAction(message));
        });
    }
  };

  return (
    <div className="recovery__wrapper">
      <form className="recovery" onSubmit={(e) => e.preventDefault()}>
        <h2 className="recovery__title">Reset Your Password</h2>
        <p className="recovery__text">
          Please enter your email address to send password recovery link
        </p>
        <Form onCancel={onCancelClickButton} onSubmit={onSendClickButton} />
      </form>
    </div>
  );
};

export default RecoveryPage;
