import ActionTypes from '../../action-types/action-types';
import { AppThunkType } from '../store';
import API from '../../api/api';
import {
  labelFromServerType,
  labelToPostType,
} from '../../types/types';

const initialState: Array<labelToPostType> = [];

type InitialStateType = typeof initialState;

export const addLabelPopupReducer = (
  state: InitialStateType = initialState,
  action: AddLabelPopupActionType,
): InitialStateType => {
  switch (action.type) {
    case ActionTypes.CLOSE_LABEL_CHOOSER:
      return state.map(
        (el) => ({ ...el, isChooserOpen: false }),
      );

    case ActionTypes.OPEN_LABEL_CHOOSER:
      return state.map(
        (el) => (el.id === action.id
          ? { ...el, isChooserOpen: true }
          : el),
      );

    case ActionTypes.MAKE_AGREE_LABEL:
      return state.map(
        (el) => (el.id === action.id
          ? {
            ...el, isAgree: true, isDisagree: false, isVoted: true,
          }
          : el),
      );

    case ActionTypes.MAKE_DISAGREE_LABEL:
      return state.map(
        (el) => (el.id === action.id
          ? {
            ...el, isAgree: false, isDisagree: true, isVoted: true,
          }
          : el),
      );

    case ActionTypes.SET_LABELS_TO_ADD_TO_POST:
      return [...action.labels.map((el) => (
        {
          ...el,
          isVoted: false,
          isAgree: false,
          isDisagree: false,
          isChooserOpen: false,
        }
      ))];

    default:
      return [...state];
  }
};

export const closeLabelChooserAction = (
) => ({
  type: ActionTypes.CLOSE_LABEL_CHOOSER,
} as const);

export const openLabelChooserAction = (
  id: number,
) => ({
  type: ActionTypes.OPEN_LABEL_CHOOSER,
  id,
} as const);

export const makeAgreeLabelAction = (
  id: number,
) => ({
  type: ActionTypes.MAKE_AGREE_LABEL,
  id,
} as const);

export const makeDisagreeLabelAction = (
  id: number,
) => ({
  type: ActionTypes.MAKE_DISAGREE_LABEL,
  id,
} as const);

export const setLabelsToAddToPostAction = (
  labels: Array<labelFromServerType>,
) => ({
  type: ActionTypes.SET_LABELS_TO_ADD_TO_POST,
  labels,
}as const);

export const getLabelsToAddToPost = (
  token: string,
): AppThunkType => async (dispatch) => {
  const response = await API.getAllLabelsRequest(token);
  dispatch(setLabelsToAddToPostAction(response.data));
};

export type AddLabelPopupActionType =
  | ReturnType<typeof closeLabelChooserAction>
  | ReturnType<typeof openLabelChooserAction>
  | ReturnType<typeof makeDisagreeLabelAction>
  | ReturnType<typeof makeAgreeLabelAction>
  | ReturnType<typeof setLabelsToAddToPostAction>;
