/* eslint-disable camelcase */
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TokenService } from '../services';
import { authAction } from '../store/auth/auth.reducer';
import { logoutAction } from '../store/login/login.reducer';
import API, { instance } from './api';

type refreshType = ReturnType<typeof API.refreshAccessTokenRequest> | null;

let refreshInProgress: refreshType = null;
let responseInterceptor: number | null = null;

const useApiInterception = () => {
  const dispatch = useDispatch();

  responseInterceptor = instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const prevRequest = error.config as AxiosRequestConfig & {
        sent: boolean;
      };

      if (
        error.response?.status === 422
        && prevRequest.url === 'auth/refresh'
      ) {
        dispatch(logoutAction());

        return Promise.reject(error);
      }

      if (
        error.response?.status === 422
        && !prevRequest.sent
      ) {
        prevRequest.sent = true;

        const { refreshToken } = TokenService.getTokens();

        if (!refreshInProgress) {
          refreshInProgress = API.refreshAccessTokenRequest(refreshToken);
        }

        const {
          data: { access_token },
        } = await refreshInProgress;

        if (refreshInProgress as refreshType) {
          TokenService.saveTokens(access_token, refreshToken);
          dispatch(authAction({ accessToken: access_token, refreshToken }));
        }

        prevRequest.headers!.Authorization = `Bearer ${access_token}`;
        refreshInProgress = null;

        return instance(prevRequest);
      }

      return Promise.reject(error);
    },
  );

  useEffect(
    () => () => {
      if (responseInterceptor) {
        instance.interceptors.response.eject(responseInterceptor);
        responseInterceptor = null;
      }
    },
    [],
  );
};

export default useApiInterception;
