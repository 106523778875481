import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setNewPasswordAction } from '../../store/auth/auth.reducer';
import { ISetNewPasswordForm } from './components/form/form.interfaces';
import { Form } from './components';

import './set-new-password-page.css';

const SetNewPasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const token = history.location.search.replace('?', '').split('=')[1];

  const onCancelClickButton = () => {
    history.push('/');
  };

  const onSetClickButton = ({ password }: ISetNewPasswordForm) => {
    dispatch(setNewPasswordAction(password, token));
    history.push('/login');
  };

  return (
    <div className="set-new-password__wrapper">
      <form className="set-new-password" onSubmit={(e) => e.preventDefault()}>
        <h2 className="set-new-password__title">Set A New Password</h2>
        <Form onCancel={onCancelClickButton} onSubmit={onSetClickButton} />
      </form>
    </div>
  );
};

export default SetNewPasswordPage;
