import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { topicType } from '../../../../../types/types';
import { StateType } from '../../../../../store/store';
import { IEditTopicProps } from './edit-topic.interfaces';
import {
  addEditTopicExcludedKeywordByNameAction,
  addEditTopicKeywordByNameAction,
  removeEditTopicExcludedKeywordByNameAction,
  removeEditTopicKeywordByNameAction,
  setEditTopicDescriptionAction,
  setEditTopicNameAction,
} from '../../../../../store/edit-topic/edit-topic.reducer';
import { ManageTopic } from '..';

const EditTopic: React.FC<IEditTopicProps> = ({ onCancel, onSubmit }) => {
  const dispatch = useDispatch();

  const topic = useSelector<StateType, topicType>((state) => state.editTopic);

  const onSaveButton = () => onSubmit(topic);
  const onChangeNameTopic = (value: string) => {
    dispatch(setEditTopicNameAction(value));
  };
  const onChangeDescription = (value: string) => {
    dispatch(setEditTopicDescriptionAction(value));
  };
  const onAddSavedKeyword = (keyword: string) => {
    dispatch(addEditTopicKeywordByNameAction(keyword));
  };
  const onRemoveSavedKeyword = (keyword: string) => {
    dispatch(removeEditTopicKeywordByNameAction(keyword));
  };
  const onAddSavedExcludedKeyword = (keyword: string) => {
    dispatch(addEditTopicExcludedKeywordByNameAction(keyword));
  };
  const onRemoveSavedExcludedKeyword = (keyword: string) => {
    dispatch(removeEditTopicExcludedKeywordByNameAction(keyword));
  };

  return (
    <ManageTopic
      title="Edit topic"
      topic={topic}
      onCancel={onCancel}
      onSubmit={onSaveButton}
      onChangeTopic={onChangeNameTopic}
      onChangeDescription={onChangeDescription}
      onAddTopicKeyword={onAddSavedKeyword}
      onAddTopicExcludedKeyword={onAddSavedExcludedKeyword}
      onRemoveTopicKeyword={onRemoveSavedKeyword}
      onRemoveTopicExcludedKeyword={onRemoveSavedExcludedKeyword}
    />
  );
};

export default EditTopic;
