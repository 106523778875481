import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ISummaryCount } from '../../../../../../interfaces/server/summary';
import { StateType } from '../../../../../../store/store';
import { IFilterProps } from './filter.interfaces';

const Filter: React.FC<IFilterProps> = ({ onChange }) => {
  const [isActiveTab, setIsActiveTab] = useState(4);

  const isLoading = useSelector<StateType, boolean>(
    (state) => state.posts.isLoading,
  );
  const stats = useSelector<StateType, ISummaryCount | null>(
    (state) => state.posts.stats,
  );

  const getPostsBy = (
    id: number, timeRange: moment.unitOfTime.DurationConstructor | null,
  ) => () => {
    onChange(timeRange);
    setIsActiveTab(id);
  };

  const getActiveTabClass = (id: number) => (isActiveTab === id ? 'summary__toggle-button--active' : '');

  useEffect(() => {
    if (!stats) {
      setIsActiveTab(4);
    }
  }, [stats]);

  return (
    <div className={`summary__toggle-wrapper ${(
      isLoading ? 'summary__toggle-disabled' : ''
    )}`}
    >
      <button
        type="button"
        className={`summary__toggle-button ${getActiveTabClass(1)}`}
        onClick={getPostsBy(1, 'hour')}
      >
        Hour
      </button>
      <button
        type="button"
        className={`summary__toggle-button ${getActiveTabClass(2)}`}
        onClick={getPostsBy(2, 'day')}
      >
        Day
      </button>
      <button
        type="button"
        className={`summary__toggle-button ${getActiveTabClass(3)}`}
        onClick={getPostsBy(3, 'week')}
      >
        Week
      </button>
      <button
        type="button"
        className={`summary__toggle-button ${getActiveTabClass(4)}`}
        onClick={getPostsBy(4, null)}
      >
        All
      </button>
    </div>
  );
};

export default React.memo(Filter);
