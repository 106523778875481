import React from 'react';

const UpDownArrowsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="14"
    fill="none"
    viewBox="0 0 10 14"
  >
    <path
      fill="#141414"
      d="M8 10.757V6.25a.752.752 0 00-.75-.75.752.752 0 00-.75.75v4.507H5.158a.371.371 0 00-.263.638l2.093 2.085c.15.142.382.142.532 0l2.093-2.085c.24-.233.067-.638-.263-.638H8zM2.488.512L.395 2.605a.371.371 0 00.263.637H2V7.75c0 .412.338.75.75.75.413 0 .75-.338.75-.75V3.242h1.343a.371.371 0 00.262-.637L3.013.512a.376.376 0 00-.525 0z"
    />
  </svg>
);

export default UpDownArrowsIcon;
