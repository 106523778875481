import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  makeHeaderFilterOpinionOffAction,
  makeHeaderFilterOpinionOnAction,
} from '../../../../../../store/labels-header-filter/labels-header-filter.reducer';
import { StateType } from '../../../../../../store/store';

const Filter = () => {
  const dispatch = useDispatch();

  const isOpinionsOn = useSelector<StateType, boolean>(
    (state) => state.labelsHeaderFilter.isOpinionsOn,
  );

  const onOpinionsButton = () => {
    dispatch(makeHeaderFilterOpinionOnAction());
  };
  const onThemesButton = () => {
    dispatch(makeHeaderFilterOpinionOffAction());
  };

  return (
    <div className="labels-header__toggle-labels-container">
      <button
        type="button"
        className={
          isOpinionsOn
            ? 'labels-header__toggle-button labels-header__toggle-button--active'
            : 'labels-header__toggle-button'
        }
        name="labels-header-filter-opinions"
        onClick={onOpinionsButton}
      >
        Opinions
      </button>
      <button
        type="button"
        className={
          !isOpinionsOn
            ? 'labels-header__toggle-button labels-header__toggle-button--active'
            : 'labels-header__toggle-button'
        }
        onClick={onThemesButton}
        name="labels-header-filter-themes"
      >
        Themes
      </button>
    </div>
  );
};

export default Filter;
