import { useHistory, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import FilterIcon from '../../../../shared/icons/filter-icon';
import { triggerGetPostsAction } from '../../../../store/content/content.reducer';
import Modal from '../../../../shared/components/modal/modal';
import { FilterModal } from './components';
import { clearStats } from '../../../../store/posts/posts.reducer';

const Filter = () => {
  const [isFilterOpen, setFilterOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const onToggleFilter = () => setFilterOpen(!isFilterOpen);
  const onSubmitFilter = () => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete('page');
    history.replace({ search: queryParams.toString() });
    dispatch(clearStats());
    dispatch(triggerGetPostsAction());
    setFilterOpen(false);
  };

  return (
    <>
      <button
        type="button"
        className="topic__control-filter"
        onClick={onToggleFilter}
      >
        <span className="filter__icon-wrapper">
          <FilterIcon />
        </span>
        Filter
      </button>
      <Modal open={isFilterOpen} onClose={onToggleFilter} className="topic__control-filter-modal">
        <FilterModal onClose={onToggleFilter} onSubmit={onSubmitFilter} />
      </Modal>
    </>
  );
};

export default Filter;
