import React from 'react';

import { ITwitterProps } from './twitter.interfaces';

const Twitter: React.FC<ITwitterProps> = ({ post }) => {
  const {
    publicMetrics: {
      likeCount,
      replyCount,
      retweetCount,
      quoteCount,
    },
  } = post;

  return (
    <>
      <p className="post-popup__data-name">Likes</p>
      <p className="post-popup__data-content">{likeCount}</p>
      <p className="post-popup__data-name">Retweets</p>
      <p className="post-popup__data-content">{retweetCount}</p>
      <p className="post-popup__data-name">Replies</p>
      <p className="post-popup__data-content">{replyCount}</p>
      <p className="post-popup__data-name">Quotes</p>
      <p className="post-popup__data-content">{quoteCount}</p>
    </>
  );
};

export default Twitter;
