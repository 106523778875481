import React, { ChangeEvent, useState } from 'react';

import SmallWhiteButton from '../../../../shared/components/buttons/small-white-button';
import SmallRedButton from '../../../../shared/components/buttons/small-red-button';
import Input from '../../../../shared/components/Input/input';
import { ISetNewPasswordForm, ISetNewPasswordFormProps } from './form.interfaces';

const initialForm: ISetNewPasswordForm = {
  password: '',
  repeatPassword: '',
};

const Form: React.FC<ISetNewPasswordFormProps> = ({ onCancel, onSubmit }) => {
  const [fields, setFields] = useState(initialForm);

  const { password, repeatPassword } = fields;

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFields((oldFields) => ({ ...oldFields, [name]: value }));
  };

  return (
    <>
      <Input
        value={password}
        type="password"
        name="password"
        placeholder="Enter new password"
        onChangeCallback={onChangeInput}
      />
      <Input
        value={repeatPassword}
        type="password"
        name="repeatPassword"
        placeholder="Repeat new password"
        onChangeCallback={onChangeInput}
      />
      <div className="reg__button-wrapper">
        <SmallWhiteButton onClick={onCancel}>Cancel</SmallWhiteButton>
        <SmallRedButton type="submit" onClick={() => onSubmit(fields)}>Set</SmallRedButton>
      </div>
    </>
  );
};

export default Form;
