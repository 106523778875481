import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { setEditTopicInitialStateAction } from '../../../store/edit-topic/edit-topic.reducer';
import { triggerGetPostsAction } from '../../../store/content/content.reducer';
import BigRedButton from '../buttons/big-red-button';
import {
  closeEditTopicAction,
  deleteTopic,
  getTopics,
  openEditTopicAction,
  putTopic,
} from '../../../store/topics/topics.reducer';
import {
  createTopic,
  resetAddNewTopicStateAction,
} from '../../../store/add-new-topic/add-new-topic.reducer';
import AddNewTopic from './components/add-new-topic/add-new-topic';
import { addNewTopicType, topicType } from '../../../types/types';
import { setLoadingAction, setPostsAction } from '../../../store/posts/posts.reducer';
import { EditTopic, NavList, TopicItem } from './components';
import { StateType } from '../../../store/store';
import Modal from '../modal/modal';

import './navbar.css';

const Navbar = () => {
  const [modalNewTopic, setModalNewTopic] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const topics = useSelector<StateType, Array<topicType>>(
    (state) => state.topics,
  );
  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );

  const [topicEditMode] = topics.filter((topic) => topic.isEditTopicModalOpen);

  const onModalNewTopicToggle = () => setModalNewTopic(!modalNewTopic);
  const onSubmitAddNewTopic = (topic: addNewTopicType) => {
    dispatch(createTopic(loginToken, topic));
    onModalNewTopicToggle();
    dispatch(resetAddNewTopicStateAction());
  };
  const onCancelEditTopic = () => {
    dispatch(closeEditTopicAction());
  };
  const onSubmitEditTopic = (topic: topicType) => {
    dispatch(putTopic(loginToken, topic.id, topic));
    dispatch(closeEditTopicAction());
    dispatch(triggerGetPostsAction());
  };

  const onEditButton = (topic: topicType) => {
    dispatch(setEditTopicInitialStateAction(topic));
    dispatch(openEditTopicAction(topic.id));
  };
  const onDeleteButton = async (topic: topicType) => {
    dispatch(setLoadingAction(true));
    await dispatch(deleteTopic(loginToken, topic.id));
    dispatch(setPostsAction([]));
    dispatch(setLoadingAction(false));
    history.push('/panel');
  };

  useEffect(() => {
    if (!topics.length) {
      dispatch(getTopics(loginToken));
    }
  }, []);

  return (
    <div className="navbar__wrapper">
      <div className="navbar__scroll">
        <div className="navbar">
          <div className="topic__block">
            <h2 className="navbar__title">
              <Link to="/panel">Information Dashboard</Link>
            </h2>
            <div className="navbar__header">
              <h3 className="navbar__title-topics">Topics</h3>
              <select name="sort" className="topic__sort">
                <option value="">Sort by attention</option>
                <option value="">Sort by attention</option>
                <option value="">Sort by attention</option>
              </select>
            </div>
            <BigRedButton onClick={onModalNewTopicToggle}>
              Add new topic
            </BigRedButton>
            <div className="topics__list">
              {topics.map((topic) => (
                <TopicItem
                  key={topic.id}
                  data={topic}
                  onEdit={onEditButton}
                  onDelete={onDeleteButton}
                />
              ))}
            </div>
          </div>
          <NavList />
          <Modal open={modalNewTopic} onClose={onModalNewTopicToggle}>
            <AddNewTopic
              onCancel={onModalNewTopicToggle}
              onSubmit={onSubmitAddNewTopic}
            />
          </Modal>
          <Modal
            open={topicEditMode && topicEditMode.isEditTopicModalOpen}
            onClose={onCancelEditTopic}
          >
            <EditTopic
              onCancel={onCancelEditTopic}
              onSubmit={onSubmitEditTopic}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
