import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React from 'react';

import { loginCredentials } from '../../store/login/login.reducer';
import { Form, ILoginForm } from './components';

import './login-page.css';

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onLoginClickButton = ({ username, password }: ILoginForm) => {
    dispatch(loginCredentials(username, password));
  };

  const onClickBackAuthButton = () => {
    history.push('/');
  };

  return (
    <div className="login__wrapper">
      <form className="login" onSubmit={(e) => e.preventDefault()}>
        <h2 className="log_title">Sign In</h2>
        <Form onCancel={onClickBackAuthButton} onSubmit={onLoginClickButton} />
        <NavLink to="/recovery" className="login__recovery-link">
          Forgot a password?
        </NavLink>
      </form>
    </div>
  );
};

export default LoginPage;
