/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, KeyboardEvent, useState } from 'react';

import CloseIcon from '../../../../icons/close-icon';
import { IKeywordInputProps } from './keyword-input.interfaces';

const KeywordInput: React.FC<IKeywordInputProps> = ({
  title,
  list,
  keywords,
  onAdd,
  onRemove,
}) => {
  const [isSavedKeywordsOpen, setSavedKeywordsOpen] = useState(false);
  const [savedKeywords, setSavedKeywords] = useState(list);
  const [inputKeyword, setInputKeyword] = useState('');

  const name = title.replace(' ', '');

  const onChangeKeyword = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInputKeyword(value);
  };
  const removeKeyword = (keywordToRemove: string) => onRemove(keywordToRemove);
  const addKeyword = (keyword: string) => onAdd(keyword);
  const onEnterKeyword = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputKeyword) {
      addKeyword(inputKeyword);
      setInputKeyword('');
    }
  };
  const moveKeywordFromSaved = (keyword: string) => {
    addKeyword(keyword);
    setSavedKeywords(
      savedKeywords.filter((savedKeyword: string) => savedKeyword !== keyword),
    );
  };

  return (
    <>
      <label htmlFor={`ID_${name}`}>{title}</label>
      <div
        className="addNewTopic__keywords-input-wrapper"
        onFocusCapture={() => setSavedKeywordsOpen(true)}
      >
        <div className="addNewTopic__keywords-input-container">
          <ul className="addNewTopic__keywords-list">
            {keywords.map((keyword) => (
              <li key={keyword} className="addNewTopic__keywords-item">
                <span className="addNewTopic__keywords-item-keyword">
                  {keyword}
                </span>
                <div
                  role="button"
                  tabIndex={0}
                  className="addNewTopic__keywords-item-close"
                  onClick={() => removeKeyword(keyword)}
                  onKeyPress={() => removeKeyword(keyword)}
                >
                  <CloseIcon />
                </div>
              </li>
            ))}
          </ul>
          <input
            id={`ID_${name}`}
            type="text"
            name={name}
            placeholder="Add keywords..."
            value={inputKeyword}
            className="addNewTopic__keywords-input"
            onChange={onChangeKeyword}
            onKeyUp={onEnterKeyword}
          />
        </div>
        <div
          className="addNewTopic__keywords-saved-keywords-wrapper"
          onFocus={() => setSavedKeywordsOpen(true)}
          onMouseEnter={() => setSavedKeywordsOpen(true)}
          onMouseLeave={() => setSavedKeywordsOpen(false)}
        >
          {isSavedKeywordsOpen && savedKeywords.length && (
            <div className="addNewTopic__saved-keywords">
              {savedKeywords.map((keyword) => (
                <div
                  key={keyword}
                  role="button"
                  tabIndex={0}
                  className="addNewTopic__saved-keyword"
                  onClick={() => moveKeywordFromSaved(keyword)}
                  onKeyPress={() => moveKeywordFromSaved(keyword)}
                >
                  {keyword}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <p className="addNewTopic__keywords-helperText">
        Topics should include any of the keywords
      </p>
    </>
  );
};

export default KeywordInput;
