import ActionTypes from '../../action-types/action-types';

const initialState = {
  isSortByDateCreation: true,
  isOpinionsOn: true,
};

type initialStateType = typeof initialState;

export const labelsHeaderFilterReducer = (
  state: initialStateType = initialState,
  action: LabelsHeaderFilterActionType,
): initialStateType => {
  switch (action.type) {
    case ActionTypes.MAKE_HEADER_FILTER_OPINION_OFF:
      return { ...state, isOpinionsOn: false };

    case ActionTypes.MAKE_HEADER_FILTER_OPINION_ON:
      return { ...state, isOpinionsOn: true };

    case ActionTypes.MAKE_HEADER_FILTER_LABELS_SORT_BY_DATE_OFF:
      return { ...state, isSortByDateCreation: false };

    case ActionTypes.MAKE_HEADER_FILTER_LABELS_SORT_BY_DATE_ON:
      return { ...state, isSortByDateCreation: true };

    case ActionTypes.SET_HEADER_FILTER_LABELS_SORT_BY_DATE:
      return { ...state, isSortByDateCreation: action.isSortByDateCreation };

    default:
      return { ...state };
  }
};

export const makeHeaderFilterOpinionOffAction = (
) => ({
  type: ActionTypes.MAKE_HEADER_FILTER_OPINION_OFF,
} as const);

export const makeHeaderFilterOpinionOnAction = (
) => ({
  type: ActionTypes.MAKE_HEADER_FILTER_OPINION_ON,
} as const);

export const makeHeaderFilterLabelsSortByDateOffAction = (
) => ({
  type: ActionTypes.MAKE_HEADER_FILTER_LABELS_SORT_BY_DATE_OFF,
} as const);

export const makeHeaderFilterLabelsSortByDateOnAction = (
) => ({
  type: ActionTypes.MAKE_HEADER_FILTER_LABELS_SORT_BY_DATE_ON,
} as const);

export const setHeaderFilterLabelsSortByDateAction = (
  isSortByDateCreation: boolean,
) => ({
  type: ActionTypes.SET_HEADER_FILTER_LABELS_SORT_BY_DATE,
  isSortByDateCreation,
} as const);

export type LabelsHeaderFilterActionType =
  | ReturnType<typeof makeHeaderFilterOpinionOffAction>
  | ReturnType<typeof makeHeaderFilterOpinionOnAction>
  | ReturnType<typeof makeHeaderFilterLabelsSortByDateOffAction>
  | ReturnType<typeof makeHeaderFilterLabelsSortByDateOnAction>
  | ReturnType<typeof setHeaderFilterLabelsSortByDateAction>;
