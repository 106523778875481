/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  closeLabelChooserAction,
  makeAgreeLabelAction,
  makeDisagreeLabelAction,
  openLabelChooserAction,
} from '../../../../../../../../../../store/add-label-popup/add-label-popup.reducer';
import { addLabelOpinionToPost } from '../../../../../../../../../../store/posts/posts.reducer';
import { StateType } from '../../../../../../../../../../store/store';
import { ITabComponentProps } from '../../add-label-popup.interfaces';
import {
  Actions,
  Popover,
} from '../../../../../../../../../../shared/components';

const OpinionsTab: React.FC<ITabComponentProps> = ({ list, post }) => {
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );

  const opinions = list.filter((el) => el.type === 'Opinion');

  const onClickByOpinion = (id: number, isOpen: boolean) => () => {
    dispatch(closeLabelChooserAction());

    if (!isOpen) {
      dispatch(openLabelChooserAction(id));
    }
  };
  const onClickByAgreeOpinion = (id: number) => (
    () => {
      dispatch(makeAgreeLabelAction(id));
      dispatch(addLabelOpinionToPost(loginToken, post.type, post.id, id, true));
      dispatch(closeLabelChooserAction());
    }
  );
  const onClickByDisagreeOpinion = (id: number) => (
    () => {
      dispatch(makeDisagreeLabelAction(id));
      dispatch(addLabelOpinionToPost(loginToken, post.type, post.id, id, false));
      dispatch(closeLabelChooserAction());
    }
  );

  return (
    <>
      {opinions.map(({ id, name, isChooserOpen }) => (
        <Popover
          key={id}
          open={isChooserOpen}
          position="bottom"
          content={(
            <Actions
              className="add-label-popup__actions"
              actions={{ agree: true, disagree: true }}
              onDisagree={onClickByDisagreeOpinion(id)}
              onAgree={onClickByAgreeOpinion(id)}
            />
          )}
          onClickOutside={onClickByOpinion(id, isChooserOpen)}
        >
          <div
            role="button"
            tabIndex={0}
            className="add-label-popup__opinion"
            onClick={onClickByOpinion(id, isChooserOpen)}
            onKeyPress={onClickByOpinion(id, isChooserOpen)}
          >
            {name}
          </div>
        </Popover>
      ))}
    </>
  );
};

export default OpinionsTab;
