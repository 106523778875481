import React from 'react';

import { IThemeItemSimpleProps } from './theme-item-simple.interfaces';

const ThemeItemSimple: React.FC<IThemeItemSimpleProps> = ({ name }) => (
  <div className="post__label-theme">
    <h5 className="post__label-theme-title">Theme</h5>
    <p className="post__label-theme-text">{name}</p>
  </div>
);

export default ThemeItemSimple;
