import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import {
  filterReducerType,
  initialState as filterInitialState,
} from '../../../../../../store/filter/filter.reducer';
import {
  initialState as sortByInitialState,
  sortByType,
} from '../../../../../../store/sort-by/sort-by.reducer';
import { initialState as gridOnInitialState } from '../../../../../../store/grid-toggle/grid-toggle.reducer';
import { putView } from '../../../../../../store/manage-views/manage-views.reducer';
import { StateType } from '../../../../../../store/store';
import { viewType } from '../../../../../../types/types';
import { IViewActionsProps } from './view-actions.interfaces';

const ViewActions: React.FC<IViewActionsProps> = ({
  view,
  onOpenManage,
  onOpenAddView,
  onUpdateView,
  onResetView,
}) => {
  const dispatch = useDispatch();

  const filterState = useSelector<StateType, filterReducerType>(
    (state) => state.filter,
  );
  const sortByState = useSelector<StateType, Array<sortByType>>(
    (state) => state.sortBy,
  );
  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );
  const isGridOn = useSelector<StateType, boolean>(
    (state) => state.gridToggle.isGridOn,
  );

  const [viewId, viewName] = view ? [view.id, view.name] : [-1, ''];

  const updateCurrentView = () => {
    if (viewId !== -1) {
      const data: viewType = {
        name: viewName,
        filters: JSON.stringify(filterState),
        sorting: JSON.stringify(sortByState),
        is_tile: isGridOn,
        is_active: true,
      };

      dispatch(putView(loginToken, viewId, data));
    }

    onUpdateView();
  };

  const resetCurrentView = () => {
    if (view) {
      const data: viewType = {
        name: viewName,
        filters: JSON.stringify(filterInitialState),
        sorting: JSON.stringify(sortByInitialState),
        is_tile: gridOnInitialState.isGridOn,
        is_active: true,
      };

      dispatch(putView(loginToken, viewId, data));
    }

    onResetView();
  };

  return (
    <>
      <div className="saved-views-bottom-container">
        <div
          role="button"
          tabIndex={0}
          className="saved-view-container"
          onClick={updateCurrentView}
          onKeyPress={updateCurrentView}
        >
          <p className="saved-view-name">{`Update ${viewName}`}</p>
        </div>
        <div
          role="button"
          tabIndex={0}
          className="saved-view-container"
          onClick={resetCurrentView}
          onKeyPress={resetCurrentView}
        >
          <p className="saved-view-name">{`Reset ${viewName}`}</p>
        </div>
        <div
          role="button"
          tabIndex={0}
          className="saved-view-container"
          onClick={onOpenAddView}
          onKeyPress={onOpenAddView}
        >
          <p className="saved-view-name">Save new view</p>
        </div>
        <div
          role="button"
          tabIndex={0}
          className="saved-view-container"
          onClick={onOpenManage}
          onKeyPress={onOpenManage}
        >
          <p className="saved-view-name">Manage views</p>
        </div>
      </div>
    </>
  );
};

export default ViewActions;
