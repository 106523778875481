import { addNewViewType, viewFromServerType, viewType } from '../../types/types';
import { triggerGetPostsAction } from '../content/content.reducer';
import setDataFromView from '../../helpers/set-data-from-view';
import ActionTypes from '../../action-types/action-types';
import { resetFilterAction } from '../filter/filter.reducer';
import { clearStats } from '../posts/posts.reducer';
import { AppThunkType } from '../store';
import API from '../../api/api';

const initialState: Array<viewFromServerType> = [];

type initialStateType = typeof initialState;

export const manageViewsReducer = (
  state: initialStateType = initialState,
  action: ManageViewsActionType,
): initialStateType => {
  switch (action.type) {
    case ActionTypes.MAKE_VIEW_ACTIVE: {
      return state.map(
        (el) => (el.id === action.id
          ? { ...el, is_active: true }
          : { ...el, is_active: false }),
      );
    }

    case ActionTypes.ADD_VIEW:
      return [...state, { ...action.view, id: action.id }];

    case ActionTypes.REMOVE_VIEW:
      return state.filter(
        (el) => el.id !== action.id,
      );

    case ActionTypes.CHANGE_VIEW_NAME:
      return state.map(
        (el) => (
          el.id === action.id
            ? { ...el, name: action.name }
            : { ...el }
        ),
      );

    case ActionTypes.UPDATE_VIEW:
      return state.map(
        (el) => (
          el.id === action.id
            ? { ...el, ...action.data }
            : { ...el }
        ),
      );

    case ActionTypes.SET_VIEWS:
      return [...action.views];

    default:
      return [...state];
  }
};

export const makeViewActiveAction = (
  id: number,
) => ({
  type: ActionTypes.MAKE_VIEW_ACTIVE,
  id,
} as const);

export const addViewAction = (
  view: addNewViewType,
  id: number,
) => ({
  type: ActionTypes.ADD_VIEW,
  view,
  id,
} as const);

export const removeViewAction = (
  id: number,
) => ({
  type: ActionTypes.REMOVE_VIEW,
  id,
} as const);

export const changeNameAction = (
  id: number,
  name: string,
) => ({
  type: ActionTypes.CHANGE_VIEW_NAME,
  id,
  name,
} as const);

export const setViewsAction = (
  views: Array<viewFromServerType>,
) => ({
  type: ActionTypes.SET_VIEWS,
  views,
} as const);

export const updateViewAction = (
  id: number,
  data: viewType,
) => ({
  type: ActionTypes.UPDATE_VIEW,
  id,
  data,
} as const);

export const createView = (
  token: string,
  data: addNewViewType,
): AppThunkType => async (dispatch) => {
  const response = await API.createViewRequest(token, data);
  const { id } = response.data;
  dispatch(addViewAction(data, id));
  dispatch(makeViewActiveAction(id));
};

export const getViews = (
  token: string,
  topicId: number,
): AppThunkType => async (dispatch) => {
  const response = await API.getAllViewsRequest(token, topicId);
  const activeView = response.data.filter((element) => element.is_active)[0];

  dispatch(setViewsAction(response.data));

  if (activeView) {
    dispatch(makeViewActiveAction(activeView.id));
    setDataFromView(activeView, dispatch);
  }
};

export const deleteView = (
  token: string,
  id: number,
): AppThunkType => async (dispatch, getState) => {
  const { manageViews } = getState();
  const view = manageViews.filter((v) => v.id === id)[0];

  if (view.is_active) {
    dispatch(clearStats());
    dispatch(resetFilterAction());
    dispatch(triggerGetPostsAction());
  }

  await API.deleteViewRequest(token, id);
  dispatch(removeViewAction(id));
};

export const putView = (
  token: string,
  id: number,
  data: viewType,
): AppThunkType => async (dispatch) => {
  await API.putViewRequest(token, id, data);
  dispatch(updateViewAction(id, data));
};

export const makeViewActive = (
  token: string,
  id: number,
  data: viewType,
): AppThunkType => async (dispatch) => {
  await API.putViewRequest(token, id, data);
  dispatch(makeViewActiveAction(id));
  setDataFromView(data, dispatch);
};

export type ManageViewsActionType =
  | ReturnType<typeof makeViewActiveAction>
  | ReturnType<typeof addViewAction>
  | ReturnType<typeof removeViewAction>
  | ReturnType<typeof changeNameAction>
  | ReturnType<typeof setViewsAction>
  | ReturnType<typeof updateViewAction>;
