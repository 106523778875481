import React, { ChangeEvent, useState } from 'react';

import SmallWhiteButton from '../../../../shared/components/buttons/small-white-button';
import SmallRedButton from '../../../../shared/components/buttons/small-red-button';
import Input from '../../../../shared/components/Input/input';
import { ILoginForm, ILoginFormProps } from './form.interfaces';

const initialForm: ILoginForm = {
  username: '',
  password: '',
};

const Form: React.FC<ILoginFormProps> = ({ onCancel, onSubmit }) => {
  const [fields, setFields] = useState(initialForm);

  const { username, password } = fields;

  const onChangeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFields((oldFields) => ({ ...oldFields, [name]: value }));
  };

  return (
    <>
      <Input
        value={username}
        type="username"
        name="username"
        placeholder="Enter username"
        onChangeCallback={onChangeInputHandler}
      />
      <Input
        value={password}
        type="password"
        name="password"
        placeholder="Enter your password"
        onChangeCallback={onChangeInputHandler}
      />
      <div className="log__button-wrapper">
        <SmallWhiteButton onClick={onCancel}>Cancel</SmallWhiteButton>
        <SmallRedButton
          type="submit"
          onClick={() => onSubmit(fields)}
        >
          Login
        </SmallRedButton>
      </div>
    </>
  );
};

export default Form;
