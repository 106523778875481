import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';

import {
  deleteLabels,
  deselectAllLabelAction,
  selectAllLabelAction,
} from '../../../../../store/labels/labels.reducer';
import { ILabelsContentDropdownProps } from './labels-content-dropdown.interfaces';
import { StateType } from '../../../../../store/store';

import './labels-content-dropdown.css';

const LabelsContentDropdown: React.FC<ILabelsContentDropdownProps> = ({
  selectedItemsIds,
}) => {
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );
  const isOpinionsOn = useSelector<StateType, boolean>(
    (state) => state.labelsHeaderFilter.isOpinionsOn,
  );

  const selectedItemsCount = selectedItemsIds.length;
  const isAnySelectedItems = selectedItemsCount > 0;
  const isNoSelectedItems = selectedItemsCount === 0;

  const onSelectedItemsCheckbox = () => {
    if (isAnySelectedItems) {
      dispatch(deselectAllLabelAction());
    }
    if (isNoSelectedItems) {
      dispatch(selectAllLabelAction());
    }
  };
  const onRemoveButton = () => {
    dispatch(deleteLabels(loginToken, selectedItemsIds));
  };

  useEffect(() => {
    dispatch(deselectAllLabelAction());
  }, [isOpinionsOn]);

  return (
    <div className="labels-content-dropdown__wrapper">
      <div className="labels-content-dropdown__selected-items-block">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          role="button"
          tabIndex={0}
          onClick={onSelectedItemsCheckbox}
          onKeyPress={onSelectedItemsCheckbox}
          className={`labels-content-dropdown__selected-items-checkbox ${
            isAnySelectedItems
              ? 'labels-content-dropdown__selected-items-checkbox--active'
              : ''
          }`}
        />
        <p className="labels-content-dropdown__selected-items-text">
          {`${selectedItemsCount} item${
            selectedItemsCount !== 1 ? 's' : ''
          } selected`}
        </p>
      </div>
      <button
        type="button"
        className="labels-content-dropdown__remove-block"
        onClick={onRemoveButton}
      >
        Remove
      </button>
    </div>
  );
};

export default LabelsContentDropdown;
