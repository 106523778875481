/* eslint-disable max-len */
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import {
  closeLabelChooserAction,
  makeAgreeLabelAction,
  openLabelChooserAction,
} from '../../../../../../../../../../store/add-label-popup/add-label-popup.reducer';
import { addLabelToPost } from '../../../../../../../../../../store/posts/posts.reducer';
import { Actions, Popover } from '../../../../../../../../../../shared/components';
import { StateType } from '../../../../../../../../../../store/store';
import { ITabComponentProps } from '../../add-label-popup.interfaces';

const ThemesTab: React.FC<ITabComponentProps> = ({ list, post }) => {
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );

  const themes = list.filter((el) => el.type === 'Theme');

  const onClickByTheme = (id: number, isOpen: boolean) => () => {
    dispatch(closeLabelChooserAction());

    if (!isOpen) {
      dispatch(openLabelChooserAction(id));
    }
  };
  const onClickBySelectTheme = (id: number) => (
    () => {
      dispatch(makeAgreeLabelAction(id));
      dispatch(addLabelToPost(loginToken, post.type, post.id, id));
      dispatch(closeLabelChooserAction());
    }
  );

  return (
    <>
      {themes.map(({ id, name, isChooserOpen }) => (
        <Popover
          key={id}
          open={isChooserOpen}
          position="bottom"
          content={(
            <Actions
              className="add-label-popup__actions"
              actions={{ select: true }}
              onSelect={onClickBySelectTheme(id)}
            />
          )}
          onClickOutside={onClickByTheme(id, isChooserOpen)}
        >
          <div
            key={id}
            role="button"
            tabIndex={0}
            className="add-label-popup__theme"
            onClick={onClickByTheme(id, isChooserOpen)}
            onKeyPress={onClickByTheme(id, isChooserOpen)}
          >
            {name}
          </div>
        </Popover>
      ))}
    </>
  );
};

export default ThemesTab;
