import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';

import { StateType } from '../../../store/store';

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const isLogged = useSelector<StateType, boolean>(
    (state) => state.login.isLogged,
  );

  // eslint-disable-next-line react/jsx-props-no-spreading
  return isLogged ? <Route {...props} /> : <Redirect to="/" />;
};

export default PrivateRoute;
