/* eslint-disable jsx-a11y/control-has-associated-label */
import { useSelector } from 'react-redux';
import React from 'react';

import { StateType } from '../../../store/store';

import './error.css';

const Error = () => {
  const message = useSelector<StateType, string>(
    (state) => state.error.message,
  );

  return (
    <div
      className="modal-error__title"
      dangerouslySetInnerHTML={{ __html: message }}
    />
  );
};

export default Error;
