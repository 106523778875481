import React from 'react';

const FacebookGreyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="16"
    fill="currentColor"
    viewBox="0 0 8 16"
    className="facebook-icon-grey"
  >
    <path
      // fill="currentColor"
      d="M6.54 2.657H8V.113A18.861 18.861 0 005.874 0C3.767 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.85V8.845h2.23L7.756 6H5.173V4.04c0-.822.222-1.384 1.367-1.384z"
    />
  </svg>
);

export default FacebookGreyIcon;
