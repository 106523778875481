import React, { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes } from 'react';
import './input.css';

type DefaultInputPropsType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>, HTMLInputElement
>

type SuperInputTextPropsType = DefaultInputPropsType & {
  onChangeCallback: (event: ChangeEvent<HTMLInputElement>) => void
}

const Input: React.FC<SuperInputTextPropsType> = ({ onChangeCallback, ...restProps }) => (
  <input
    className="auth_input"
    type="email"
    name="email"
    placeholder="Enter email"
    onChange={(event) => onChangeCallback(event)}
    required
    {...restProps /* eslint-disable-line react/jsx-props-no-spreading */}
  />
);

export default Input;
