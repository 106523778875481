import React, { ChangeEvent, useState } from 'react';

import SmallWhiteButton from '../../../../shared/components/buttons/small-white-button';
import SmallRedButton from '../../../../shared/components/buttons/small-red-button';
import { addNewLabelType } from '../../../../types/types';
import Input from '../../../../shared/components/Input/input';
import { IManageLabel } from './manage-label.interfaces';

import './manage-label.css';

const ManageLabel: React.FC<IManageLabel> = ({
  title,
  data,
  onCancel,
  onSubmit,
}) => {
  const [description, setDescription] = useState(data?.description || '');
  const [labelName, setLabelName] = useState(data?.name || '');

  const opinionLabelType: addNewLabelType['type'] = 'Opinion';
  const themeLabelType: addNewLabelType['type'] = 'Theme';
  const [isOpinionActive, setOpinionActive] = useState(
    data?.type === opinionLabelType,
  );

  const onChangeLabelName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setLabelName(value);
  };
  const onChangeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    setDescription(value);
  };
  const onOpinionsButton = () => {
    setOpinionActive(true);
  };
  const onThemesButton = () => {
    setOpinionActive(false);
  };
  const onSaveButton = () => {
    const payload: addNewLabelType = {
      name: labelName,
      description,
      type: isOpinionActive ? opinionLabelType : themeLabelType,
      agree: true,
    };

    onSubmit(payload);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <h3 className="add-new-label__title">{title}</h3>
      <label htmlFor="new-label-name" className="add-new-label__label">
        Label name
      </label>
      <Input
        id="new-label-name"
        value={labelName}
        type="text"
        name="new-label-name"
        placeholder="Enter the label name"
        onChangeCallback={onChangeLabelName}
      />
      <p className="add-new-label__subtitle">Type</p>
      <div className="add-new-label__toggle-labels-container">
        <button
          type="button"
          className={
            isOpinionActive
              ? 'add-new-label__toggle-button add-new-label__toggle-button--active'
              : 'add-new-label__toggle-button'
          }
          name="labels-header-filter-opinions"
          onClick={onOpinionsButton}
        >
          {opinionLabelType}
        </button>
        <button
          type="button"
          className={
            !isOpinionActive
              ? 'add-new-label__toggle-button add-new-label__toggle-button--active'
              : 'add-new-label__toggle-button'
          }
          onClick={onThemesButton}
          name="labels-header-filter-themes"
        >
          {themeLabelType}
        </button>
      </div>
      <label htmlFor="description" className="add-new-label__label">
        Label description
      </label>
      <textarea
        className="add-new-label__description-textarea"
        id="new-label-name"
        value={description}
        name="description"
        placeholder="Enter the label description"
        onChange={onChangeDescription}
      />
      <div className="add-new-label__button-wrapper">
        <SmallWhiteButton onClick={onCancel} onKeyPress={onCancel}>
          Cancel
        </SmallWhiteButton>
        <SmallRedButton type="submit" onClick={onSaveButton} onKeyPress={onSaveButton}>
          Save Label
        </SmallRedButton>
      </div>
    </form>
  );
};

export default ManageLabel;
