import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getLabelsToAddToPost,
} from '../../../../../../store/add-label-popup/add-label-popup.reducer';
import {
  LeftContainer,
  RightContainer,
  AddLabelPopup,
  AddLabelFullscreenPopup,
} from './components';
import { commonPostType } from '../../../../../../types/types';
import { StateType } from '../../../../../../store/store';
import { IPostPopupProps } from './post-popup.interfaces';

import './post-popup.css';
import { Modal } from '../../../../../../shared/components';

const PostPopup: React.FC<IPostPopupProps> = ({ id }) => {
  const [isAddLabelFullscreenOpen, setAddLabelFullscreenOpen] = useState(false);
  const [isAddLabelOpen, setAddLabelPopupOpen] = useState(false);
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );
  const post = useSelector<StateType, commonPostType>(
    (state) => state.posts.data.filter((postEntry) => id === postEntry.id)[0],
  );

  const onAddLabelOpenToggle = () => setAddLabelPopupOpen(!isAddLabelOpen);
  const onAddLabelOpenFullscreenToggle = () => {
    setAddLabelFullscreenOpen(!isAddLabelFullscreenOpen);
  };

  useEffect(() => {
    dispatch(getLabelsToAddToPost(loginToken));
  }, []);

  return (
    <div className="post-popup">
      <LeftContainer post={post} />
      <RightContainer post={post} onAddLabel={onAddLabelOpenToggle} />
      <Modal
        open={isAddLabelOpen}
        onClose={onAddLabelOpenToggle}
        maxWidth={380}
        className="post-popup__add-label-modal"
      >
        <AddLabelPopup
          post={post}
          onClose={onAddLabelOpenToggle}
          onFullscreen={onAddLabelOpenFullscreenToggle}
        />
      </Modal>
      <Modal
        open={isAddLabelFullscreenOpen}
        className="post-popup__add-label-fullscreen-modal"
      >
        <AddLabelFullscreenPopup
          post={post}
          onClose={onAddLabelOpenFullscreenToggle}
        />
      </Modal>
    </div>
  );
};

export default PostPopup;
