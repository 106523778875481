import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

import LeftArrowIcon from '../../../../../../shared/icons/left-arrow-icon';
import { IPaginationProps } from './pagination.interfaces';
import { StateType } from '../../../../../../store/store';

import './pagination.css';

const Pagination: FC<IPaginationProps> = ({
  total, show, page, limit = 7,
}) => {
  const history = useHistory();

  const isLoading = useSelector<StateType, boolean>(
    (state) => state.posts.isLoading,
  );

  const onPrevPage = () => {
    history.push({ search: `?page=${page - 1}` });
  };
  const onNextPage = () => {
    history.push({ search: `?page=${page + 1}` });
  };
  const onGotoPage = (num: number) => () => {
    history.push({ search: `?page=${num}` });
  };
  const pageRender = (num: number) => (
    <button
      key={num}
      type="button"
      className={`pagination__page ${
        page === num ? 'pagination__page-active' : ''
      }`}
      onClick={onGotoPage(num)}
    >
      {num}
    </button>
  );

  const totalPages = Math.ceil(total / show);
  const offset = Math.floor(limit / 2);
  const leftOffset = page - offset - 1;
  const pageNums = Array.from({ length: totalPages }, (_, i) => (
    i + 1
  ));
  const selectedPages = (
    leftOffset < 0
      ? pageNums.slice(0, limit)
      : pageNums.slice(leftOffset, page + offset)
  );
  const pages = (
    selectedPages.length < limit
      ? pageNums.slice(-limit)
      : selectedPages
  ).map((p) => pageRender(p));

  return (
    <div className={`pagination ${isLoading ? 'pagination-disabled' : ''}`}>
      <button
        type="button"
        className="pagination__left-arrow"
        onClick={onGotoPage(1)}
        disabled={page - 1 === 0}
      >
        <LeftArrowIcon />
        <LeftArrowIcon />
      </button>
      <button
        type="button"
        className="pagination__left-arrow"
        onClick={onPrevPage}
        disabled={page - 1 === 0}
      >
        <LeftArrowIcon />
      </button>
      <div className="pagination__pages">
        {pages.map((p) => p)}
      </div>
      <button
        type="button"
        className="pagination__right-arrow"
        onClick={onNextPage}
        disabled={page + 1 > totalPages}
      >
        <LeftArrowIcon />
      </button>
      <button
        type="button"
        className="pagination__right-arrow"
        onClick={onGotoPage(totalPages)}
        disabled={page + 1 > totalPages}
      >
        <LeftArrowIcon />
        <LeftArrowIcon />
      </button>
    </div>
  );
};

export default React.memo(Pagination);
