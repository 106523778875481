import React from 'react';

import { IAvatarImageProps } from './avatar-image.interfaces';

import './avatar-image.css';

const AvatarImage: React.FC<IAvatarImageProps> = ({ profileImage, type }) => {
  const isSocialType = type !== 'news';

  return (
    <div className="avatar-image__wrapper">
      <img
        className={
          isSocialType
            ? 'avatar-image__image avatar-image__image--social'
            : 'avatar-image__image'
        }
        src={profileImage}
        alt="Avatar"
      />
      {isSocialType && (
        <div
          className={`avatar-image__platform avatar-image__platform--${type}`}
        />
      )}
    </div>
  );
};

export default AvatarImage;
