import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import React from 'react';

import { IChartProps } from './chart.interfaces';

ChartJS.register(ArcElement, Tooltip);

const Chart: React.FC<IChartProps> = ({ sources }) => {
  const count = sources.reduce((acc, source) => acc + source.count, 0);
  const doughnutChartData = {
    labels: sources.map((source) => source.title),
    datasets: [
      {
        data: sources.map((source) => source.count),
        backgroundColor: sources.map((source) => source.color),
      },
    ],
  };
  const doughnutChartOptions = {
    cutout: 65,
    elements: {
      arc: {
        borderWidth: 0,
        width: 10,
      },
    },
  };

  return (
    <div className="summary__doughnut">
      <div className="doughnut">
        <Doughnut
          data={doughnutChartData}
          options={doughnutChartOptions}
          className="doughnut__chart"
        />
        <div className="doughnut__body">
          <div className="doughnut__text">Total</div>
          <div className="doughnut__count">{count}</div>
        </div>
      </div>
    </div>
  );
};

export default Chart;
