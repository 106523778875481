import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import React from 'react';

import FacebookGreyIcon from '../../../../icons/facebook-grey-icon';
import SettingsIcon from '../../../../icons/settings-icon';
import LabelsIcon from '../../../../icons/labels-icon';
import LogoutIcon from '../../../../icons/logout-icon';
import { logoutAction } from '../../../../../store/login/login.reducer';

const NavList = () => {
  const dispatch = useDispatch();

  const onLogoutCallback = () => {
    dispatch(logoutAction());
  };

  return (
    <div className="topic__list">
      <NavLink
        className="topic__list-item"
        activeClassName="activeLink"
        to="/panel/labels"
      >
        <span className="icon__wrapper">
          <LabelsIcon />
        </span>
        <p className="topic__item-text">Labels</p>
      </NavLink>
      <NavLink className="topic__list-item" activeClassName="activeLink" to="#">
        <span className="icon__wrapper">
          <FacebookGreyIcon />
        </span>
        <p className="topic__item-text">Facebook Pages</p>
      </NavLink>
      <NavLink
        className="topic__list-item"
        activeClassName="activeLink"
        to="/panel/settings"
      >
        <span className="icon__wrapper">
          <SettingsIcon />
        </span>
        <p className="topic__item-text">Settings</p>
      </NavLink>
      <NavLink
        className="topic__list-item"
        activeClassName="activeLink"
        to="#"
        onClick={onLogoutCallback}
      >
        <span className="icon__wrapper">
          <LogoutIcon />
        </span>
        <p className="topic__item-text">Logout</p>
      </NavLink>
    </div>
  );
};

export default NavList;
