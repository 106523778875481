import ActionTypes from '../../action-types/action-types';

export type sortByType = {
  id: number,
  headerName: string,
  dropDownName: string,
  isActive: boolean,
}

export const initialState = [
  {
    id: 0,
    headerName: 'Posting Date ASC',
    dropDownName: 'Date ASC',
    isActive: false,
  },
  {
    id: 1,
    headerName: 'Posting Date DESC',
    dropDownName: 'Date DESC',
    isActive: true,
  },
];

type InitialStateType = Array<sortByType>;

export const sortByReducer = (
  state: InitialStateType = initialState,
  action: SortByActionType,
): InitialStateType => {
  switch (action.type) {
    case ActionTypes.MAKE_SORT_BY_ACTIVE:
      return state.map(
        (sortBy: sortByType) => (sortBy.id === action.id
          ? { ...sortBy, isActive: true }
          : { ...sortBy, isActive: false }),
      );

    case ActionTypes.SET_SORT_BY:
      return [...action.sortByState];

    default:
      return [...state];
  }
};

export const makeSortByActiveAction = (
  id: number,
) => ({
  type: ActionTypes.MAKE_SORT_BY_ACTIVE,
  id,
} as const);

export const setSortByAction = (
  sortByState: InitialStateType,
) => ({
  type: ActionTypes.SET_SORT_BY,
  sortByState,
} as const);

export type SortByActionType =
  | ReturnType <typeof makeSortByActiveAction>
  | ReturnType <typeof setSortByAction>;
