import React from 'react';

import { Navbar } from '../../shared/components';
import { Content } from './components';

import './panel-page.css';

const PanelPage = () => (
  <div className="panel__wrapper">
    <Navbar />
    <Content />
  </div>
);

export default PanelPage;
