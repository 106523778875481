import React from 'react';
import { useSelector } from 'react-redux';

import { viewFromServerType } from '../../../../../../types/types';
import { StateType } from '../../../../../../store/store';
import { IManageViewsProps } from './manage-views.interfaces';

import './manage-views.css';
import { View } from './components';
import SmallRedButton from '../../../../../../shared/components/buttons/small-red-button';

const ManageView: React.FC<IManageViewsProps> = ({ onAddNewView }) => {
  const views = useSelector<StateType, Array<viewFromServerType>>(
    (state) => state.manageViews,
  );

  return (
    <>
      <div className="manage-views__top-container">
        <h3 className="manage-views__title">Manage views</h3>
      </div>
      <div className="manage-views__bottom-container">
        <div className="manage-views__views">
          {views.map((view) => (
            <View key={view.id} data={view} />
          ))}
        </div>
        <SmallRedButton
          style={{ marginBottom: 0, marginLeft: 0, width: '123px' }}
          onClick={onAddNewView}
        >
          Add new view
        </SmallRedButton>
      </div>
    </>
  );
};

export default ManageView;
