import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SmallRedButton from '../../../../../../shared/components/buttons/small-red-button';
import SmallWhiteButton from '../../../../../../shared/components/buttons/small-white-button';
import { addLabelsToFilter } from '../../../../../../store/filter/filter.reducer';
import { LabelsTab, PostingDateTab, SourcesTab } from './components';
import { StateType } from '../../../../../../store/store';
import { IFilterModalProps } from './filter-modal.interfaces';

import './filter-modal.css';

const tabs: Record<string, React.ComponentType> = {
  Sources: SourcesTab,
  Labels: LabelsTab,
  'Posting date': PostingDateTab,
};
const tabNames = Object.keys(tabs);

const FilterModal: React.FC<IFilterModalProps> = ({ onClose, onSubmit }) => {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>((state) => state.login.token);

  const onSwitchTab = (tabIndex: number) => () => {
    setActiveTab(tabIndex);
  };

  const renderTab = (Component: React.ComponentType) => <Component />;

  useEffect(() => {
    dispatch(addLabelsToFilter(loginToken));
  }, []);

  return (
    <>
      <div className="filter__top-container">
        <h3 className="filter__title">Filter by:</h3>
        <div className="filter__toggle-wrapper">
          {tabNames.map((tab, index) => (
            <button
              key={tab}
              type="button"
              className={
                tabNames[activeTab] === tab
                  ? 'filter__toggle-button filter__toggle-button--active'
                  : 'filter__toggle-button'
              }
              onClick={onSwitchTab(index)}
            >
              {tab}
            </button>
          ))}
        </div>
        {renderTab(tabs[tabNames[activeTab]])}
      </div>
      <div className="filter__bottom-container">
        <div className="filter__button-wrapper">
          <SmallWhiteButton onClick={onClose}>Cancel</SmallWhiteButton>
          <SmallRedButton onClick={onSubmit} style={{ marginRight: 0 }}>
            Apply filter
          </SmallRedButton>
        </div>
      </div>
    </>
  );
};

export default FilterModal;
