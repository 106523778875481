import { IObjWithName } from '../interfaces/interfaces';

const sortObjectsByName:
  (a: IObjWithName, b: IObjWithName) => (1 | -1 | 0) = (a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA > nameB) {
      return 1;
    }
    if (nameA < nameB) {
      return -1;
    }
    return 0;
  };

export default sortObjectsByName;
