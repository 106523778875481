import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  BrowserRouter,
} from 'react-router-dom';

import { authAction, authCredentials } from './store/auth/auth.reducer';
import { setErrorFlagAction } from './store/error/error.reducer';
import { themeType } from './store/theme/theme.reducer';
import { StateType } from './store/store';
import {
  SetNewPasswordPage,
  RegistrationPage,
  NotFoundPage,
  RecoveryPage,
  LoginPage,
  AuthPage,
  PanelPage,
  TopicPage,
  LabelsPage,
  SettingsPage,
} from './pages';
import {
  Modal,
  PrivateRoute,
  PublicRoute,
  Error,
} from './shared/components';
import { TokenService } from './services';
import useApiInterception from './api/useApiInterception';

let prevTheme = 'ooh';

function App() {
  const dispatch = useDispatch();
  useApiInterception();

  const isError = useSelector<StateType, boolean>((state) => state.error.isError);
  const theme = useSelector<StateType, themeType>((state) => state.theme.value);
  const tokens = TokenService.getTokens();

  const onCloseError = () => dispatch(setErrorFlagAction(false));

  useEffect(() => {
    if (tokens.accessToken && tokens.refreshToken) {
      dispatch(authCredentials(tokens.accessToken));
    }
  }, []);

  useEffect(() => {
    document.body.classList.add(theme);
    document.body.classList.remove(prevTheme);
    prevTheme = theme;
  }, [theme]);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path="/" render={() => <AuthPage />} />
          <PublicRoute exact path="/login" render={() => <LoginPage />} />
          <PublicRoute exact path="/recovery" render={() => <RecoveryPage />} />
          <PublicRoute exact path="/registration" render={() => <RegistrationPage />} />
          <PublicRoute exact path="/set-new-password" render={() => <SetNewPasswordPage />} />
          <PrivateRoute exact path="/panel" render={() => <PanelPage />} />
          <PrivateRoute exact path="/panel/labels" render={() => <LabelsPage />} />
          <PrivateRoute exact path="/panel/topic/:topicId" render={() => <TopicPage />} />
          <PrivateRoute exact path="/panel/settings" render={() => <SettingsPage />} />
          <Route render={() => <NotFoundPage />} />
        </Switch>
      </BrowserRouter>
      <Modal open={isError} maxWidth={400} onClose={onCloseError}>
        <Error />
      </Modal>
    </>
  );
}

export default App;
