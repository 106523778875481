import React from 'react';

import { IInstagramProps } from './instagram.interfaces';

const Instagram: React.FC<IInstagramProps> = ({ post }) => {
  const {
    statistics: {
      actual: { commentCount, favoriteCount },
    },
  } = post;

  return (
    <>
      <p className="post-popup__data-name">Comments</p>
      <p className="post-popup__data-content">{commentCount}</p>
      <p className="post-popup__data-name">Favorites</p>
      <p className="post-popup__data-content">{favoriteCount}</p>
    </>
  );
};

export default Instagram;
