import MicrosoftLogin from 'react-microsoft-login';
import { useDispatch } from 'react-redux';
import React from 'react';

import { authByMicrosoftAction } from '../../../../store/auth/auth.reducer';
import { IMicrosoftOAuthResponse } from '../../../../interfaces/server';
import { IMicrosoftButtonProps } from './microsoft-button.interfaces';
import { axiosGetError } from '../../../../helpers/axios-errors';
import {
  setErrorFlagAction,
  setErrorMessageAction,
} from '../../../../store/error/error.reducer';
import BigWhiteButton from '../../../../shared/components/buttons/big-white-button';
import MicrosoftIcon from '../../../../shared/icons/microsoft-icon';

const MICROSOFT_OAUTH2_CLIENT_ID = process.env
  .REACT_APP_MICROSOFT_OAUTH2_CLIENT_ID as string;

const MicrosoftButton: React.FC<IMicrosoftButtonProps> = ({ title }) => {
  const dispatch = useDispatch();

  const authMicrosoftHandler = (
    error: any,
    result: IMicrosoftOAuthResponse,
  ) => {
    if (error && error.errorCode !== 'user_cancelled') {
      dispatch(setErrorFlagAction(true));
      dispatch(setErrorMessageAction(axiosGetError(error)));
    } else if (result && result.accessToken) {
      dispatch(authByMicrosoftAction(result.accessToken));
    }
  };

  return (
    <MicrosoftLogin
      clientId={MICROSOFT_OAUTH2_CLIENT_ID}
      authCallback={authMicrosoftHandler}
    >
      <BigWhiteButton>
        <span className="auth__icon">
          <MicrosoftIcon />
        </span>
        {title}
      </BigWhiteButton>
    </MicrosoftLogin>
  );
};

export default MicrosoftButton;
