/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { ILabelProps } from './label.interfaces';

const Label: React.FC<ILabelProps> = ({ data, onSelect }) => {
  const { isSelected, name, id } = data;

  return (
    <div className="filter__labels-list-element-block">
      <div
        role="button"
        tabIndex={0}
        onClick={() => onSelect(id)}
        onKeyPress={() => onSelect(id)}
        className={
          isSelected
            ? 'filter__labels-list-element-checkbox filter__labels-first-element-checkbox--selected'
            : 'filter__labels-list-element-checkbox'
        }
      />
      <p className="filter__labels-list-element-text">{name}</p>
    </div>
  );
};

export default Label;
