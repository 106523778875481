import React from 'react';

const RedCrossTickIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.72504 0.282539C9.43254 -0.00996077 8.96004 -0.00996077 8.66754 0.282539L5.00004 3.94254L1.33254 0.275039C1.04004 -0.0174609 0.567539 -0.0174609 0.275039 0.275039C-0.0174609 0.567539 -0.0174609 1.04004 0.275039 1.33254L3.94254 5.00004L0.275039 8.66754C-0.0174609 8.96004 -0.0174609 9.43254 0.275039 9.72504C0.567539 10.0175 1.04004 10.0175 1.33254 9.72504L5.00004 6.05754L8.66754 9.72504C8.96004 10.0175 9.43254 10.0175 9.72504 9.72504C10.0175 9.43254 10.0175 8.96004 9.72504 8.66754L6.05754 5.00004L9.72504 1.33254C10.01 1.04754 10.01 0.567539 9.72504 0.282539Z"
      fill="#F5222D"
    />
  </svg>
);

export default RedCrossTickIcon;
