import { AxiosResponse } from 'axios';

import { addNewTopicType, topicFromServerType } from '../types/types';
import { instance } from '../api/api';

export default {
  getTopicsOfUser: (token: string) => (
    instance.get<unknown, AxiosResponse<Array<topicFromServerType>>>(
      '/topics/all',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  ),
  createTopic: (token: string, data: addNewTopicType) => (
    instance.post<addNewTopicType, AxiosResponse<topicFromServerType>>(
      '/topics',
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  ),
  deleteTopic: (token: string, id: number) => (
    instance.delete<unknown>(`/topics/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ),
  putTopic: (token: string, id: number, data: addNewTopicType) => (
    instance.put<addNewTopicType, AxiosResponse<string>>(
      `/topics/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  ),
};
