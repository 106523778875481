import React, { ChangeEvent } from 'react';

import { excludedKeywordType, keywordType } from '../../../../../types/types';
import SmallWhiteButton from '../../../buttons/small-white-button';
import SmallRedButton from '../../../buttons/small-red-button';
import Input from '../../../Input/input';
import { IManageTopicProps } from './manage-topic.interfaces';
import { KeywordInput } from '..';

import './manage-topic.css';

const initialSavedKeywords = [
  'power',
  'doe',
  'electricity',
  'solar energy',
  'renewable energy',
  'electricity bill',
  'electrical supply',
];
const initialSavedExcludedKeywords = [
  '-power',
  '-doe',
  '-electricity',
  '-solar energy',
  '-renewable energy',
  '-electricity bill',
  '-electrical supply',
];

const ManageTopic: React.FC<IManageTopicProps> = ({
  title,
  topic,
  onCancel,
  onSubmit,
  onChangeTopic,
  onChangeDescription,
  onAddTopicExcludedKeyword,
  onAddTopicKeyword,
  onRemoveTopicExcludedKeyword,
  onRemoveTopicKeyword,
}) => {
  const {
    name: nameTopic,
    description,
    keywords: keywordsObjects,
    excluded_keywords: excludedKeywordsObjects,
  } = topic;
  const keywords = keywordsObjects.map(({ name }: keywordType) => name);
  const excludedKeywords = excludedKeywordsObjects.map(
    ({ name }: excludedKeywordType) => name,
  );

  const onSaveButton = () => onSubmit(topic);
  const onChangeNameTopicCallback = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    onChangeTopic(value);
  };
  const onChangeDescriptionCallback = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;

    onChangeDescription(value);
  };

  return (
    <>
      <h3 className="addNewTopic__title">{title}</h3>
      <div className="addNewTopic__inputElement-wrapper">
        <label htmlFor="topicName">Topic name</label>
        <Input
          id="topicName"
          value={nameTopic}
          type="text"
          name="nameTopic"
          placeholder="Enter the topic name"
          onChangeCallback={onChangeNameTopicCallback}
        />
        <KeywordInput
          keywords={keywords}
          list={initialSavedKeywords}
          title="Keywords"
          onAdd={onAddTopicKeyword}
          onRemove={onRemoveTopicKeyword}
        />
        <KeywordInput
          keywords={excludedKeywords}
          list={initialSavedExcludedKeywords}
          title="Excluded Keywords"
          onAdd={onAddTopicExcludedKeyword}
          onRemove={onRemoveTopicExcludedKeyword}
        />
        <label htmlFor="description">Topic description</label>
        <textarea
          className="addNewTopic__description-textarea"
          id="description"
          value={description}
          name="description"
          placeholder="Enter the topic description"
          onChange={onChangeDescriptionCallback}
        />
      </div>
      <div className="addNewTopic__button-wrapper">
        <SmallWhiteButton onClick={onCancel} onKeyPress={onCancel}>
          Cancel
        </SmallWhiteButton>
        <SmallRedButton onClick={onSaveButton} onKeyPress={onSaveButton}>
          Save Topic
        </SmallRedButton>
      </div>
    </>
  );
};

export default ManageTopic;
