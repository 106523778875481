import axios from 'axios';

import {
  AuthService,
  LabelsService,
  SearchersService,
  TopicsService,
  ViewsService,
} from '../services';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export default {
  regRequest: AuthService.registration,
  loginRequest: AuthService.login,
  refreshAccessTokenRequest: AuthService.refreshAccessToken,
  passwordRecoveryRequest: AuthService.passwordRecovery,
  setNewPasswordRequest: AuthService.setNewPassword,
  userProfileRequest: AuthService.userProfile,
  loginByMicrosoftRequest: AuthService.loginByMicrosoft,
  loginByGoogleRequest: AuthService.loginByGoogle,

  getTopicsOfUserRequest: TopicsService.getTopicsOfUser,
  createTopicRequest: TopicsService.createTopic,
  deleteTopicRequest: TopicsService.deleteTopic,
  putTopicRequest: TopicsService.putTopic,

  getPostsRequest: SearchersService.getPosts,
  getCountPostsRequest: SearchersService.getCountPosts,

  createLabelRequest: LabelsService.createLabel,
  getAllLabelsRequest: LabelsService.getAllLabels,
  deleteLabelsRequest: LabelsService.deleteLabels,
  deleteLabelRequest: LabelsService.deleteLabel,
  putLabelRequest: LabelsService.putLabel,
  getLabelsOfPostRequest: LabelsService.getLabelsOfPost,
  attachLabelToPostRequest: LabelsService.attachLabelToPost,
  detachLabelToPostRequest: LabelsService.detachLabelToPost,

  createViewRequest: ViewsService.createView,
  getAllViewsRequest: ViewsService.getAllViews,
  deleteViewRequest: ViewsService.deleteView,
  putViewRequest: ViewsService.putView,
};
