import React from 'react';

const ListIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#141414"
      d="M4 8a1 1 0 100-2 1 1 0 000 2zM4 13a1 1 0 100-2 1 1 0 000 2zM4 18a1 1 0 100-2 1 1 0 000 2zM20.06 11H7.94a.94.94 0 00-.94.94v.12c0 .52.42.94.94.94h12.12c.52 0 .94-.42.94-.94v-.12a.94.94 0 00-.94-.94zM20.06 16H7.94a.94.94 0 00-.94.94v.12c0 .52.42.94.94.94h12.12c.52 0 .94-.42.94-.94v-.12a.94.94 0 00-.94-.94zM20.06 6H7.94a.94.94 0 00-.94.94v.12c0 .52.42.94.94.94h12.12c.52 0 .94-.42.94-.94v-.12a.94.94 0 00-.94-.94z"
    />
  </svg>
);

export default ListIcon;
