/* eslint-disable camelcase */
import { AppThunkType } from '../store';
import { authCredentials } from '../auth/auth.reducer';
import authAPI from '../../api/api';
import { setThemeAction } from '../theme/theme.reducer';
import { setTopicsAction } from '../topics/topics.reducer';
import { resetFilterAction } from '../filter/filter.reducer';
import { setLabelsAction } from '../labels/labels.reducer';
import { TokenService } from '../../services';

const { accessToken, refreshToken } = TokenService.getTokens();
const initialState = {
  token: accessToken,
  refreshToken,
  isLogged: !!accessToken && !!refreshToken,
};

type InitialStateType = typeof initialState;

export const loginReducer = (
  state: InitialStateType = initialState, action: LoginActionType,
): InitialStateType => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
        isLogged: action.isLogged,
      };
    default:
      return { ...state };
  }
};

export const loginAction = (
  receivedToken: string,
  receivedRefreshToken: string,
  isLogged: boolean,
) => ({
  type: 'LOGIN',
  token: receivedToken,
  refreshToken: receivedRefreshToken,
  isLogged,
} as const);

type loginActionType = ReturnType<typeof loginAction>

export const logoutAction = (): AppThunkType => (dispatch) => {
  localStorage.clear();
  dispatch(loginAction('', '', false));
  dispatch(setThemeAction('light'));
  dispatch(setTopicsAction([]));
  dispatch(resetFilterAction());
  dispatch(setLabelsAction([]));
};

export const loginCredentials = (
  username: string, password: string,
): AppThunkType => async (dispatch) => {
  const {
    data: {
      refresh_token, access_token,
    },
  } = await authAPI.loginRequest(username, password);

  TokenService.saveTokens(access_token, refresh_token);
  await dispatch(authCredentials(access_token));
  dispatch(loginAction(access_token, refresh_token, true));
};

export const registrationCredentials = (
  email: string, username: string, password: string,
): AppThunkType => async (dispatch) => {
  const {
    data: {
      refresh_token, access_token,
    },
  } = await authAPI.regRequest(email, username, password);

  TokenService.saveTokens(access_token, refresh_token);
  await dispatch(authCredentials(access_token));
  dispatch(loginAction(access_token, refresh_token, true));
};

export type LoginActionType = loginActionType;
