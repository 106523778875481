import React from 'react';

const FilterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      fill="#141414"
      d="M8.25 13.5h1.5c.412 0 .75-.338.75-.75a.752.752 0 00-.75-.75h-1.5a.752.752 0 00-.75.75c0 .412.338.75.75.75zm-6-8.25c0 .412.337.75.75.75h12c.412 0 .75-.338.75-.75A.752.752 0 0015 4.5H3a.752.752 0 00-.75.75zm3 4.5h7.5c.412 0 .75-.338.75-.75a.752.752 0 00-.75-.75h-7.5A.752.752 0 004.5 9c0 .412.338.75.75.75z"
    />
  </svg>
);

export default FilterIcon;
