import React from 'react';

import { IHeaderProps } from './header.interfaces';

import './header.css';

const Header: React.FC<IHeaderProps> = ({ title, children }) => (
  <div className="header__wrapper">
    <h2 className="header__title-topic">{title}</h2>
    {children}
  </div>
);

export default Header;
