import {
  addNewTopicType,
  topicFromServerType,
  topicType,
} from '../../types/types';
import ActionTypes from '../../action-types/action-types';
import { AppThunkType } from '../store';
import API from '../../api/api';

const initialState: Array<topicType> = [];

type initialStateType = Array<topicType>;

export const topicsReducer = (
  state: initialStateType = initialState,
  action: TopicsActionType,
): initialStateType => {
  switch (action.type) {
    case ActionTypes.CLOSE_EDIT_TOPIC:
      return state.map(
        (topic) => ({ ...topic, isEditTopicModalOpen: false }),
      );
    case ActionTypes.OPEN_EDIT_TOPIC:
      return state.map(
        (topic) => (topic.id === action.id
          ? { ...topic, isEditTopicModalOpen: true }
          : { ...topic, isEditTopicModalOpen: false }
        ),
      );
    case ActionTypes.EDIT_TOPIC:
      return state.map(
        (topic) => (topic.id === action.id
          ? { ...topic, ...action.payload }
          : { ...topic }
        ),
      );
    case ActionTypes.SELECT_TOPIC:
      return state.map(
        (topic) => (topic.id === action.id
          ? { ...topic, isSelected: true }
          : { ...topic, isSelected: false }
        ),
      );
    case ActionTypes.REMOVE_TOPIC:
      return state.filter((topic) => topic.id !== action.id);
    case ActionTypes.SET_TOPICS:
      return [...action.topics.map((el: topicFromServerType) => (
        {
          ...el,
          isSelected: false,
          isEditTopicModalOpen: false,
        }))];
    case ActionTypes.ADD_NEW_TOPIC:
      return [...state, {
        ...action.topic,
        id: action.id,
        isSelected: false,
        isEditTopicModalOpen: false,
      }];
    default:
      return [...state];
  }
};

export const closeEditTopicAction = (
) => ({
  type: ActionTypes.CLOSE_EDIT_TOPIC,
} as const);

export const openEditTopicAction = (
  id: number,
) => ({
  type: ActionTypes.OPEN_EDIT_TOPIC,
  id,
} as const);

export const editTopicAction = (
  id: number,
  payload: addNewTopicType,
) => ({
  type: ActionTypes.EDIT_TOPIC,
  id,
  payload,
} as const);

export const selectTopicAction = (
  id: number,
) => ({
  type: ActionTypes.SELECT_TOPIC,
  id,
} as const);

export const removeTopicAction = (
  id: number,
) => ({
  type: ActionTypes.REMOVE_TOPIC,
  id,
} as const);

export const setTopicsAction = (
  topics: Array<topicFromServerType>,
) => ({
  type: ActionTypes.SET_TOPICS,
  topics,
} as const);

export const addNewTopicAction = (
  topic: addNewTopicType,
  id: number,
) => ({
  type: ActionTypes.ADD_NEW_TOPIC,
  topic,
  id,
} as const);

export const getTopics = (
  token: string,
): AppThunkType => async (dispatch) => {
  const response = await API.getTopicsOfUserRequest(token);
  dispatch(setTopicsAction(response.data));
};

export const deleteTopic = (
  token: string,
  id: number,
): AppThunkType => async (dispatch) => {
  await API.deleteTopicRequest(token, id);
  dispatch(removeTopicAction(id));
};

export const putTopic = (
  token: string,
  id: number,
  data: addNewTopicType,
):AppThunkType => async (dispatch) => {
  await API.putTopicRequest(token, id, data);
  dispatch(editTopicAction(id, data));
};

export type TopicsActionType =
  | ReturnType<typeof closeEditTopicAction>
  | ReturnType<typeof openEditTopicAction>
  | ReturnType<typeof editTopicAction>
  | ReturnType<typeof selectTopicAction>
  | ReturnType<typeof removeTopicAction>
  | ReturnType<typeof setTopicsAction>
  | ReturnType<typeof addNewTopicAction>;
