import { useHistory } from 'react-router-dom';
import React from 'react';

import BigWhiteButton from '../../../../shared/components/buttons/big-white-button';
import MicrosoftButton from '../microsoft-button/microsoft-button';
import GoogleButton from '../google-button/google-button';

const Login = () => {
  const history = useHistory();

  const onClickLoginButton = () => {
    history.push('/login');
  };

  return (
    <div className="auth__button-wrapper">
      <MicrosoftButton title="Sign In With Microsoft" />
      <GoogleButton title="Sign In With Google" />
      <BigWhiteButton onClick={onClickLoginButton}>
        Sign In With phone or email
      </BigWhiteButton>
    </div>
  );
};

export default React.memo(Login);
