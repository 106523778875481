import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import Input from '../../../../../../../../shared/components/Input/input';
import {
  IAddLabelPopupProps,
  ITabComponentProps,
} from './add-label-popup.interfaces';
import { OpinionsTab, ThemesTab } from './components';
import { StateType } from '../../../../../../../../store/store';
import { labelToPostType } from '../../../../../../../../types/types';
import sortObjectsByName from '../../../../../../../../helpers/sort-objects-by-name';
import isObjectNameIncludes from '../../../../../../../../helpers/is-object-name-includes';
import CloseIcon from '../../../../../../../../shared/icons/close-icon';
import FullscreenIcon from '../../../../../../../../shared/icons/fullscreen-icon';

import './add-label-popup.css';

const tabs: Record<string, React.ComponentType<ITabComponentProps>> = {
  Opinions: OpinionsTab,
  Themes: ThemesTab,
};
const tabNames = Object.keys(tabs);

const AddLabelPopup: React.FC<IAddLabelPopupProps> = ({ post, onClose, onFullscreen }) => {
  const [searchLabel, setSearchLabel] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const attachedLabelsIds = post.labels.map((el) => el.id);

  const labels = useSelector<StateType, Array<labelToPostType>>(
    (state) => state.addLabelPopup,
  )
    .filter((el) => !attachedLabelsIds.includes(el.id))
    .filter((el) => !el.isVoted)
    .slice()
    .sort(sortObjectsByName)
    .filter(isObjectNameIncludes(searchLabel));

  const onSwitchTab = (tabIndex: number) => () => {
    setActiveTab(tabIndex);
  };
  const onChangeSearchLabel = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearchLabel(value);
  };

  const renderTab = (Component: React.ComponentType<ITabComponentProps>) => (
    <Component list={labels} post={post} />
  );

  return (
    <div className="add-label-popup">
      <div className="add-label-popup__top-container">
        <h5 className="add-label-popup__title">Add label</h5>
        <div className="add-label-popup__toggle-wrapper">
          {tabNames.map((tab, index) => (
            <button
              key={tab}
              type="button"
              className={
                tabNames[activeTab] === tab
                  ? 'add-label-popup__toggle-button add-label-popup__toggle-button--active'
                  : 'add-label-popup__toggle-button'
              }
              onClick={onSwitchTab(index)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="add-label-popup__search-container">
          <Input
            value={searchLabel}
            type="searchOpinion"
            name="searchOpinion"
            placeholder="Search"
            onChangeCallback={onChangeSearchLabel}
            style={{ paddingLeft: '38px' }}
          />
        </div>
      </div>
      <div className="add-label-popup__bottom-container">
        {renderTab(tabs[tabNames[activeTab]])}
      </div>
      <div
        role="button"
        tabIndex={0}
        className="add-label-popup__fullscreen"
        onClick={onFullscreen}
        onKeyPress={onFullscreen}
      >
        <FullscreenIcon />
        <span className="visually-hidden">
          Open add label popup in fullscreen
        </span>
      </div>
      <div
        role="button"
        tabIndex={0}
        className="add-label-popup__close"
        onClick={onClose}
        onKeyPress={onClose}
      >
        <CloseIcon />
        <span className="visually-hidden">Close add label popup</span>
      </div>
    </div>
  );
};

export default AddLabelPopup;
