/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import React, { useState } from 'react';

import MoreIcon from '../../../../icons/more-icon';
import HashtagIcon from '../../../../icons/hashtag-icon';
import { selectTopicAction } from '../../../../../store/topics/topics.reducer';
import { ITopicItemProps } from './topic-item.interfaces';
import { StateType } from '../../../../../store/store';
import Popover from '../../../popover/popover';
import { Actions } from '../../..';

import './topic-item.css';

const TopicItem: React.FC<ITopicItemProps> = ({ data, onEdit, onDelete }) => {
  const [topicControl, setTopicControl] = useState(false);
  const [moreActive, setMoreActive] = useState(false);
  const { topicId } = useParams<{ topicId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector<StateType, boolean>(
    (state) => state.posts.isLoading,
  );

  const { id, isSelected, name } = data;

  const onSetTopicControlInactive = () => {
    setTopicControl(false);
    setMoreActive(false);
  };
  const onSetTopicControlActive = () => {
    setTopicControl((value) => !value);
    setMoreActive((value) => !value);
  };

  const onTopicButton = () => {
    dispatch(selectTopicAction(id));
    history.push(`/panel/topic/${id}`);
  };
  const onEditCallback = () => {
    onSetTopicControlInactive();
    onEdit(data);
  };
  const onDeleteCallback = () => {
    onSetTopicControlInactive();
    onDelete(data);
  };

  return (
    <div
      className={`topic__item  ${
        (isSelected && +topicId) || moreActive ? 'topic__item--selected' : ''
      } ${isLoading ? 'topic__item--disabled' : ''}`}
    >
      <div
        role="button"
        tabIndex={0}
        className="topic__item-content"
        onClick={onTopicButton}
        onKeyPress={onTopicButton}
      >
        <HashtagIcon />
        <p className="topic__item-text">{name}</p>
      </div>
      <Popover
        open={topicControl}
        onClickOutside={onSetTopicControlInactive}
        content={
          <Actions onEdit={onEditCallback} onDelete={onDeleteCallback} />
        }
      >
        <div
          role="button"
          tabIndex={0}
          className={
            `topic__item-button-more${
              moreActive ? ' topic__item-button-more-active' : ''}`
          }
          onClick={onSetTopicControlActive}
          onKeyPress={onSetTopicControlActive}
        >
          <MoreIcon />
        </div>
      </Popover>
    </div>
  );
};

export default TopicItem;
