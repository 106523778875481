/* eslint-disable jsx-a11y/control-has-associated-label */
import { useDispatch, useSelector } from 'react-redux';
import React, { ChangeEvent, useState } from 'react';

import { StateType } from '../../../../../../../../store/store';
import {
  setAndCombiningRuleOnAction,
  setOrCombiningRuleOnAction,
  toggleLabelInFilterAction,
} from '../../../../../../../../store/filter/filter.reducer';
import Input from '../../../../../../../../shared/components/Input/input';
import { labelToFilterType } from '../../../../../../../../types/types';
import isObjectNameIncludes from '../../../../../../../../helpers/is-object-name-includes';
import { Label } from './components';

const LabelsTab = () => {
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const labels = useSelector<StateType, Array<labelToFilterType>>(
    (state) => state.filter.labels.labelsArray,
  ).filter(isObjectNameIncludes(search));

  const labelAnd = useSelector<StateType, boolean>(
    (state) => state.filter.labels.combiningRule.and,
  );
  const labelOr = useSelector<StateType, boolean>(
    (state) => state.filter.labels.combiningRule.or,
  );

  const onClickLabelAnd = () => {
    dispatch(setAndCombiningRuleOnAction());
  };
  const onClickLabelOr = () => {
    dispatch(setOrCombiningRuleOnAction());
  };

  const onChangeSearchTopic = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearch(value);
  };
  const onClickFirstElementCheckbox = (id: number) => {
    dispatch(toggleLabelInFilterAction(id));
  };

  return (
    <div className="filter__labels-wrapper">
      <div className="filter__labels-rule-container">
        <h5 className="filter__labels-rule-title">Combining rule</h5>
        <div className="filter__labels-rule-block">
          <div
            role="button"
            tabIndex={0}
            onClick={onClickLabelAnd}
            onKeyPress={onClickLabelAnd}
            className={
              labelAnd
                ? 'filter__labels-rule-button filter__labels-rule-button--active'
                : 'filter__labels-rule-button'
            }
          >
            {false}
          </div>
          <p className="filter__labels-rule-text">And</p>
        </div>
        <div className="filter__labels-rule-block">
          <div
            role="button"
            tabIndex={0}
            onClick={onClickLabelOr}
            onKeyPress={onClickLabelOr}
            className={
              labelOr
                ? 'filter__labels-rule-button filter__labels-rule-button--active'
                : 'filter__labels-rule-button'
            }
          />
          <p className="filter__labels-rule-text">Or</p>
        </div>
      </div>
      <div className="filter__labels-search-container">
        <Input
          value={search}
          type="searchTopic"
          name="searchTopic"
          placeholder="Search"
          onChangeCallback={onChangeSearchTopic}
          style={{ paddingLeft: '38px' }}
        />
      </div>
      <div className="filter__labels-list-wrapper">
        {labels.map((label) => (
          <Label key={label.id} data={label} onSelect={onClickFirstElementCheckbox} />
        ))}
      </div>
    </div>
  );
};

export default LabelsTab;
