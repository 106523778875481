/* eslint-disable camelcase */
import { loginAction } from '../login/login.reducer';
import { ITokens } from '../../services/token';
import { AppThunkType } from '../store';
import authAPI from '../../api/api';
import { TokenService } from '../../services';

const initialState = {
  profile: {
    email: '',
    username: '',
    id: 1,
  },
  isInitialized: false,
};

type InitialStateType = typeof initialState;

export const mainReducer = (
  state: InitialStateType = initialState, action: MainActionType,
): InitialStateType => {
  switch (action.type) {
    case 'IS_INITIALIZED':
      return { ...state, isInitialized: action.isInitialized };
    case 'SET_PROFILE':
      return { ...state, profile: { ...action.payload } };
    default:
      return { ...state };
  }
};

type initializedActionType = {type: 'IS_INITIALIZED', isInitialized: boolean};

export const initializedAction = (isInitialized: boolean): initializedActionType => ({
  type: 'IS_INITIALIZED',
  isInitialized,
});

type payloadType = {email: string, username: string, id: number};

type setProfileType = {type: 'SET_PROFILE', payload: payloadType};

const setProfile = (payload: payloadType): setProfileType => ({
  type: 'SET_PROFILE',
  payload,
});

export const authCredentials = (token: string): AppThunkType => async (dispatch) => {
  dispatch(initializedAction(true));
  const response = await authAPI.userProfileRequest(token);
  dispatch(setProfile(response.data));
};

export const authAction = ({
  accessToken,
  refreshToken,
}: ITokens): AppThunkType => async (dispatch) => {
  await dispatch(authCredentials(accessToken));
  dispatch(loginAction(accessToken, refreshToken, true));
};

export const setNewPasswordAction = (
  password: string,
  token: string,
): AppThunkType => async () => {
  await authAPI.setNewPasswordRequest(password, token);
};

export const authByMicrosoftAction = (
  token: string,
): AppThunkType => async (dispatch) => {
  const {
    data: {
      access_token,
      refresh_token,
    },
  } = await authAPI.loginByMicrosoftRequest(token);

  TokenService.saveTokens(access_token, refresh_token);
  await dispatch(authCredentials(access_token));
  dispatch(loginAction(access_token, refresh_token, true));
};

export const authByGoogleAction = (
  token: string,
): AppThunkType => async (dispatch) => {
  const {
    data: {
      access_token,
      refresh_token,
    },
  } = await authAPI.loginByGoogleRequest(token);

  TokenService.saveTokens(access_token, refresh_token);
  await dispatch(authCredentials(access_token));
  dispatch(loginAction(access_token, refresh_token, true));
};

export type MainActionType =
  | initializedActionType
  | setProfileType;
