import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  Chart,
  Filter,
  Keywords,
  Sources,
} from './components';
import { setCalendarFromAction } from '../../../../store/filter/filter.reducer';
import { triggerGetPostsAction } from '../../../../store/content/content.reducer';
import { ISummaryCount } from '../../../../interfaces/server/summary';
import { clearStats } from '../../../../store/posts/posts.reducer';
import { ISource, ISummaryProps } from './summary.interfaces';
import { StateType } from '../../../../store/store';

import './summary.css';

const Summary: React.FC<ISummaryProps> = ({ topicId }) => {
  const postingDate = useSelector<StateType, { from: number, to: number }>(
    (state) => state.filter.postingDate,
  );
  const [dateStart, setDateStart] = useState(postingDate.from);
  const prevFromRef = useRef(dateStart);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const stats = useSelector<StateType, ISummaryCount | null>(
    (state) => state.posts.stats,
  );

  const onChangeStats = (timeRange: moment.unitOfTime.DurationConstructor | null) => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete('page');
    history.replace({ search: queryParams.toString() });
    // eslint-disable-next-line prefer-const
    let { from, to } = postingDate;

    if (timeRange) {
      from = +moment(to).subtract(1, timeRange);
    } else {
      from = prevFromRef.current;
    }

    setDateStart(from);
    dispatch(setCalendarFromAction(from));
    dispatch(triggerGetPostsAction());
  };

  useEffect(() => {
    dispatch(clearStats());
  }, [topicId]);

  const sources: Array<ISource> = [
    {
      title: 'Instagram',
      indicator: 'instagram',
      color: '#ec4868',
      count: stats?.instagram || 0,
    },
    {
      title: 'Facebook',
      indicator: 'facebook',
      color: '#4267B2',
      count: stats?.facebook || 0,
    },
    {
      title: 'Twitter',
      indicator: 'twitter',
      color: '#1DA1F2',
      count: stats?.twitter || 0,
    },
    {
      title: 'News',
      indicator: 'news',
      color: '#FFA600',
      count: stats?.news || 0,
    },
  ];

  return (
    <div className="summary">
      <div className="summary__wrapper">
        <h2 className="summary__title">Summary</h2>
        <Filter onChange={onChangeStats} />
        {!!stats && (
          <>
            <Chart sources={sources} />
            <Sources data={sources} />
          </>
        )}
        <Keywords />
      </div>
    </div>
  );
};

export default React.memo(Summary);
