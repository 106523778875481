import React from 'react';

import { IViewProps } from './view.interfaces';

const View: React.FC<IViewProps> = ({ data, onClick }) => {
  const { is_active: isActive, name } = data;

  return (
    <div
      role="button"
      tabIndex={0}
      className={
        isActive
          ? 'saved-view-container saved-view-container--active'
          : 'saved-view-container'
      }
      onClick={() => onClick(data)}
      onKeyPress={() => onClick(data)}
    >
      <p className="saved-view-name">{name}</p>
    </div>
  );
};

export default View;
