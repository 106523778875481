import React from 'react';

const FullscreenIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#595959"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 14H3C2.45 14 2 13.55 2 13V3C2 2.45 2.45 2 3 2H7C7.55 2 8 1.55 8 1C8 0.45 7.55 0 7 0H2C0.89 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V9C16 8.45 15.55 8 15 8C14.45 8 14 8.45 14 9V13C14 13.55 13.55 14 13 14ZM10 1C10 1.55 10.45 2 11 2H12.59L4.46 10.13C4.07 10.52 4.07 11.15 4.46 11.54C4.85 11.93 5.48 11.93 5.87 11.54L14 3.41V5C14 5.55 14.45 6 15 6C15.55 6 16 5.55 16 5V1C16 0.45 15.55 0 15 0H11C10.45 0 10 0.45 10 1Z" />
  </svg>
);

export default FullscreenIcon;
