import React from 'react';

import { mediaFacebookType } from '../../../../../../../../types/post-types/facebook-type';
import { mediaInstagramType } from '../../../../../../../../types/post-types/instagram-type';
import AvatarImage from '../../../avatar-image/avatar-image';
import { ILeftContainerProps } from './left-container.interfaces';

const LeftContainer: React.FC<ILeftContainerProps> = ({ post }) => {
  const isInstagramType = post.type === 'instagram';
  const isFacebookType = post.type === 'facebook';
  const isTwitterType = post.type === 'twitter';
  const isNewsType = post.type === 'news';

  const maxImageWidth = 616;

  let sourceName = '';
  let sourceLink = '';
  let title = '';
  let text = '';
  let profileImage = '';
  let media: Array<mediaInstagramType | mediaFacebookType> = [];

  if (isFacebookType) {
    profileImage = post.account?.profileImage;
    sourceName = post.account?.name;
    sourceLink = post.account?.url;
    text = post.message;
  }
  if (isInstagramType) {
    profileImage = post.account?.profileImage;
    sourceName = post.account?.name;
    sourceLink = post.account?.url;
    text = post.description;
    media = post.media;
  }
  if (isTwitterType) {
    profileImage = post.account?.profileImage;
    sourceName = post.account?.username;
    sourceLink = post.account?.url;
    text = post.text;
  }
  if (isNewsType) {
    profileImage = post.imageUrl;
    sourceLink = post.sourceDomain;
    sourceName = post.sourceName;
    title = post.headline;
    text = post.article;
  }

  return (
    <div className="post-popup__left-container">
      <div className="post-popup__header">
        <div className="post-popup__topic-source-avatar-wrapper">
          <AvatarImage profileImage={profileImage} type={post.type} />
        </div>
        <div className="post-popup__topic-source-wrapper">
          <h4 className="post-popup__topic-source-name">{sourceName}</h4>
          <p className="post-popup__topic-source-link">{sourceLink}</p>
        </div>
      </div>
      {isNewsType && <div className="post-popup__title">{title}</div>}
      <div className="post-popup__image-wrapper">
        {media.map((el) => (el.type === 'photo' ? (
          <img
            src={el.url}
            alt="post's illustration"
            key={el.url}
            width={el.width > maxImageWidth ? maxImageWidth : el.width}
          />
        ) : null))}
      </div>
      <p className="post-popup__text">{text}</p>
    </div>
  );
};

export default LeftContainer;
