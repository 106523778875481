import { useDispatch } from 'react-redux';
import React from 'react';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';

import BigWhiteButton from '../../../../shared/components/buttons/big-white-button';
import { authByGoogleAction } from '../../../../store/auth/auth.reducer';
import GoogleIcon from '../../../../shared/icons/google-icon';
import { IGoogleButtonProps } from './google-button.interfaces';
import {
  setErrorFlagAction,
  setErrorMessageAction,
} from '../../../../store/error/error.reducer';

const GoogleButton: React.FC<IGoogleButtonProps> = ({ title }) => {
  const dispatch = useDispatch();

  const authGoogleSuccessHandler = (response: TokenResponse) => {
    if ('access_token' in response) {
      dispatch(authByGoogleAction(response.access_token));
    } else {
      dispatch(setErrorFlagAction(true));
      dispatch(setErrorMessageAction('Connection is offline'));
    }
  };

  const authGoogleErrorHandler = (error: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
    dispatch(setErrorFlagAction(true));
    if (error.error_description) dispatch(setErrorMessageAction(error.error_description));
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      authGoogleSuccessHandler(tokenResponse);
    },
    onError: (error) => {
      authGoogleErrorHandler(error);
    },
  });

  return (
    <BigWhiteButton
      onClick={() => {
        login();
      }}
    >
      <span className="auth__icon">
        <GoogleIcon />
      </span>
      {title}
    </BigWhiteButton>
  );
};

export default GoogleButton;
