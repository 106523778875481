import React from 'react';

import './not-found-page.css';

const NotFoundPage = () => (
  <div className="page__not-found">
    <h1>Error 404. Page not found</h1>
    <a href="/">Return to main</a>
  </div>
);

export default NotFoundPage;
