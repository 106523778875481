import { useSelector } from 'react-redux';
import React from 'react';

import { addNewLabelType } from '../../../../types/types';
import { IAddNewLabelProps } from './add-new-label.interfaces';
import ManageLabel from '../manage-label/manage-label';
import { StateType } from '../../../../store/store';

const AddNewLabel: React.FC<IAddNewLabelProps> = ({ onCancel, onSubmit }) => {
  const isOpinionsOn = useSelector<StateType, boolean>(
    (state) => state.labelsHeaderFilter.isOpinionsOn,
  );

  const onSaveButton = (payload: addNewLabelType) => {
    onSubmit(payload);
  };

  return (
    <ManageLabel
      title="Add new label"
      data={{ type: isOpinionsOn ? 'Opinion' : 'Theme' }}
      onCancel={onCancel}
      onSubmit={onSaveButton}
    />
  );
};

export default AddNewLabel;
