import {
  keywordType,
  excludedKeywordType,
  topicType,
} from '../../types/types';
import ActionTypes from '../../action-types/action-types';

const initialStateId = -1;

const initialState = {
  id: initialStateId,
  name: '',
  description: '',
  keywords: [],
  // eslint-disable-next-line camelcase
  excluded_keywords: [],
  isSelected: true, // frontend only
  isEditTopicModalOpen: true, // frontend only
  posts: [],
};

type InitialStateType = topicType;

export const editTopicReducer = (
  state: InitialStateType = initialState,
  action: EditTopicActionType,
): InitialStateType => {
  switch (action.type) {
    case ActionTypes.SET_EDIT_TOPIC_INITIAL_STATE:
      return { ...state, ...action.topic };

    case ActionTypes.SET_EDIT_TOPIC_NAME:
      return { ...state, name: action.name };

    case ActionTypes.SET_EDIT_TOPIC_DESCRIPTION:
      return { ...state, description: action.description };

    case ActionTypes.SET_EDIT_TOPIC_KEYWORDS:
      return { ...state, keywords: action.keywords };

    case ActionTypes.ADD_EDIT_TOPIC_KEYWORD_BY_NAME: {
      const { keywords: curKeywords } = state;
      const names = curKeywords.map(({ name }) => name);
      const { keywordName } = action;

      if (!names.includes(keywordName)) {
        return { ...state, keywords: [...curKeywords, { name: keywordName }] };
      }
      return { ...state };
    }

    case ActionTypes.REMOVE_EDIT_TOPIC_KEYWORD_BY_NAME: {
      const { keywords: curKeywords } = state;
      return {
        ...state,
        keywords: curKeywords.filter(
          ({ name }) => name !== action.keywordName,
        ),
      };
    }

    case ActionTypes.ADD_EDIT_TOPIC_EXCLUDED_KEYWORD_BY_NAME: {
      const { excluded_keywords: curKeywords } = state;
      const names = curKeywords.map(({ name }) => name);
      const { excludedKeywordName } = action;

      if (!names.includes(excludedKeywordName)) {
        return { ...state, excluded_keywords: [...curKeywords, { name: excludedKeywordName }] };
      }
      return { ...state };
    }

    case ActionTypes.REMOVE_EDIT_TOPIC_EXCLUDED_KEYWORD_BY_NAME: {
      const { excluded_keywords: curKeywords } = state;
      return {
        ...state,
        excluded_keywords: curKeywords.filter(
          ({ name }) => name !== action.excludedKeywordName,
        ),
      };
    }

    default:
      return { ...state };
  }
};

export const setEditTopicInitialStateAction = (
  topic: topicType,
) => ({
  type: ActionTypes.SET_EDIT_TOPIC_INITIAL_STATE,
  topic,
} as const);

export const setEditTopicNameAction = (
  name: string,
) => ({
  type: ActionTypes.SET_EDIT_TOPIC_NAME,
  name,
} as const);

export const setEditTopicDescriptionAction = (
  description: string,
) => ({
  type: ActionTypes.SET_EDIT_TOPIC_DESCRIPTION,
  description,
} as const);

export const setEditTopicKeywordsAction = (
  keywords: Array<keywordType>,
) => ({
  type: ActionTypes.SET_EDIT_TOPIC_KEYWORDS,
  keywords,
} as const);

export const addEditTopicKeywordByNameAction = (
  keywordName: string,
) => ({
  type: ActionTypes.ADD_EDIT_TOPIC_KEYWORD_BY_NAME,
  keywordName,
} as const);

export const removeEditTopicKeywordByNameAction = (
  keywordName: string,
) => ({
  type: ActionTypes.REMOVE_EDIT_TOPIC_KEYWORD_BY_NAME,
  keywordName,
} as const);

export const addEditTopicExcludedKeywordByNameAction = (
  excludedKeywordName: string,
) => ({
  type: ActionTypes.ADD_EDIT_TOPIC_EXCLUDED_KEYWORD_BY_NAME,
  excludedKeywordName,
} as const);

export const removeEditTopicExcludedKeywordByNameAction = (
  excludedKeywordName: string,
) => ({
  type: ActionTypes.REMOVE_EDIT_TOPIC_EXCLUDED_KEYWORD_BY_NAME,
  excludedKeywordName,
} as const);

export type EditTopicActionType =
  | ReturnType<typeof setEditTopicInitialStateAction>
  | ReturnType<typeof setEditTopicNameAction>
  | ReturnType<typeof setEditTopicDescriptionAction>
  | ReturnType<typeof setEditTopicKeywordsAction>
  | ReturnType<typeof addEditTopicKeywordByNameAction>
  | ReturnType<typeof removeEditTopicKeywordByNameAction>
  | ReturnType<typeof addEditTopicExcludedKeywordByNameAction>
  | ReturnType<typeof removeEditTopicExcludedKeywordByNameAction>;
