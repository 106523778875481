import React from 'react';

const CloseIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.70497 4.00009L6.85497 1.85509C6.94912 1.76094 7.00202 1.63324 7.00202 1.50009C7.00202 1.36694 6.94912 1.23924 6.85497 1.14509C6.76082 1.05094 6.63312 0.998047 6.49997 0.998047C6.36682 0.998047 6.23912 1.05094 6.14497 1.14509L3.99997 3.29509L1.85497 1.14509C1.76082 1.05094 1.63312 0.998047 1.49997 0.998047C1.36682 0.998047 1.23912 1.05094 1.14497 1.14509C1.05082 1.23924 0.997924 1.36694 0.997924 1.50009C0.997924 1.63324 1.05082 1.76094 1.14497 1.85509L3.29497 4.00009L1.14497 6.14509C1.09811 6.19158 1.06091 6.24688 1.03552 6.3078C1.01014 6.36873 0.99707 6.43409 0.99707 6.50009C0.99707 6.5661 1.01014 6.63145 1.03552 6.69238C1.06091 6.75331 1.09811 6.80861 1.14497 6.85509C1.19145 6.90196 1.24675 6.93916 1.30768 6.96454C1.36861 6.98992 1.43396 7.00299 1.49997 7.00299C1.56598 7.00299 1.63133 6.98992 1.69226 6.96454C1.75319 6.93916 1.80849 6.90196 1.85497 6.85509L3.99997 4.70509L6.14497 6.85509C6.19145 6.90196 6.24675 6.93916 6.30768 6.96454C6.36861 6.98992 6.43396 7.00299 6.49997 7.00299C6.56598 7.00299 6.63133 6.98992 6.69226 6.96454C6.75319 6.93916 6.80849 6.90196 6.85497 6.85509C6.90183 6.80861 6.93903 6.75331 6.96442 6.69238C6.9898 6.63145 7.00287 6.5661 7.00287 6.50009C7.00287 6.43409 6.9898 6.36873 6.96442 6.3078C6.93903 6.24688 6.90183 6.19158 6.85497 6.14509L4.70497 4.00009Z"
      fill="#595959"
    />
  </svg>
);

export default CloseIcon;
