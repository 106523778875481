import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';

import {
  getPostsByParams,
  getPostsByParamsWithViews,
} from '../../../../store/posts/posts.reducer';
import LoadingIcon from '../../../../shared/icons/loading-icon';
import { commonPostType } from '../../../../types/types';
import { StateType } from '../../../../store/store';
import { IContentProps } from './content.interfaces';
import { Posts } from './components';

import './content.css';

const Content: React.FC<IContentProps> = ({ topicId }) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const mount = useRef(false);

  const page = Number(new URLSearchParams(search).get('page')) || 1;

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );
  const loadCounter = useSelector<StateType, number>(
    (state) => state.content.loadCounter,
  );
  const isLoading = useSelector<StateType, boolean>(
    (state) => state.posts.isLoading,
  );
  const isGridOn = useSelector<StateType, boolean>(
    (state) => state.gridToggle.isGridOn,
  );
  const posts = useSelector<StateType, Array<commonPostType>>(
    (state) => state.posts.data,
  );

  useEffect(() => {
    dispatch(getPostsByParamsWithViews(
      loginToken,
      topicId,
      page,
    ));
  }, []);
  useEffect(() => {
    if (mount.current) {
      dispatch(
        getPostsByParams(
          loginToken,
          topicId,
          page,
        ),
      );
    }
    mount.current = true;
  }, [loadCounter, search, topicId]);

  return (
    <div
      className={
        isGridOn
          ? 'content__wrapper content__wrapper--grid'
          : 'content__wrapper'
      }
    >
      {isLoading && !posts.length ? (
        <div className="content__loader">
          <LoadingIcon />
        </div>
      ) : (
        <Posts page={page} />
      )}
    </div>
  );
};

export default Content;
