import moment from 'moment';
import ActionTypes from '../../action-types/action-types';
import {
  labelFromServerType,
  labelToFilterType,
} from '../../types/types';
import { AppThunkType } from '../store';
import API from '../../api/api';
import sortObjectsByName from '../../helpers/sort-objects-by-name';

const curDate = moment().valueOf();
const dayBeforeCurDate = moment(curDate).subtract(7, 'day').valueOf();

export type filterReducerType = {
  sources: {
    twitter: boolean;
    facebook: boolean;
    news: boolean;
    instagram: boolean;
  };
  labels: {
    combiningRule: {
      and: boolean;
      or: boolean;
    };
    labelsArray: Array<labelToFilterType>;
  };
  postingDate: {
    from: number;
    to: number;
  };
};

type InitialStateType = filterReducerType;

export const initialState: InitialStateType = {
  sources: {
    twitter: true,
    facebook: true,
    news: true,
    instagram: true,
  },
  labels: {
    combiningRule: {
      and: true,
      or: false,
    },
    labelsArray: [],
  },
  postingDate: {
    from: dayBeforeCurDate,
    to: curDate,
  },
};

export const filterReducer = (
  state: InitialStateType = initialState,
  action: FilterReducerType,
): InitialStateType => {
  switch (action.type) {
    case ActionTypes.SET_CALENDAR_FROM:
      return {
        ...state,
        postingDate: {
          ...state.postingDate,
          from: action.from,
        },
      };

    case ActionTypes.SET_CALENDAR_TO:
      return {
        ...state,
        postingDate: {
          ...state.postingDate,
          to: action.to,
        },
      };

    case ActionTypes.TOGGLE_TWITTER_CHECKBOX:
      return {
        ...state,
        sources: {
          ...state.sources,
          twitter: !state.sources.twitter,
        },
      };

    case ActionTypes.TOGGLE_FACEBOOK_CHECKBOX:
      return {
        ...state,
        sources: {
          ...state.sources,
          facebook: !state.sources.facebook,
        },
      };

    case ActionTypes.TOGGLE_NEWS_CHECKBOX:
      return {
        ...state,
        sources: {
          ...state.sources,
          news: !state.sources.news,
        },
      };

    case ActionTypes.TOGGLE_INSTAGRAM_CHECKBOX:
      return {
        ...state,
        sources: {
          ...state.sources,
          instagram: !state.sources.instagram,
        },
      };

    case ActionTypes.SET_OR_COMBINING_RULE_ON:
      return {
        ...state,
        labels: {
          ...state.labels,
          combiningRule: {
            and: false,
            or: true,
          },
        },
      };

    case ActionTypes.SET_AND_COMBINING_RULE_ON:
      return {
        ...state,
        labels: {
          ...state.labels,
          combiningRule: {
            and: true,
            or: false,
          },
        },
      };

    case ActionTypes.TOGGLE_LABEL_IN_FILTER:
      return {
        ...state,
        labels: {
          ...state.labels,
          labelsArray: state.labels.labelsArray.map((el) => (
            el.id === action.id
              ? { ...el, isSelected: !el.isSelected }
              : el
          )),
        },
      };

    case ActionTypes.SET_FILTER_BY:
      return { ...state, ...action.filterByState };

    case ActionTypes.ADD_LABELS_TO_FILTER: {
      const labelsIds = state.labels.labelsArray.map(
        (el) => el.id,
      );

      return {
        ...state,
        labels: {
          ...state.labels,
          labelsArray: [
            ...state.labels.labelsArray,
            ...action.labels
              .filter(
                (el) => !labelsIds.includes(el.id),
              ).map(
                (el) => ({
                  ...el,
                  isSelected: false,
                }),
              )
              .sort(sortObjectsByName),
          ],
        },
      };
    }

    case ActionTypes.RESET_FILTER:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export const setCalendarFromAction = (
  from: number,
) => ({
  type: ActionTypes.SET_CALENDAR_FROM,
  from,
} as const);

export const setCalendarToAction = (
  to: number,
) => ({
  type: ActionTypes.SET_CALENDAR_TO,
  to,
} as const);

export const toggleTwitterCheckboxAction = (
) => ({
  type: ActionTypes.TOGGLE_TWITTER_CHECKBOX,
} as const);

export const toggleFacebookCheckboxAction = (
) => ({
  type: ActionTypes.TOGGLE_FACEBOOK_CHECKBOX,
} as const);

export const toggleNewsCheckboxAction = (
) => ({
  type: ActionTypes.TOGGLE_NEWS_CHECKBOX,
} as const);

export const toggleInstagramCheckboxAction = (
) => ({
  type: ActionTypes.TOGGLE_INSTAGRAM_CHECKBOX,
} as const);

export const setOrCombiningRuleOnAction = (
) => ({
  type: ActionTypes.SET_OR_COMBINING_RULE_ON,
} as const);

export const setAndCombiningRuleOnAction = (
) => ({
  type: ActionTypes.SET_AND_COMBINING_RULE_ON,
} as const);

export const toggleLabelInFilterAction = (
  id: number,
) => ({
  type: ActionTypes.TOGGLE_LABEL_IN_FILTER,
  id,
} as const);

export const setFilterByAction = (
  filterByState: filterReducerType,
) => ({
  type: ActionTypes.SET_FILTER_BY,
  filterByState,
} as const);

export const addLabelsToFilterAction = (
  labels: Array<labelFromServerType>,
) => ({
  type: ActionTypes.ADD_LABELS_TO_FILTER,
  labels,
} as const);

export const addLabelsToFilter = (
  token: string,
): AppThunkType => async (dispatch) => {
  const response = await API.getAllLabelsRequest(token);
  dispatch(addLabelsToFilterAction(response.data));
};

export const resetFilterAction = () => ({
  type: ActionTypes.RESET_FILTER,
} as const);

export type FilterReducerType =
  | ReturnType<typeof setCalendarFromAction>
  | ReturnType<typeof setCalendarToAction>
  | ReturnType<typeof toggleTwitterCheckboxAction>
  | ReturnType<typeof toggleFacebookCheckboxAction>
  | ReturnType<typeof toggleNewsCheckboxAction>
  | ReturnType<typeof toggleInstagramCheckboxAction>
  | ReturnType<typeof setOrCombiningRuleOnAction>
  | ReturnType<typeof setAndCombiningRuleOnAction>
  | ReturnType<typeof toggleLabelInFilterAction>
  | ReturnType<typeof setFilterByAction>
  | ReturnType<typeof addLabelsToFilterAction>
  | ReturnType<typeof resetFilterAction>;
