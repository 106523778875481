/* eslint-disable react/react-in-jsx-scope */
import { useDispatch, useSelector } from 'react-redux';

import {
  closeEditLabelAction,
  putLabel,
} from '../../../../store/labels/labels.reducer';
import { addNewLabelType, labelType } from '../../../../types/types';
import { StateType } from '../../../../store/store';
import { ManageLabel } from '..';

const EditLabel = () => {
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );
  const labels = useSelector<StateType, Array<labelType>>(
    (state) => state.labels,
  );

  const label = labels.filter((el) => el.isEditLabelModalOpen)[0];

  const onCancelButton = () => {
    dispatch(closeEditLabelAction());
  };

  const onSaveButton = (payload: addNewLabelType) => {
    dispatch(putLabel(loginToken, label.id, payload));
    dispatch(closeEditLabelAction());
  };

  return (
    <ManageLabel
      title="Edit label"
      onCancel={onCancelButton}
      onSubmit={onSaveButton}
      data={label}
    />
  );
};

export default EditLabel;
