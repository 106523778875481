import { useSelector } from 'react-redux';
import React from 'react';

import { excludedKeywordType, keywordType, topicType } from '../../../../../../types/types';
import { StateType } from '../../../../../../store/store';

const makeStringFromKeywordsName = (
  collection: Array<keywordType | excludedKeywordType>,
  separator = ', ',
) => (
  collection.map(({ name }) => name).join(separator)
);

const Keywords = () => {
  const topics = useSelector<StateType, Array<topicType>>(
    (state) => state.topics,
  );

  const [selectedTopic] = topics.filter(({ isSelected }) => isSelected);
  const keywords = (
    selectedTopic && selectedTopic.keywords.length > 0
      ? selectedTopic.keywords
      : [{ name: '' }]);
  const excludedKeywords = (
    selectedTopic && selectedTopic.excluded_keywords.length > 0
      ? selectedTopic.excluded_keywords
      : [{ name: '' }]);
  const keywordsTextValue = makeStringFromKeywordsName(keywords);
  const excludedKeywordsTextValue = makeStringFromKeywordsName(excludedKeywords);

  return (
    <div className="summary__keywords">
      <div className="keywords">
        <div className="keywords__title">Keywords</div>
        <div className="keywords__text keywords__text--bordered">
          {keywordsTextValue}
        </div>
        <div className="keywords__title">Excluded Keywords</div>
        <div className="keywords__text">{excludedKeywordsTextValue}</div>
      </div>
    </div>
  );
};

export default Keywords;
