import React from 'react';

const MoreIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="18"
    fill="none"
    viewBox="0 0 12 18"
  >
    <path
      fill="#141414"
      d="M5.75 10.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM5.75 5.25a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM5.75 15.75a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
    />
  </svg>
);

export default MoreIcon;
