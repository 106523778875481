import { useDispatch, useSelector } from 'react-redux';
import React, { ChangeEvent } from 'react';

import { setThemeAction, themeType } from '../../store/theme/theme.reducer';
import { Navbar } from '../../shared/components';
import { StateType } from '../../store/store';

import './settings-page.css';

const SettingsPage = () => {
  const dispatch = useDispatch();

  const currentTheme = useSelector<StateType, themeType>(
    (state) => state.theme.value,
  );

  const themes: themeType[] = ['dark', 'light'];

  const onToggleTheme = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    localStorage.setItem('theme', value);
    dispatch(setThemeAction(value as themeType));
  };

  return (
    <div className="settings__wrapper">
      <Navbar />
      <div className="settings__container">
        <div className="settings__content">
          <h3>General</h3>
          <div className="settings__option">
            <span>Select theme:</span>
            <select name="theme" defaultValue={currentTheme} onChange={onToggleTheme}>
              {themes.map((theme) => (
                <option key={theme} value={theme}>
                  {theme}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
