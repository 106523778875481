import React, { ChangeEvent, useState } from 'react';

import SmallRedButton from '../../../../shared/components/buttons/small-red-button';
import SmallWhiteButton from '../../../../shared/components/buttons/small-white-button';
import Input from '../../../../shared/components/Input/input';
import { IUserStepForm, IUserStepProps } from './user-step.interfaces';

const UserStep: React.FC<IUserStepProps> = ({ onBackStep, onNextStep }) => {
  const [fields, setFields] = useState<IUserStepForm>({
    email: '',
    username: '',
  });
  const { email, username } = fields;

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFields((oldFields) => ({ ...oldFields, [name]: value }));
  };

  return (
    <>
      <Input
        value={email}
        type="email"
        name="email"
        placeholder="Enter email"
        onChangeCallback={onChangeInput}
      />
      <Input
        value={username}
        type="username"
        name="username"
        placeholder="Enter username"
        onChangeCallback={onChangeInput}
      />
      <div className="reg__button-wrapper">
        <SmallWhiteButton onClick={onBackStep}>Cancel</SmallWhiteButton>
        <SmallRedButton type="submit" onClick={() => onNextStep(fields)}>Next</SmallRedButton>
      </div>
    </>
  );
};

export default UserStep;
