import axios from 'axios';

import { IResponseError } from '../interfaces/server';
import {
  setErrorFlagAction,
  setErrorMessageAction,
} from '../store/error/error.reducer';
import { AppThunkType } from '../store/store';

const axiosGetError = (error: Error | IResponseError): string => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      const { detail }: IResponseError = error.response.data;

      if (typeof detail === 'string') {
        return detail;
      }

      if (detail instanceof Array) {
        return detail.reduce(
          (acc, err) => (
            `${acc}<p>${
              err.loc
                ? `${err.loc[1]} - ${err.msg}`
                : `${err}`
            }</p>`
          ),
          '',
        );
      }
    }
    if (error.request) {
      return error.message || 'Request error';
    }
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Unknown error';
};

const axiosErrorHandler = (
  (error: Error | IResponseError): AppThunkType => (dispatch) => {
    if (axios.isAxiosError(error) && error.response?.status === 422) {
      return;
    }

    dispatch(setErrorFlagAction(true));
    dispatch(setErrorMessageAction(axiosGetError(error)));
  }
);

export { axiosErrorHandler, axiosGetError };
