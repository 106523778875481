/* eslint-disable react/prop-types */
import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';

import { IPopoverPortalProps } from './popover-portal.interfaces';

import './popover-portal.css';

const PopoverPortal = forwardRef<HTMLDivElement, IPopoverPortalProps>(
  ({
    content, position, rect, mouseCoords, fixed,
  }, ref) => {
    if (!rect || !mouseCoords) {
      return null;
    }

    const prevRectCoords = useRef<DOMRect>(rect);
    const contentWidth = (ref as any).current?.offsetWidth || 0;
    const styles: CSSProperties = {
      position: fixed ? 'fixed' : 'absolute',
      top: fixed ? rect.y - prevRectCoords.current?.y + mouseCoords.y : mouseCoords.pageY,
      left: fixed ? rect.x - prevRectCoords.current?.x + mouseCoords.x : mouseCoords.pageX,
      visibility: contentWidth ? 'visible' : 'hidden',
    };

    if (position === 'left') {
      styles.left = -contentWidth + (styles.left as number);
    }
    if (position === 'bottom') {
      styles.left = -contentWidth / 2 + (styles.left as number);
    }

    return createPortal(
      <div className="popover" ref={ref} style={styles}>
        {content}
      </div>,
      document.body,
    );
  },
);

export default PopoverPortal;
