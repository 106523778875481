import React, {
  cloneElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PopoverPortal from './components/popover-portal/popover-portal';
import { IPopoverMouseCoords } from './components/popover-portal/popover-portal.interfaces';
import { IPopoverProps } from './popover.interfaces';

const Popover: React.FC<IPopoverProps> = ({
  children,
  open,
  content,
  fixed = false,
  position = 'left',
  onClickOutside,
}) => {
  const [mouseCoords, setMouseCoords] = useState<IPopoverMouseCoords | null>(null);
  const [rect, setRect] = useState<DOMRect | null>(null);

  const childRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const cloneChildren = useMemo(
    () => cloneElement(children, { ref: childRef }),
    [children],
  );

  const setRectHandler = () => (
    setRect(childRef.current?.getBoundingClientRect() || null)
  );
  const setMouseCoordsHandler = ({
    pageX,
    pageY,
    x,
    y,
  }: MouseEvent) => (
    setMouseCoords({
      pageX,
      pageY,
      x,
      y,
    })
  );
  const onClickOutsideHandler = (event: MouseEvent) => {
    if (
      open
      && !childRef.current?.contains(event.target as Node)
      && !containerRef.current?.contains(event.target as Node)
    ) {
      onClickOutside?.(event);
    }
  };

  useEffect(() => {
    setRectHandler();

    childRef.current?.addEventListener('click', setMouseCoordsHandler);
    window.addEventListener('click', onClickOutsideHandler);
    window.addEventListener('scroll', setRectHandler, true);
    window.addEventListener('resize', setRectHandler);

    return () => {
      childRef.current?.removeEventListener('click', setMouseCoordsHandler);
      window.removeEventListener('click', onClickOutsideHandler);
      window.removeEventListener('scroll', setRectHandler, true);
      window.removeEventListener('resize', setRectHandler);
    };
  }, [open]);

  return (
    <>
      {cloneChildren}
      {open && rect && mouseCoords && (
        <PopoverPortal
          position={position}
          rect={rect}
          content={content}
          mouseCoords={mouseCoords}
          ref={containerRef}
          fixed={fixed}
        />
      )}
    </>
  );
};

export default Popover;
