import React from 'react';

const SettingsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="currentColor"
    viewBox="0 0 18 18"
    className="settings-icon"
  >
    <path
      d="M6.458 16.5a1.687 1.687 0 01-1.013-.345L3.893 15a1.777 1.777 0 01-.368-2.415 1.545 1.545 0 00.173-1.395l-.045-.12a1.373 1.373 0 00-.84-.915h-.12a1.755 1.755 0 01-1.11-2.205L2.198 6a1.635 1.635 0 011.46-1.232c.217-.015.434.013.64.085a1.447 1.447 0 001.335-.218l.097-.075a1.455 1.455 0 00.548-1.132v-.18A1.74 1.74 0 017.995 1.5h1.913a1.695 1.695 0 011.2.502c.33.336.513.79.51 1.26v.21a1.32 1.32 0 00.517 1.073l.082.06a1.305 1.305 0 001.193.195l.255-.082a1.695 1.695 0 012.16 1.132l.593 1.89a1.77 1.77 0 01-1.095 2.197l-.15.053a1.417 1.417 0 00-.923.96 1.5 1.5 0 00.188 1.238l.194.284a1.785 1.785 0 01-.374 2.423l-1.508 1.163a1.68 1.68 0 01-2.415-.398l-.09-.127a1.312 1.312 0 00-1.125-.585 1.35 1.35 0 00-1.072.577l-.173.248a1.688 1.688 0 01-1.125.727c-.097.01-.195.01-.292 0zM3.3 8.715a2.873 2.873 0 011.785 1.875v.09a3 3 0 01-.345 2.715.285.285 0 000 .383L6.353 15a.187.187 0 00.277-.053l.173-.247a2.828 2.828 0 014.65 0l.09.135c.032.046.08.078.135.09a.186.186 0 00.142-.038l1.545-1.17a.27.27 0 00.053-.367l-.195-.285a3 3 0 01-.398-2.565 2.94 2.94 0 011.868-1.957l.15-.053a.255.255 0 00.142-.33L14.4 6.293a.263.263 0 00-.15-.143.158.158 0 00-.143 0l-.255.082a2.805 2.805 0 01-2.572-.427l-.03-.067a2.82 2.82 0 01-1.117-2.25v-.233a.277.277 0 00-.075-.195A.232.232 0 009.9 3H7.995a.233.233 0 00-.217.248v.187a2.925 2.925 0 01-1.14 2.318l-.098.074a2.932 2.932 0 01-2.722.443.165.165 0 00-.105 0 .21.21 0 00-.09.113L3 8.34a.27.27 0 00.165.338l.135.037z"
    />
    <path
      d="M9 11.625a2.625 2.625 0 110-5.25 2.625 2.625 0 010 5.25zm0-3.75a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
    />
  </svg>
);

export default SettingsIcon;
