import React from 'react';

import {
  actionData,
  actionType,
  IActionsActive,
  IActionsProps,
} from './actions.interfaces';
import GreenTickIcon from '../../icons/green-tick-icon';

import './actions.css';
import RedCrossTickIcon from '../../icons/red-cross-tick-icon';

const defaultActions: IActionsActive = {
  edit: true,
  delete: true,
};

const Actions: React.FC<IActionsProps> = ({
  actions = defaultActions,
  className,
  onAgree,
  onDelete,
  onDisagree,
  onEdit,
  onSelect,
}) => {
  const accessActions = Object.keys(actions) as actionType[];
  const list: actionData = {
    agree: {
      label: 'Agree',
      className: 'actions-button-agree',
      action: onAgree,
    },
    disagree: {
      label: 'Do not agree',
      className: 'actions-button-disagree',
      action: onDisagree,
    },
    delete: {
      label: 'Delete',
      className: 'actions-button-delete',
      action: onDelete,
    },
    edit: {
      label: 'Edit',
      className: 'actions-button-edit',
      action: onEdit,
    },
    select: {
      label: 'Select',
      className: 'actions-button-select',
      action: onSelect,
    },
  };

  return (
    <div className={`actions-wrapper ${className || ''}`}>
      <div className="actions">
        {accessActions.map((action) => (
          <button
            key={action}
            type="button"
            className={`actions-button ${list[action].className || ''}`}
            onClick={list[action].action}
          >
            {(action === 'agree' || action === 'select') && <GreenTickIcon />}
            {action === 'disagree' && <RedCrossTickIcon />}
            {list[action].label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Actions;
