const accessTokenName = 'access_token';
const refreshTokenName = 'refresh_token';

export interface ITokens {
  refreshToken: string;
  accessToken: string;
}

export default {
  getTokens(): ITokens {
    return {
      refreshToken: localStorage.getItem(refreshTokenName) || '',
      accessToken: localStorage.getItem(accessTokenName) || '',
    };
  },
  saveTokens(token: string, refreshToken: string) {
    localStorage.setItem(refreshTokenName, refreshToken);
    localStorage.setItem(accessTokenName, token);
  },
  clearTokens() {
    localStorage.removeItem(refreshTokenName);
    localStorage.removeItem(accessTokenName);
  },
};
