import { AxiosResponse } from 'axios';

import { addNewLabelType, labelFromServerType } from '../types/types';
import { instance } from '../api/api';

export default {
  createLabel: (token: string, data: addNewLabelType) => (
    instance.post<addNewLabelType, AxiosResponse<labelFromServerType>>(
      '/labels',
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  ),
  getAllLabels: (token: string) => (
    instance.get<Array<labelFromServerType>>('/labels/all', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ),
  deleteLabels: (token: string, data: Array<number>) => (
    instance.delete<unknown>('/labels/all', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
  ),
  deleteLabel: (token: string, labelId: number) => (
    instance.delete<unknown>(`/labels/${labelId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ),
  putLabel: (token: string, labelId: number, data: addNewLabelType) => (
    instance.put<addNewLabelType, AxiosResponse<labelFromServerType>>(
      `/labels/${labelId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  ),
  getLabelsOfPost: (token: string, postSource: string, postId: string) => (
    instance.get<Array<labelFromServerType>>(
      `/labels/${postSource}/${postId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  ),
  attachLabelToPost: (
    token: string,
    postSource: string,
    postId: string,
    labelId: number,
  ) => (
    instance.post<unknown, AxiosResponse<Array<labelFromServerType>>>(
      `/labels/attach/${postSource}/${postId}?label_id=${labelId}`,
      '',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  ),
  detachLabelToPost: (
    token: string,
    postSource: string,
    postId: string,
    labelId: number,
  ) => (
    instance.post<unknown, AxiosResponse<Array<labelFromServerType>>>(
      `/labels/detach/${postSource}/${postId}?label_id=${labelId}`,
      '',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  ),
};
