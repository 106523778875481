import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import './button.css';

type DefaultButtonPropsType = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement
>

type ButtonPropsType = DefaultButtonPropsType

const BigWhiteButton: React.FC<ButtonPropsType> = ({ className, ...restProps }) => (
  <button
    type="button"
    className="big-white-button"
    {...restProps /* eslint-disable-line react/jsx-props-no-spreading */}
  />
);

export default BigWhiteButton;
