import React from 'react';

const HashtagIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    fill="currentColor"
    viewBox="0 0 14 14"
    className="hashtag-icon"
  >
    <path
      d="M13 8.5H9.775l.547-3H13A.75.75 0 1013 4h-2.408l.518-2.858a.751.751 0 00-.63-.892.75.75 0 00-.915.615L9.002 4h-2.91l.518-2.858A.75.75 0 005.98.25a.75.75 0 00-.915.615L4.502 4H1a.75.75 0 000 1.5h3.225l-.547 3H1A.75.75 0 001 10h2.408l-.518 2.857a.75.75 0 00.63.893.749.749 0 00.915-.615L4.998 10h2.91l-.518 2.857a.75.75 0 00.63.893.749.749 0 00.915-.615L9.498 10H13a.75.75 0 100-1.5zm-7.725 0l.547-3h2.903l-.547 3H5.274z"
    />
  </svg>
);

export default HashtagIcon;
