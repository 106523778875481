/* eslint-disable jsx-a11y/control-has-associated-label */
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import React, { useState } from 'react';

import {
  setCalendarFromAction,
  setCalendarToAction,
} from '../../../../../../../../../../store/filter/filter.reducer';
import LeftArrowIcon from '../../../../../../../../../../shared/icons/left-arrow-icon';
import { StateType } from '../../../../../../../../../../store/store';

import './calendar.css';

type CalendarPropsType = {
  setCalendarOn: (filter: boolean) => void;
  isFrom: boolean;
  isTo: boolean;
};

const Calendar: React.FC<CalendarPropsType> = ({
  setCalendarOn,
  isFrom,
  isTo,
}) => {
  const dispatch = useDispatch();

  const dateFrom = useSelector<StateType, number>(
    (state) => state.filter.postingDate.from,
  );
  const dateTo = useSelector<StateType, number>(
    (state) => state.filter.postingDate.to,
  );

  const receivedDate = isFrom ? dateFrom : dateTo;

  const [date, setDate] = useState(receivedDate);

  const onClickNextMonthButton = () => {
    const addMonth = (day: number): number => moment(day).add(1, 'month').valueOf();
    setDate(addMonth(date));
  };

  const onClickPrevMonthButton = () => {
    const subtractMonth = (day: number): number => moment(day).subtract(1, 'month').valueOf();
    setDate(subtractMonth(date));
  };

  const getMonthDays = (day: number) => {
    const startOfMonth = moment(day).startOf('month');
    const endOfMonth = moment(day).endOf('month');
    const curMonthDays = [];

    for (
      let curDay = startOfMonth;
      curDay <= endOfMonth;
      curDay = moment(curDay).add(1, 'day')
    ) {
      curMonthDays.push(curDay);
    }

    return curMonthDays;
  };

  const getDaysFormPrevMonthToWeekStart = (day: number) => {
    const startOfMonth = moment(day).startOf('month');
    const startOfWeek = moment(startOfMonth).startOf('isoWeek');
    const prevMonthDaysToAdd = [];

    for (
      let curDay = startOfWeek;
      curDay < startOfMonth;
      curDay = moment(curDay).add(1, 'day')
    ) {
      prevMonthDaysToAdd.push(curDay);
    }
    return prevMonthDaysToAdd;
  };

  const getDaysFormNextMonthToWeekEnd = (day: number) => {
    const endOfMonth = moment(day).endOf('month');
    const endOfWeek = moment(endOfMonth).endOf('isoWeek');
    const nextMonthDaysToAdd = [];

    for (
      let curDay = moment(endOfMonth).add(1, 'day');
      curDay <= endOfWeek;
      curDay = moment(curDay).add(1, 'day')
    ) {
      nextMonthDaysToAdd.push(curDay);
    }

    return nextMonthDaysToAdd;
  };

  const curMonthDays = getMonthDays(date);

  const prevMonthDays = getDaysFormPrevMonthToWeekStart(date);

  const nextMonthDays = getDaysFormNextMonthToWeekEnd(date);

  const daysToRender = prevMonthDays.concat(curMonthDays).concat(nextMonthDays);

  const lastSevenDaysToRender = daysToRender.slice(-7);

  const renderDays = (day: moment.Moment) => {
    let classes = 'calendar__calendar-table-day';

    if (prevMonthDays.includes(day)) {
      classes = `${classes} calendar__calendar-table-day--not-current-month`;
    }

    if (nextMonthDays.includes(day)) {
      classes = `${classes} calendar__calendar-table-day--not-current-month`;
    }

    if (lastSevenDaysToRender.includes(day)) {
      classes = `${classes} calendar__calendar-table-day--last-row`;
    }

    return (
      <div
        key={moment(day).format('D.MMM.YYYY')}
        role="button"
        tabIndex={0}
        className={classes}
        data-timestamp={moment(day).valueOf()}
        onMouseDown={(event) => {
          const curEl = event.currentTarget;
          const timestamp = Number(curEl.getAttribute('data-timestamp'));
          if (isFrom) dispatch(setCalendarFromAction(timestamp));
          if (isTo) dispatch(setCalendarToAction(timestamp));
          setCalendarOn(false);
        }}
        onKeyPress={(event) => {
          const curEl = event.currentTarget;
          const timestamp = Number(curEl.getAttribute('data-timestamp'));
          if (isFrom) dispatch(setCalendarFromAction(timestamp));
          if (isTo) dispatch(setCalendarToAction(timestamp));
          setCalendarOn(false);
        }}
      >
        {moment(day).format('D') === '1'
          ? moment(day).format('D MMM')
          : moment(day).format('D')}
      </div>
    );
  };

  return (
    <div className="calendar__wrapper">
      <div className="calendar__top-container">
        <p className="calendar__month-year">
          {moment(date).format('MMM YYYY')}
        </p>
        <div className="calendar__month-control-buttons">
          <button
            type="button"
            className="calendar__prev-month-button"
            onClick={onClickPrevMonthButton}
            onKeyPress={onClickPrevMonthButton}
          >
            <LeftArrowIcon />
          </button>
          <button
            type="button"
            className="calendar__next-month-button"
            onClick={onClickNextMonthButton}
            onKeyPress={onClickNextMonthButton}
          >
            <LeftArrowIcon />
          </button>
        </div>
      </div>
      <div className="calendar__bottom-container">
        <div className="calendar__calendar-table">
          <div className="calendar__calendar-table-header">
            <div className="calendar__calendar-table-weekdays">
              <div className="calendar__calendar-table-weekday">Mon</div>
              <div className="calendar__calendar-table-weekday">Tue</div>
              <div className="calendar__calendar-table-weekday">Wed</div>
              <div className="calendar__calendar-table-weekday">Thu</div>
              <div className="calendar__calendar-table-weekday">Fri</div>
              <div className="calendar__calendar-table-weekday">Sat</div>
              <div className="calendar__calendar-table-weekday">Sun</div>
            </div>
          </div>
          <div className="calendar__calendar-table-days">
            {daysToRender.map(renderDays)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
