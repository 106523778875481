import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  changeNameAction,
  deleteView,
  putView,
} from '../../../../../../../../store/manage-views/manage-views.reducer';
import Input from '../../../../../../../../shared/components/Input/input';
import { StateType } from '../../../../../../../../store/store';
import { viewType } from '../../../../../../../../types/types';
import { IViewProps } from './view.interfaces';
import TrashIcon from '../../../../../../../../shared/icons/trash-icon';

const View: React.FC<IViewProps> = ({ data }) => {
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );

  const { name, id } = data;

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(changeNameAction(id, value));
  };
  const onBlurInput = () => {
    const newView: viewType = {
      ...data,
      name,
    };

    dispatch(putView(loginToken, id, newView));
  };
  const onDeleteView = () => {
    dispatch(deleteView(loginToken, id));
  };

  return (
    <div className="manage-views__view">
      <Input
        value={name}
        type="text"
        style={{ height: 48 }}
        name={`view name ${id}`}
        placeholder="Enter view name"
        onChangeCallback={onChangeInput}
        onBlur={onBlurInput}
      />
      <button
        type="button"
        className="manage-views__remove-view"
        onClick={onDeleteView}
        onKeyPress={onDeleteView}
      >
        <TrashIcon />
      </button>
    </div>
  );
};

export default View;
