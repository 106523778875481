import { shallowEqual, useSelector } from 'react-redux';
import React, { useState } from 'react';
import moment from 'moment';

import { OpinionItemSimple, ThemeItemSimple } from './components';
import { Modal } from '../../../../../../shared/components';
import { StateType } from '../../../../../../store/store';
import { AvatarImage, PostPopup } from '..';
import { IPostProps } from './post.interfaces';

import './post.css';

const Post: React.FC<IPostProps> = ({
  accountName,
  accountUrl,
  publishDate,
  message,
  type,
  profileImage,
  labels,
  id,
}) => {
  const [isPostPopupOpen, setPostPopupOpen] = useState(false);

  const isGridOn = useSelector<StateType, boolean>(
    (state) => state.gridToggle.isGridOn,
  );

  const opinions = labels.filter((el) => el.type === 'Opinion');
  const themes = labels.filter((el) => el.type === 'Theme');
  const timeFormatOutput = 'DD MMM / HH:mm';

  const onPostPopupTrigger = () => setPostPopupOpen(!isPostPopupOpen);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className={
          isGridOn ? 'post__wrapper post__wrapper--grid' : 'post__wrapper'
        }
        onClick={() => setPostPopupOpen(true)}
        onKeyPress={() => setPostPopupOpen(true)}
      >
        <div className="post__header">
          <div className="post__avatar-source-wrapper">
            <AvatarImage profileImage={profileImage} type={type} />
          </div>
          <div className="post__header-title-wrapper">
            <h4 className="post__header-title">{accountName}</h4>
            <p className="post__header-title-description">{accountUrl}</p>
          </div>
          <p className="post__header-date">
            {moment.unix(publishDate).format(timeFormatOutput)}
          </p>
        </div>
        <div className="post__text">{message}</div>
        <div className="post__labels-block">
          {themes.map((el) => (
            <ThemeItemSimple name={el.name} key={el.id} />
          ))}
          {opinions.map((el) => (
            <OpinionItemSimple agree={el.agree} name={el.name} key={el.id} />
          ))}
        </div>
      </div>
      <Modal open={isPostPopupOpen} onClose={onPostPopupTrigger} maxWidth={1024}>
        <PostPopup id={id} />
      </Modal>
    </>
  );
};

export default React.memo(Post, (prev, next) => {
  const isEqualProps = shallowEqual(
    { ...next, labels: null },
    { ...prev, labels: null },
  );
  const isEqualLabels = (
    shallowEqual(next.labels, prev.labels)
    && next.labels.length === prev.labels.length
  );

  return isEqualLabels && isEqualProps;
});
