import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import { removeLabelFromPost } from '../../../../../../../../../../store/posts/posts.reducer';
import CloseIcon from '../../../../../../../../../../shared/icons/close-icon';
import { StateType } from '../../../../../../../../../../store/store';
import { IOpinionItemProps } from './opinion-item.interfaces';

const OpinionItem: React.FC<IOpinionItemProps> = ({ post, data }) => {
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );

  const { id: postId, type: postSource } = post;
  const { name, id, agree } = data;
  const agreeText = agree ? 'Agree' : 'Do not agree';

  const onButtonDetach = () => {
    dispatch(removeLabelFromPost(loginToken, postSource, postId, id));
  };

  return (
    <div
      className={`post-popup__opinion ${
        agree ? 'post-popup__opinion--agree' : 'post-popup__opinion--disagree'
      }`}
    >
      <div
        className={`post-popup__label-opinion-layer ${
          agree
            ? 'post-popup__label-opinion-layer--agree'
            : 'post-popup__label-opinion-layer--disagree'
        }`}
      />
      <div className="post-popup__opinion-header">
        <h5 className="post-popup__opinion-title">Opinion</h5>
        <p
          className={`post-popup__agree-text ${
            agree
              ? 'post-popup__agree-text--agree'
              : 'post-popup__agree-text--disagree'
          }`}
        >
          {agreeText}
        </p>
      </div>
      <p className="post-popup__opinion-text">{name}</p>
      <div
        role="button"
        tabIndex={0}
        className="post-popup__detach-label"
        onClick={onButtonDetach}
        onKeyPress={onButtonDetach}
      >
        <CloseIcon />
        <span className="visually-hidden">Detach label from post</span>
      </div>
    </div>
  );
};

export default OpinionItem;
