import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UpDownArrowsIcon from '../../../../shared/icons/up-down-arrows-icon';
import { triggerGetPostsAction } from '../../../../store/content/content.reducer';
import { makeSortByActiveAction, sortByType } from '../../../../store/sort-by/sort-by.reducer';
import { StateType } from '../../../../store/store';

const Sort = () => {
  const [isSortByOpen, setSortByOpen] = useState(false);
  const dispatch = useDispatch();

  const sortRef = useRef<HTMLDivElement>(null);
  const sortButtonRef = useRef<HTMLButtonElement>(null);

  const sorts = useSelector<StateType, Array<sortByType>>((state) => state.sortBy);

  const [sortByDateAsc, sortByDateDesc] = sorts;
  const activeSort = sorts.filter((sortBy) => sortBy.isActive)[0];
  const { headerName: activeSortName } = activeSort;

  const openSortBy = () => {
    setSortByOpen(!isSortByOpen);
  };
  const onCloseAndSortById = (id: number) => () => {
    setSortByOpen(false);
    dispatch(makeSortByActiveAction(id));
    dispatch(triggerGetPostsAction());
  };

  useEffect(() => {
    const callback = (event: MouseEvent) => {
      if (
        isSortByOpen
        && !sortRef.current?.contains(event.target as Node)
        && !sortButtonRef.current?.contains(event.target as Node)
      ) {
        setSortByOpen(!isSortByOpen);
      }
    };

    window.addEventListener('click', callback as any);

    return () => {
      window.removeEventListener('click', callback as any);
    };
  }, [isSortByOpen]);

  return (
    <div className="topic__control-sort-wrapper">
      <button
        type="button"
        className="topic__control-sort"
        name="sort-by"
        onClick={openSortBy}
        ref={sortButtonRef}
      >
        <span className="sort__icon-wrapper">
          <UpDownArrowsIcon />
        </span>
        Sort by&nbsp;
        <span className="sort__selected-by">{`${activeSortName}`}</span>
      </button>
      {isSortByOpen && (
        <div className="sort-by" ref={sortRef}>
          <div
            className={
              sortByDateAsc.isActive
                ? 'sort-by-container sort-by-container--active'
                : 'sort-by-container'
            }
            role="button"
            tabIndex={0}
            onClick={onCloseAndSortById(sortByDateAsc.id)}
            onKeyPress={onCloseAndSortById(sortByDateAsc.id)}
          >
            <p className="sort-by-text">{sortByDateAsc.dropDownName}</p>
          </div>
          <div
            className={
              sortByDateDesc.isActive
                ? 'sort-by-container sort-by-container--active'
                : 'sort-by-container'
            }
            role="button"
            tabIndex={0}
            onClick={onCloseAndSortById(sortByDateDesc.id)}
            onKeyPress={onCloseAndSortById(sortByDateDesc.id)}
          >
            <p className="sort-by-text">{sortByDateDesc.dropDownName}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sort;
