import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AddTopicExcludedKeywordByNameAction,
  AddTopicKeywordByNameAction,
  removeTopicExcludedKeywordByNameAction,
  removeTopicKeywordByNameAction,
  setTopicDescriptionAction,
  setTopicNameAction,
} from '../../../../../store/add-new-topic/add-new-topic.reducer';
import { addNewTopicType } from '../../../../../types/types';
import { IAddNewTopicProps } from './add-new-topic.interfaces';
import { StateType } from '../../../../../store/store';
import { ManageTopic } from '..';

const AddNewTopic: React.FC<IAddNewTopicProps> = ({ onCancel, onSubmit }) => {
  const dispatch = useDispatch();

  const addNewTopic = useSelector<StateType, addNewTopicType>(
    (state) => state.addNewTopic,
  );

  const onSaveButton = () => onSubmit(addNewTopic);
  const onChangeNameTopic = (value: string) => {
    dispatch(setTopicNameAction(value));
  };
  const onChangeDescription = (value: string) => {
    dispatch(setTopicDescriptionAction(value));
  };
  const onAddSavedKeyword = (keyword: string) => {
    dispatch(AddTopicKeywordByNameAction(keyword));
  };
  const onRemoveSavedKeyword = (keyword: string) => {
    dispatch(removeTopicKeywordByNameAction(keyword));
  };
  const onAddSavedExcludedKeyword = (keyword: string) => {
    dispatch(AddTopicExcludedKeywordByNameAction(keyword));
  };
  const onRemoveSavedExcludedKeyword = (keyword: string) => {
    dispatch(removeTopicExcludedKeywordByNameAction(keyword));
  };

  return (
    <ManageTopic
      title="Add new topic"
      topic={addNewTopic}
      onCancel={onCancel}
      onSubmit={onSaveButton}
      onChangeTopic={onChangeNameTopic}
      onChangeDescription={onChangeDescription}
      onAddTopicKeyword={onAddSavedKeyword}
      onAddTopicExcludedKeyword={onAddSavedExcludedKeyword}
      onRemoveTopicKeyword={onRemoveSavedKeyword}
      onRemoveTopicExcludedKeyword={onRemoveSavedExcludedKeyword}
    />
  );
};

export default AddNewTopic;
