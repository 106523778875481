import ActionTypes from '../../action-types/action-types';
import { putLabel } from '../labels/labels.reducer';
import { AppThunkType } from '../store';
import API from '../../api/api';
import {
  addNewLabelType,
  commonPostType,
  labelFromServerType,
} from '../../types/types';
import { ISummaryCount } from '../../interfaces/server/summary';
import { getViews } from '../manage-views/manage-views.reducer';
import { TokenService } from '../../services';

const initialState = {
  data: [] as Array<commonPostType>,
  isLoading: false,
  isLoadingStats: false,
  stats: null as (ISummaryCount | null),
  total: null as number | null,
};

type initialStateType = typeof initialState;

export const postsReducer = (
  state: initialStateType = initialState,
  action: PostsActionType,
): initialStateType => {
  switch (action.type) {
    case ActionTypes.SET_POSTS:
      return {
        ...state,
        data: [...action.posts],
      };

    case ActionTypes.TOGGLE_LOADING_POST:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case ActionTypes.TOGGLE_LOADING_POST_STATS:
      return {
        ...state,
        isLoadingStats: action.isLoadingStats,
      };

    case ActionTypes.SET_LABELS_TO_POST:
      return {
        ...state,
        data: state.data.map(
          (post) => (post.id === action.postId
            ? { ...post, labels: action.labels }
            : { ...post }
          ),
        ),
      };

    case ActionTypes.SET_POSTS_STATS:
      return {
        ...state,
        stats: action.stats,
      };

    case ActionTypes.CLEAR_POST_STATS:
      return {
        ...state,
        stats: null,
      };

    case ActionTypes.SET_TOTAL_POSTS:
      return {
        ...state,
        total: action.total,
      };

    default:
      return { ...state };
  }
};

export const setLabelsToPostAction = (
  postId: string,
  labels: Array<labelFromServerType>,
) => ({
  type: ActionTypes.SET_LABELS_TO_POST,
  postId,
  labels,
} as const);

export const setPostsAction = (
  posts: Array<commonPostType>,
) => ({
  type: ActionTypes.SET_POSTS,
  posts,
} as const);

export const setLoadingAction = (isLoading: boolean) => ({
  type: ActionTypes.TOGGLE_LOADING_POST,
  isLoading,
} as const);

export const setLoadingStatsAction = (isLoadingStats: boolean) => ({
  type: ActionTypes.TOGGLE_LOADING_POST_STATS,
  isLoadingStats,
} as const);

export const setPostsStats = (stats: ISummaryCount) => ({
  type: ActionTypes.SET_POSTS_STATS,
  stats,
} as const);

export const setTotalPosts = (total: number) => ({
  type: ActionTypes.SET_TOTAL_POSTS,
  total,
} as const);

export const clearStats = () => ({
  type: ActionTypes.CLEAR_POST_STATS,
} as const);

export const addLabelToPost = (
  token: string,
  postSource: string,
  postId: string,
  labelId: number,
): AppThunkType => async (dispatch) => {
  const response = await API.attachLabelToPostRequest(token, postSource, postId, labelId);
  dispatch(setLabelsToPostAction(postId, response.data));
};

export const addLabelOpinionToPost = (
  token: string,
  postSource: string,
  postId: string,
  labelId: number,
  agree: boolean,
): AppThunkType => async (dispatch, getState) => {
  const { posts } = getState();
  const newPosts = posts.data.map((post) => ({
    ...post,
    labels: post.labels.map((label) => (label.id === labelId ? { ...label, agree } : label)),
  }));

  await dispatch(putLabel(token, labelId, { agree } as addNewLabelType));
  dispatch(setPostsAction(newPosts));
  dispatch(addLabelToPost(token, postSource, postId, labelId));
};

export const removeLabelFromPost = (
  token: string,
  postSource: string,
  postId: string,
  labelId: number,
): AppThunkType => async (dispatch) => {
  const response = await API.detachLabelToPostRequest(token, postSource, postId, labelId);
  dispatch(setLabelsToPostAction(postId, response.data));
};

export const getStats = (
  token: string,
  topicId: number,
  dateStart: number,
  dateEnd: number,
  labelsArr: Array<number>,
  isConjunction: boolean,
): AppThunkType => async (dispatch, getState) => {
  const { sources } = getState().filter;
  const keys = Object.keys(sources) as Array<keyof typeof sources>;
  const includes = keys.filter((key) => sources[key]);

  dispatch(setLoadingStatsAction(true));
  const response = await API.getCountPostsRequest(
    token,
    topicId,
    includes,
    dateStart,
    dateEnd,
    labelsArr,
    isConjunction,
  );
  dispatch(setPostsStats(response.data));
  dispatch(setLoadingStatsAction(false));
};

export const getPostsByParams = (
  token: string,
  topicId: number,
  page: number,
): AppThunkType => async (dispatch, getState) => {
  dispatch(setLoadingAction(true));
  const { filter, sortBy } = getState();
  const postTypes = (Object.keys(filter.sources) as (keyof typeof filter.sources)[]).filter(
    (source) => filter.sources[source],
  );
  const isConjunction = filter.labels.combiningRule.and;
  const labelsArr = filter.labels.labelsArray
    .filter((label) => label.isSelected)
    .map((label) => label.id);
  const startTime = Number((filter.postingDate.from / 1000).toFixed());
  const endTime = Number((filter.postingDate.to / 1000).toFixed());
  const sortByDesc = sortBy.filter(
    (sortType) => sortType.isActive,
  )[0].dropDownName === 'Date DESC';

  const response = await API.getPostsRequest(
    token,
    topicId,
    postTypes,
    isConjunction,
    page,
    startTime,
    endTime,
    labelsArr,
    sortByDesc,
  );

  const { posts } = response.data;

  await dispatch(
    getStats(TokenService.getTokens().accessToken,
      topicId,
      startTime,
      endTime,
      labelsArr,
      isConjunction),
  );
  dispatch(setPostsAction(posts));

  const { stats } = getState().posts;
  const keys = (stats ? Object.keys(stats) : []) as (keyof typeof stats)[];

  dispatch(setTotalPosts(keys.reduce(
    (acc, key) => (stats?.[key] || 0) + acc, 0,
  )));
  dispatch(setLoadingAction(false));
};

export const getPostsByParamsWithViews = (
  token: string,
  topicId: number,
  page: number,
): AppThunkType => async (dispatch) => {
  await dispatch(getViews(token, topicId));
  dispatch(getPostsByParams(
    TokenService.getTokens().accessToken, topicId, page,
  ));
};

export type PostsActionType =
  | ReturnType<typeof setLabelsToPostAction>
  | ReturnType<typeof setLoadingAction>
  | ReturnType<typeof setLoadingStatsAction>
  | ReturnType<typeof setPostsStats>
  | ReturnType<typeof clearStats>
  | ReturnType<typeof setTotalPosts>
  | ReturnType<typeof setPostsAction>;
