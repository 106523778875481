import ActionTypes from '../../action-types/action-types';

export type themeType = 'light' | 'dark';

type InitialStateType = {
  value: themeType
};

const themeFromStorage = localStorage.getItem('theme') as themeType || 'light';
const isTheme = themeFromStorage === 'dark' || themeFromStorage === 'light';
export const initialState: InitialStateType = {
  value: isTheme ? themeFromStorage : 'light',
};

export const themeReducer = (
  state = initialState,
  action: ThemeActionType,
): InitialStateType => {
  switch (action.type) {
    case ActionTypes.TOGGLE_THEME:
      return { ...state, value: action.value };

    default:
      return { ...state };
  }
};

export const setThemeAction = (
  value: themeType,
) => ({
  type: ActionTypes.TOGGLE_THEME,
  value,
} as const);

export type ThemeActionType = ReturnType <typeof setThemeAction>;
