import React from 'react';

const GreenTickIcon = () => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.74984 8.15023L2.12484 5.52523C1.83234 5.23273 1.36734 5.23273 1.07484 5.52523C0.782344 5.81773 0.782344 6.28273 1.07484 6.57523L4.21734 9.71773C4.50984 10.0102 4.98234 10.0102 5.27484 9.71773L13.2248 1.77523C13.5173 1.48273 13.5173 1.01773 13.2248 0.725234C12.9323 0.432734 12.4673 0.432734 12.1748 0.725234L4.74984 8.15023Z"
      fill="#52C41A"
    />
  </svg>
);

export default GreenTickIcon;
