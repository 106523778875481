import { useSelector } from 'react-redux';
import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';

import Input from '../../../../../../../../shared/components/Input/input';
import { Modal } from '../../../../../../../../shared/components';
import { StateType } from '../../../../../../../../store/store';
import { Calendar } from './components';

const PostingDateTab = () => {
  const [calendarFromOn, setCalendarFromOn] = useState(false);
  const [calendarToOn, setCalendarToOn] = useState(false);
  const [, setFrom1] = useState('Jul 21, 2021');
  const [, setFrom2] = useState('Jul 21, 2021');

  const dataFrom = useSelector<StateType, number>(
    (state) => state.filter.postingDate.from,
  );
  const dataTo = useSelector<StateType, number>(
    (state) => state.filter.postingDate.to,
  );

  const dataFromHumanized = moment(dataFrom).format('ll');
  const dataToHumanized = moment(dataTo).format('ll');

  const onChangeFromTopic1 = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setFrom1(value);
  };

  const onChangeFromTopic2 = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setFrom2(value);
  };

  const onFocusInputFromTopic1 = () => {
    setCalendarFromOn(true);
    setCalendarToOn(false);
  };

  const onBlurInInputFromTopic1 = () => {
    setCalendarFromOn(false);
    setCalendarToOn(false);
  };

  const onFocusInputFromTopic2 = () => {
    setCalendarToOn(true);
    setCalendarFromOn(false);
  };

  const onBlurInInputFromTopic2 = () => {
    setCalendarFromOn(false);
    setCalendarToOn(false);
  };

  return (
    <div className="filter__labels-posting-date-wrapper">
      <div className="filter__labels-posting-date-container">
        <h5 className="filter__labels-posting-date-title">From</h5>
        <Input
          value={dataFromHumanized}
          type="fromTopic1"
          name="fromTopic1"
          placeholder="From date"
          onChangeCallback={onChangeFromTopic1}
          onFocus={onFocusInputFromTopic1}
        />
        <Modal open={calendarFromOn} onClose={onBlurInInputFromTopic1} className="filter__calendar-modal">
          <Calendar setCalendarOn={setCalendarFromOn} isFrom isTo={false} />
        </Modal>
      </div>
      <div className="filter__labels-posting-date-container">
        <h5 className="filter__labels-posting-date-title">To</h5>
        <Input
          value={dataToHumanized}
          type="fromTopic2"
          name="fromTopic2"
          placeholder="From date"
          onChangeCallback={onChangeFromTopic2}
          onFocus={onFocusInputFromTopic2}
        />
        <Modal open={calendarToOn} onClose={onBlurInInputFromTopic2} className="filter__calendar-modal">
          <Calendar setCalendarOn={setCalendarToOn} isFrom={false} isTo />
        </Modal>
      </div>
    </div>
  );
};

export default PostingDateTab;
