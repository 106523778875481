import React from 'react';

const LabelsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="currentColor"
    viewBox="0 0 18 18"
    className="labels-icon"
  >
    <path
      d="M9.652 16.5a1.38 1.38 0 01-.967-.397l-4.808-4.815a.75.75 0 01-.217-.458L3 3.818A.75.75 0 013.817 3l7.013.66a.75.75 0 01.457.218l4.815 4.807a1.365 1.365 0 010 1.928l-5.49 5.49c-.255.254-.6.396-.96.397zm-4.5-6.082l4.5 4.5L14.94 9.63l-4.5-4.5-5.858-.547.57 5.835z"
    />
    <path
      d="M7.875 9a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
    />
  </svg>
);

export default LabelsIcon;
