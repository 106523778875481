import { ISummaryCount } from '../interfaces/server/summary';
import { searcherResponseType } from '../types/types';
import { instance } from '../api/api';

export default {
  getPosts: (
    token: string,
    topicId: number,
    postTypes: Array<string>,
    isConjunction: boolean,
    page: number,
    startTime?: number,
    endTime?: number,
    labels?: Array<number>,
    sortByDesc = true,
  ) => {
    const size = +(process.env.REACT_APP_SHOW_ON_PAGE || 10);
    let queries = postTypes.map((el) => `types=${el}`);

    queries.push(
      `is_conjunction=${isConjunction}`,
      `is_descending_order=${sortByDesc}`,
    );

    if (startTime) {
      queries.push(`start_time=${startTime}`);
    }

    if (endTime) {
      queries.push(`end_time=${endTime}`);
    }

    queries = queries.concat(
      labels ? labels.map((el) => `labels=${el}`) : [],
      [`offset=${size * (page - 1)}`],
      [`size=${size}`],
    );

    const optionalParameters = queries.join('&');

    return instance.get<searcherResponseType>(
      `/searchers/${topicId}?${optionalParameters}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  getCountPosts: (
    token: string,
    topicId: number,
    postTypes: Array<string>,
    dateStart: number,
    dateEnd: number,
    labels: Array<number>,
    isConjunction: boolean,
  ) => {
    const search = new URLSearchParams();

    postTypes.map((source) => search.append('types', source));
    search.append('start_time', `${dateStart}`);
    search.append('end_time', `${dateEnd}`);
    search.append('is_conjunction', `${isConjunction}`);
    labels.map((label) => search.append('labels', `${label}`));

    return instance.get<ISummaryCount>(
      `/searchers/${topicId}/count?${search.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
};
