import { AxiosResponse } from 'axios';

import { addNewViewType, viewFromServerType, viewType } from '../types/types';
import { instance } from '../api/api';

export default {
  createView: (token: string, data: addNewViewType) => (
    instance.post<addNewViewType, AxiosResponse<viewFromServerType>>(
      '/views',
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  ),
  getAllViews: (token: string, topicId: number) => (
    instance.get<Array<viewFromServerType>>(`/views/${topicId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ),
  deleteView: (token: string, viewId: number) => (
    instance.delete<unknown>(`/views/${viewId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ),
  putView: (token: string, viewId: number, data: viewType) => (
    instance.put<viewType, AxiosResponse<viewFromServerType>>(
      `/views/${viewId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  ),
};
