enum ActionType {
  CLOSE_LABEL_CHOOSER = 'CLOSE_LABEL_CHOOSER',
  OPEN_LABEL_CHOOSER = 'OPEN_LABEL_CHOOSER',
  MAKE_AGREE_LABEL = 'MAKE_AGREE_LABEL',
  MAKE_DISAGREE_LABEL = 'MAKE_DISAGREE_LABEL',
  SET_NEW_TOPIC_NAME = 'SET_NEW_TOPIC_NAME',
  SET_NEW_TOPIC_DESCRIPTION = 'SET_NEW_TOPIC_DESCRIPTION',
  SET_NEW_TOPIC_KEYWORDS = 'SET_NEW_TOPIC_KEYWORDS',
  SET_NEW_TOPIC_EXCLUDED_KEYWORDS = 'SET_NEW_TOPIC_EXCLUDED_KEYWORDS',
  ADD_TOPIC_KEYWORD_BY_NAME = 'ADD_TOPIC_KEYWORD_BY_NAME',
  REMOVE_TOPIC_KEYWORD_BY_NAME = 'REMOVE_TOPIC_KEYWORD_BY_NAME',
  ADD_TOPIC_EXCLUDED_KEYWORD_BY_NAME = 'ADD_TOPIC_EXCLUDED_KEYWORD_BY_NAME',
  REMOVE_TOPIC_EXCLUDED_KEYWORD_BY_NAME = 'REMOVE_TOPIC_EXCLUDED_KEYWORD_BY_NAME',
  RESET_NEW_TOPIC_STATE = 'RESET_NEW_TOPIC_STATE',
  REMOVE_TOPIC = 'REMOVE_TOPIC',
  SELECT_TOPIC = 'SELECT_TOPIC',
  EDIT_TOPIC = 'EDIT_TOPIC',
  OPEN_EDIT_TOPIC = 'OPEN_EDIT_TOPIC',
  CLOSE_EDIT_TOPIC = 'CLOSE_EDIT_TOPIC',
  SET_EDIT_TOPIC_INITIAL_STATE = 'SET_EDIT_TOPIC_INITIAL_STATE',
  SET_EDIT_TOPIC_NAME = 'SET_EDIT_TOPIC_NAME',
  SET_EDIT_TOPIC_DESCRIPTION = 'SET_EDIT_TOPIC_DESCRIPTION',
  SET_EDIT_TOPIC_KEYWORDS = 'SET_EDIT_TOPIC_KEYWORDS',
  ADD_EDIT_TOPIC_KEYWORD_BY_NAME = 'ADD_EDIT_TOPIC_KEYWORD_BY_NAME',
  REMOVE_EDIT_TOPIC_KEYWORD_BY_NAME = 'REMOVE_EDIT_TOPIC_KEYWORD_BY_NAME',
  ADD_EDIT_TOPIC_EXCLUDED_KEYWORD_BY_NAME = 'ADD_EDIT_TOPIC_EXCLUDED_KEYWORD_BY_NAME',
  REMOVE_EDIT_TOPIC_EXCLUDED_KEYWORD_BY_NAME = 'REMOVE_EDIT_TOPIC_EXCLUDED_KEYWORD_BY_NAME',
  SET_TOPICS = 'SET_TOPICS',
  ADD_NEW_TOPIC = 'ADD_NEW_TOPIC',
  SET_LABELS_TO_POST = 'SET_LABELS_TO_POST',
  SET_POSTS = 'SET_POSTS',
  REMOVE_LABEL = 'REMOVE_LABEL',
  REMOVE_LABELS = 'REMOVE_LABELS',
  MAKE_HEADER_FILTER_OPINION_ON = 'MAKE_HEADER_FILTER_OPINION_ON',
  MAKE_HEADER_FILTER_OPINION_OFF = 'MAKE_HEADER_FILTER_OPINION_OFF',
  MAKE_HEADER_FILTER_LABELS_SORT_BY_DATE_ON = 'MAKE_HEADER_FILTER_LABELS_SORT_BY_DATE_ON',
  MAKE_HEADER_FILTER_LABELS_SORT_BY_DATE_OFF = 'MAKE_HEADER_FILTER_LABELS_SORT_BY_DATE_OFF',
  SET_HEADER_FILTER_LABELS_SORT_BY_DATE = 'SET_HEADER_FILTER_LABELS_SORT_BY_DATE',
  ADD_NEW_LABEL = 'ADD_NEW_LABEL',
  SET_LABELS = 'SET_LABELS',
  SELECT_LABEL = 'SELECT_LABEL',
  DESELECT_LABEL = 'DESELECT_LABEL',
  SET_LABEL_SELECTION = 'SET_LABEL_SELECTION',
  SELECT_ALL_LABELS = 'SELECT_ALL_LABELS',
  DESELECT_ALL_LABELS = 'DESELECT_ALL_LABELS',
  SELECT_LABELS = 'SELECT_ALL_LABELS',
  DESELECT_LABELS = 'DESELECT_ALL_LABELS',
  OPEN_EDIT_LABEL = 'OPEN_EDIT_LABEL',
  CLOSE_EDIT_LABEL = 'CLOSE_EDIT_LABEL',
  EDIT_LABEL = 'EDIT_LABEL',
  SET_LABELS_TO_ADD_TO_POST = 'SET_LABELS_TO_ADD_TO_POST',
  SET_CALENDAR_FROM = 'SET_CALENDAR_FROM',
  SET_CALENDAR_TO = 'SET_CALENDAR_TO',
  TOGGLE_TWITTER_CHECKBOX = 'TOGGLE_TWITTER_CHECKBOX',
  TOGGLE_FACEBOOK_CHECKBOX = 'TOGGLE_FACEBOOK_CHECKBOX',
  TOGGLE_NEWS_CHECKBOX = 'TOGGLE_NEWS_CHECKBOX',
  TOGGLE_INSTAGRAM_CHECKBOX = 'TOGGLE_INSTAGRAM_CHECKBOX',
  SET_OR_COMBINING_RULE_ON = 'SET_OR_COMBINING_RULE_ON',
  SET_AND_COMBINING_RULE_ON = 'SET_AND_COMBINING_RULE_ON',
  TOGGLE_LABEL_IN_FILTER = 'TOGGLE_LABEL_IN_FILTER',
  SET_FILTER_BY = 'SET_FILTER_BY',
  ADD_LABELS_TO_FILTER = 'ADD_LABELS_TO_FILTER',
  MAKE_VIEW_ACTIVE = 'MAKE_VIEW_ACTIVE',
  ADD_VIEW = 'ADD_VIEW',
  REMOVE_VIEW = 'REMOVE_VIEW',
  CHANGE_VIEW_NAME = 'CHANGE_VIEW_NAME',
  SET_VIEWS = 'SET_VIEWS',
  UPDATE_VIEW = 'UPDATE_VIEW',
  SET_GRID = 'SET_GRID',
  MAKE_SORT_BY_ACTIVE = 'MAKE_SORT_BY_ACTIVE',
  SET_SORT_BY = 'SET_SORT_BY',
  TRIGGER_GET_POSTS = 'TRIGGER_GET_POSTS',
  RESET_FILTER = 'RESET_FILTER',
  TOGGLE_THEME = 'TOGGLE_THEME',
  TOGGLE_LOADING_POST = 'TOGGLE_LOADING_POST',
  TOGGLE_LOADING_POST_STATS = 'TOGGLE_LOADING_POST_STATS',
  SET_POSTS_STATS = 'SET_POSTS_STATS',
  CLEAR_POST_STATS = 'CLEAR_POST_STATS',
  SET_TOTAL_POSTS = 'SET_TOTAL_POSTS',
}

export default ActionType;
