import ActionTypes from '../../action-types/action-types';
import { AppThunkType } from '../store';
import API from '../../api/api';
import {
  addNewLabelType,
  labelFromServerType,
  labelType,
} from '../../types/types';

const initialState: Array<labelType> = [];

type initialStateType = typeof initialState;

export const labelsReducer = (
  state: initialStateType = initialState,
  action: LabelsActionType,
): initialStateType => {
  switch (action.type) {
    case ActionTypes.REMOVE_LABEL:
      return state.filter((label) => label.id !== action.id);

    case ActionTypes.REMOVE_LABELS:
      return state.filter((label) => !action.ids.includes(label.id));

    case ActionTypes.ADD_NEW_LABEL:
      return [{
        ...action.label,
        id: action.id,
        isSelected: false,
        isEditLabelModalOpen: false,
      }, ...state];

    case ActionTypes.SET_LABELS:
      return [...action.labels.map((el: labelFromServerType) => (
        {
          ...el,
          isSelected: false,
          isEditLabelModalOpen: false,
        }
      ))].reverse();

    case ActionTypes.SELECT_LABEL:
      return state.map(
        (label) => (label.id === action.id
          ? { ...label, isSelected: true }
          : { ...label }
        ),
      );

    case ActionTypes.DESELECT_LABEL:
      return state.map(
        (label) => (label.id === action.id
          ? { ...label, isSelected: false }
          : { ...label }
        ),
      );

    case ActionTypes.SET_LABEL_SELECTION:
      return state.map(
        (label) => (label.id === action.id
          ? { ...label, isSelected: action.isSelected }
          : { ...label }
        ),
      );

    case ActionTypes.SELECT_ALL_LABELS:
      return state.map((label) => ({ ...label, isSelected: true }));

    case ActionTypes.DESELECT_ALL_LABELS:
      return state.map((label) => ({ ...label, isSelected: false }));

    case ActionTypes.OPEN_EDIT_LABEL:
      return state.map(
        (label) => (label.id === action.id
          ? { ...label, isEditLabelModalOpen: true }
          : { ...label, isEditLabelModalOpen: false }
        ),
      );

    case ActionTypes.CLOSE_EDIT_LABEL:
      return state.map(
        (label) => ({ ...label, isEditLabelModalOpen: false }),
      );

    case ActionTypes.EDIT_LABEL:
      return state.map(
        (label) => (label.id === action.id
          ? { ...label, ...action.payload }
          : { ...label }
        ),
      );

    default:
      return [...state];
  }
};

export const removeLabelAction = (
  id: number,
) => ({
  type: ActionTypes.REMOVE_LABEL,
  id,
} as const);

export const removeLabelsAction = (
  ids: Array<number>,
) => ({
  type: ActionTypes.REMOVE_LABELS,
  ids,
} as const);

export const addNewLabelAction = (
  label: addNewLabelType,
  id: number,
) => ({
  type: ActionTypes.ADD_NEW_LABEL,
  label,
  id,
} as const);

export const setLabelsAction = (
  labels: Array<labelFromServerType>,
) => ({
  type: ActionTypes.SET_LABELS,
  labels,
} as const);

export const selectLabelAction = (
  id: number,
) => ({
  type: ActionTypes.SELECT_LABEL,
  id,
} as const);

export const deselectLabelAction = (
  id: number,
) => ({
  type: ActionTypes.DESELECT_LABEL,
  id,
} as const);

export const setLabelSelectionAction = (
  id: number,
  isSelected: boolean,
) => ({
  type: ActionTypes.SET_LABEL_SELECTION,
  id,
  isSelected,
} as const);

export const selectAllLabelAction = (
) => ({
  type: ActionTypes.SELECT_ALL_LABELS,
} as const);

export const deselectAllLabelAction = (
) => ({
  type: ActionTypes.DESELECT_ALL_LABELS,
} as const);

export const openEditLabelAction = (
  id: number,
) => ({
  type: ActionTypes.OPEN_EDIT_LABEL,
  id,
} as const);

export const closeEditLabelAction = (
) => ({
  type: ActionTypes.CLOSE_EDIT_LABEL,
} as const);

export const editLabelAction = (
  id: number,
  payload: addNewLabelType,
) => ({
  type: ActionTypes.EDIT_LABEL,
  id,
  payload,
} as const);

export const createLabel = (
  token: string,
  data: addNewLabelType,
): AppThunkType => async (dispatch) => {
  const response = await API.createLabelRequest(token, data);
  const { id } = response.data;
  dispatch(addNewLabelAction(data, id));
};

export const getLabels = (
  token: string,
): AppThunkType => async (dispatch) => {
  const response = await API.getAllLabelsRequest(token);
  dispatch(setLabelsAction(response.data));
};

export const deleteLabels = (
  token: string,
  ids: Array<number>,
): AppThunkType => async (dispatch) => {
  await API.deleteLabelsRequest(token, ids);
  dispatch(removeLabelsAction(ids));
};

export const deleteLabel = (
  token: string,
  id: number,
): AppThunkType => async (dispatch) => {
  await API.deleteLabelRequest(token, id);
  dispatch(removeLabelAction(id));
};

export const putLabel = (
  token: string,
  id: number,
  data: addNewLabelType,
):AppThunkType => async (dispatch) => {
  await API.putLabelRequest(token, id, data);
  dispatch(editLabelAction(id, data));
};

export type LabelsActionType =
  | ReturnType<typeof removeLabelAction>
  | ReturnType<typeof removeLabelsAction>
  | ReturnType<typeof addNewLabelAction>
  | ReturnType<typeof setLabelsAction>
  | ReturnType<typeof selectLabelAction>
  | ReturnType<typeof deselectLabelAction>
  | ReturnType<typeof setLabelSelectionAction>
  | ReturnType<typeof selectAllLabelAction>
  | ReturnType<typeof deselectAllLabelAction>
  | ReturnType<typeof openEditLabelAction>
  | ReturnType<typeof closeEditLabelAction>
  | ReturnType<typeof editLabelAction>;
