/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { KeyboardEvent, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import CloseIcon from '../../icons/close-icon';
import { IModalProps } from './modal.interfaces';

import './modal.css';

let modalIds: number[] = [];
let counter = 0;

const Modal: React.FC<IModalProps> = ({
  open,
  children,
  maxWidth,
  className,
  onClose,
}) => {
  const refId = useRef(counter++);

  const maxWidthStyle = { ...(maxWidth && { maxWidth }) };

  useEffect(() => {
    const onEscCallback: any = (event: KeyboardEvent) => {
      const isLast = refId.current === modalIds[modalIds.length - 1];

      if (open && event.key === 'Escape' && isLast) {
        onClose?.(event);
      }
    };

    if (open) {
      modalIds.push(refId.current);
    } else {
      modalIds = modalIds.filter((id) => id !== refId.current);
    }

    document.addEventListener('keyup', onEscCallback);

    return () => {
      document.removeEventListener('keyup', onEscCallback);
    };
  }, [open]);

  if (!open) {
    return null;
  }

  return createPortal(
    <div className={['modal-container', className].filter((v) => v).join(' ')}>
      <div className="modal-background" onClick={onClose} />
      <div className="modal-block" style={maxWidthStyle}>
        <button className="modal-close-btn" type="button" onClick={onClose}>
          <CloseIcon />
        </button>
        <div className="modal-content">{children}</div>
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
