const initialState = {
  message: '',
  isError: false,
};

type InitialStateType = typeof initialState;

export const errorReducer = (
  state: InitialStateType = initialState,
  action: ErrorActionType,
): InitialStateType => {
  switch (action.type) {
    case 'SET_FLAG':
      return { ...state, isError: action.isError };
    case 'SET_MESSAGE':
      return { ...state, message: action.message };
    default:
      return { ...state };
  }
};

type setErrorFlagActionType = {
  type: 'SET_FLAG',
  isError: boolean,
}

export const setErrorFlagAction = (
  isError: boolean,
): setErrorFlagActionType => ({
  type: 'SET_FLAG',
  isError,
});

type setErrorActionType = {
  type: 'SET_MESSAGE',
  message: string,
}

export const setErrorMessageAction = (
  message: string,
): setErrorActionType => ({
  type: 'SET_MESSAGE',
  message,
});

export type ErrorActionType =
  | setErrorFlagActionType
  | setErrorActionType;
