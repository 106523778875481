import React from 'react';

import { IHeaderPostProps } from './header-post.interfaces';
import { AvatarImage } from '../../../../..';

const HeaderPost: React.FC<IHeaderPostProps> = ({ post }) => {
  let profileImage = '';
  let sourceLink = '';
  let highlight = '';

  if (post.type === 'facebook') {
    profileImage = post.account?.profileImage;
    sourceLink = post.account?.url;
    highlight = post.message;
  }
  if (post.type === 'instagram') {
    profileImage = post.account?.profileImage;
    sourceLink = post.account?.url;
    highlight = post.description;
  }
  if (post.type === 'twitter') {
    profileImage = post.account?.profileImage;
    sourceLink = post.account?.url;
    highlight = post.text;
  }
  if (post.type === 'news') {
    profileImage = post.imageUrl;
    sourceLink = post.sourceDomain;
    highlight = post.headline;
  }

  return (
    <div className="add-label-fullscreen__header-post">
      <div className="add-label-fullscreen__image-wrapper">
        <AvatarImage profileImage={profileImage} type={post.type} />
      </div>
      <div className="add-label-fullscreen__post-text-wrapper">
        <h4 className="add-label-fullscreen__header-highlight">{highlight}</h4>
        <p className="add-label-fullscreen__header-source-link">{sourceLink}</p>
      </div>
    </div>
  );
};

export default HeaderPost;
