import React from 'react';

const Content = () => (
  <div className="panel__content">
    <div className="welcome-text">
      <p>You need an active topic to see social media activity here.</p>
      <p>Please add or select a topic using the left-hand side panel.</p>
    </div>
  </div>
);

export default Content;
