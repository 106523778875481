import { useHistory } from 'react-router-dom';
import React from 'react';

import BigWhiteButton from '../../../../shared/components/buttons/big-white-button';
import MicrosoftButton from '../microsoft-button/microsoft-button';
import GoogleButton from '../google-button/google-button';

const Registration = () => {
  const history = useHistory();

  const onClickRegistrationButton = () => {
    history.push('/registration');
  };

  return (
    <div className="auth__button-wrapper">
      <MicrosoftButton title="Sign Up With Microsoft" />
      <GoogleButton title="Sign Up With Google" />
      <BigWhiteButton onClick={onClickRegistrationButton}>
        Sign Up With phone or email
      </BigWhiteButton>
    </div>
  );
};

export default React.memo(Registration);
