import React, { ChangeEvent, useState } from 'react';

import SmallRedButton from '../../../../shared/components/buttons/small-red-button';
import SmallWhiteButton from '../../../../shared/components/buttons/small-white-button';
import Input from '../../../../shared/components/Input/input';
import { IPasswordStepForm, IPasswordStepProps } from './password-step.interfaces';

const PasswordStepForm: React.FC<IPasswordStepProps> = ({
  onBackStep,
  onSubmit,
}) => {
  const [fields, setFields] = useState<IPasswordStepForm>({
    password: '',
    repeatPassword: '',
  });
  const { password, repeatPassword } = fields;

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFields((oldFields) => ({ ...oldFields, [name]: value }));
  };

  return (
    <>
      <Input
        value={password}
        type="password"
        name="password"
        placeholder="Enter password"
        onChangeCallback={onChangeInput}
      />
      <Input
        value={repeatPassword}
        type="password"
        name="repeatPassword"
        placeholder="Repeat password"
        onChangeCallback={onChangeInput}
      />
      <div className="reg__button-wrapper">
        <SmallWhiteButton onClick={onBackStep}>Cancel</SmallWhiteButton>
        <SmallRedButton type="submit" onClick={() => onSubmit(fields)}>
          Register
        </SmallRedButton>
      </div>
    </>
  );
};

export default PasswordStepForm;
