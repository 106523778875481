import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import {
  setHeaderFilterLabelsSortByDateAction,
} from '../../../../../../store/labels-header-filter/labels-header-filter.reducer';
import UpDownArrowsIcon from '../../../../../../shared/icons/up-down-arrows-icon';
import { StateType } from '../../../../../../store/store';

const Sort = () => {
  const dispatch = useDispatch();

  const isSortByDateCreation = useSelector<StateType, boolean>(
    (state) => state.labelsHeaderFilter.isSortByDateCreation,
  );

  const onSortByDateButton = () => {
    dispatch(setHeaderFilterLabelsSortByDateAction(!isSortByDateCreation));
  };

  return (
    <button
      type="button"
      className="labels-header__sort-by-date"
      name="sort-by-date"
      onClick={onSortByDateButton}
    >
      <span className="labels-header__icon-wrapper">
        <UpDownArrowsIcon />
      </span>
      {!isSortByDateCreation ? 'Sort by Date creation' : 'Sort by label name'}
    </button>
  );
};

export default Sort;
