import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';

import BigRedButton from '../../../../shared/components/buttons/big-red-button';
import { createLabel } from '../../../../store/labels/labels.reducer';
import { addNewLabelType } from '../../../../types/types';
import { Modal } from '../../../../shared/components';
import { StateType } from '../../../../store/store';
import { Filter, Sort } from './components';
import { AddNewLabel } from '..';

import './labels-header.css';

const LabelsHeader = () => {
  const [isAddNewLabel, setAddNewLabel] = useState(false);
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );

  const onAddNewLabelToggle = () => setAddNewLabel(!isAddNewLabel);
  const onSaveLabel = (label: addNewLabelType) => {
    dispatch(createLabel(loginToken, label));
    onAddNewLabelToggle();
  };

  return (
    <>
      <div className="labels-header__control-panel">
        <Sort />
        <Filter />
        <BigRedButton onClick={onAddNewLabelToggle} style={{ width: '125px' }}>
          Add new label
        </BigRedButton>
      </div>
      <Modal open={isAddNewLabel} onClose={onAddNewLabelToggle} maxWidth={560}>
        <AddNewLabel onSubmit={onSaveLabel} onCancel={onAddNewLabelToggle} />
      </Modal>
    </>
  );
};

export default LabelsHeader;
