import ActionTypes from '../../action-types/action-types';

type contentReducerType = {
  loadCounter: number,
}

const initialState = {
  loadCounter: 0,
};

type initialStateType = contentReducerType;

export const contentReducer = (
  state: initialStateType = initialState,
  action: ContentActionType,
): initialStateType => {
  switch (action.type) {
    case ActionTypes.TRIGGER_GET_POSTS:
      return { ...state, loadCounter: state.loadCounter + 1 };

    default:
      return { ...state };
  }
};

export const triggerGetPostsAction = (
) => ({
  type: ActionTypes.TRIGGER_GET_POSTS,
} as const);

export type ContentActionType =
  | ReturnType<typeof triggerGetPostsAction>
