/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';

import {
  closeEditLabelAction,
  deleteLabel,
  openEditLabelAction,
  setLabelSelectionAction,
} from '../../../../../store/labels/labels.reducer';
import { ILabelItemProps } from './label-item.interfaces';
import { StateType } from '../../../../../store/store';
import { Actions, Modal, Popover } from '../../../../../shared/components';
import { EditLabel } from '../..';

import './label-item.css';
import MoreIcon from '../../../../../shared/icons/more-icon';

const LabelItem: React.FC<ILabelItemProps> = ({
  id,
  name,
  isSelected,
  isEditLabelModalOpen,
}) => {
  const [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );

  const onSelectedItemsCheckbox = () => {
    dispatch(setLabelSelectionAction(id, !isSelected));
  };
  const onMoreButton = () => {
    setMoreMenuOpen((value) => !value);
  };
  const onPopoverClose = () => {
    setMoreMenuOpen(false);
  };
  const onEditButton = () => {
    dispatch(openEditLabelAction(id));
    setMoreMenuOpen(false);
  };
  const onDeleteButton = () => {
    dispatch(deleteLabel(loginToken, id));
    setMoreMenuOpen(false);
  };
  const onClose = () => {
    dispatch(closeEditLabelAction());
  };

  return (
    <>
      <div className="label-item__wrapper">
        <div className="label-item__container">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <div
            role="button"
            tabIndex={0}
            onClick={onSelectedItemsCheckbox}
            onKeyPress={onSelectedItemsCheckbox}
            className={`label-item__checkbox ${
              isSelected ? 'label-item__checkbox--active' : ''
            }`}
          />
          <div className="label-item__name">{name}</div>
        </div>
        <Popover
          open={isMoreMenuOpen}
          onClickOutside={onPopoverClose}
          content={<Actions onEdit={onEditButton} onDelete={onDeleteButton} />}
        >
          <button
            type="button"
            className="labels-content-dropdown__more-button"
            onClick={onMoreButton}
          >
            <MoreIcon />
            <span className="visually-hidden">Open label&apos;s options</span>
          </button>
        </Popover>
      </div>
      <Modal open={isEditLabelModalOpen} onClose={onClose} maxWidth={560}>
        <EditLabel />
      </Modal>
    </>
  );
};

export default React.memo(LabelItem);
