import React, { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { commonPostType } from '../../../../../../types/types';
import { StateType } from '../../../../../../store/store';
import Pagination from '../pagination/pagination';
import { IPostsProps } from './posts.interfaces';
import { Post } from '..';

const showOnPage = +(process.env.REACT_APP_SHOW_ON_PAGE || 10);

const Posts: FC<IPostsProps> = ({ page }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const posts = useSelector<StateType, Array<commonPostType>>(
    (state) => state.posts.data,
  );
  const totalPosts = useSelector<StateType, number | null>(
    (state) => state.posts.total,
  );
  const isLoading = useSelector<StateType, boolean>(
    (state) => state.posts.isLoading,
  );

  useEffect(() => {
    if (!isLoading) {
      contentRef.current?.scrollTo({ top: 0 });
    }
  }, [isLoading]);

  return (
    <>
      {posts.length === 0 && (
        <div className="content__empty">No data to display</div>
      )}
      <div
        ref={contentRef}
        className={`content__posts ${
          isLoading ? 'content__posts-disabled' : ''
        }`}
      >
        {posts.length !== 0
          && posts.map((post) => {
            let accountName = '';
            let accountUrl = '';
            let publishDate = 0;
            let message = 'There is no type for this post';
            let id = '-1';
            let agree = 'agree';
            let type = 'unknown';
            let profileImage = '';

            if (post.type === 'facebook') {
              accountName = post.account?.name;
              accountUrl = post.account?.url;
              publishDate = post.publishDate;
              message = post.message;
              agree = 'Agree';
              id = post.id;
              type = post.type;
              profileImage = post.account?.profileImage;
            }

            if (post.type === 'instagram') {
              accountName = post.account?.name;
              accountUrl = post.account?.url;
              publishDate = post.publishDate;
              message = post.description;
              agree = 'Agree';
              id = post.id;
              type = post.type;
              profileImage = post.account?.profileImage;
            }

            if (post.type === 'twitter') {
              accountName = post.account?.username;
              accountUrl = post.account?.url;
              publishDate = post.publishDate;
              message = post.text;
              id = post.id;
              type = post.type;
              profileImage = post.account?.profileImage;
            }

            if (post.type === 'news') {
              accountName = post.sourceName;
              accountUrl = post.sourceDomain;
              publishDate = post.publishDate;
              message = post.headline;
              id = post.id;
              type = post.type;
              profileImage = post.imageUrl;
            }

            return (
              <Post
                accountName={accountName}
                accountUrl={accountUrl}
                publishDate={publishDate}
                message={message}
                agree={agree}
                id={id}
                type={type}
                key={id}
                profileImage={profileImage}
                labels={post.labels}
              />
            );
          })}
      </div>
      {!!posts.length && !!totalPosts && totalPosts > showOnPage && (
        <Pagination page={page} show={showOnPage} total={totalPosts} />
      )}
    </>
  );
};

export default Posts;
