import React from 'react';

const LeftArrowIcon = () => (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.01744 8.96718C5.30994 8.67468 5.30994 8.20218 5.01744 7.90968L2.10744 4.99968L5.01744 2.08968C5.30994 1.79718 5.30994 1.32468 5.01744 1.03218C4.72494 0.739677 4.25244 0.739677 3.95994 1.03218L0.517441 4.47468C0.224941 4.76718 0.224941 5.23968 0.517441 5.53218L3.95994 8.97468C4.24494 9.25968 4.72494 9.25968 5.01744 8.96718Z"
      fill="#141414"
    />
  </svg>
);

export default LeftArrowIcon;
