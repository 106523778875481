import React, { useState } from 'react';

import SmallWhiteButton from '../../shared/components/buttons/small-white-button';
import { Login, Registration } from './components';

import './auth-page.css';

const AuthPage = () => {
  const [auth, setAuth] = useState(false);

  return (
    <div className="auth__wrapper">
      <div className="auth">
        <h2 className="auth_title">Happening now</h2>
        <h3 className="auth_title">Join Project Name today.</h3>
        {auth ? <Login /> : <Registration />}
        <div className="auth__toggle-container">
          <span className="auth__toggle-text">
            {auth ? 'Don’t have an account?' : 'Already have an account?'}
          </span>
          <SmallWhiteButton onClick={() => setAuth(!auth)}>
            {auth ? 'Sign up' : 'Sign in'}
          </SmallWhiteButton>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
