import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import { setGridAction } from '../../../../store/grid-toggle/grid-toggle.reducer';
import ListIcon from '../../../../shared/icons/list-icon';
import GridIcon from '../../../../shared/icons/grid-icon';
import { StateType } from '../../../../store/store';

const Grid = () => {
  const dispatch = useDispatch();

  const isGridOn = useSelector<StateType, boolean>(
    (state) => state.gridToggle.isGridOn,
  );

  const toggleTile = (value: boolean) => () => dispatch(setGridAction(value));

  return (
    <div className="toggle__container">
      <button
        type="button"
        className={
          isGridOn
            ? 'topic__view-toggle-button'
            : 'topic__view-toggle-button topic__view-toggle-button--active'
        }
        name="view-toggle-list"
        onClick={toggleTile(false)}
      >
        <div className="topic__icon-wrapper">
          <ListIcon />
        </div>
      </button>
      <button
        type="button"
        className={
          isGridOn
            ? 'topic__view-toggle-button topic__view-toggle-button--active'
            : 'topic__view-toggle-button'
        }
        onClick={toggleTile(true)}
        name="view-toggle-grid"
      >
        <div className="topic__icon-wrapper">
          <GridIcon />
        </div>
      </button>
    </div>
  );
};

export default Grid;
