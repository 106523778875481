import moment from 'moment';
import React from 'react';

import { IRightContainerProps } from './right-container.interfaces';
import {
  Facebook,
  Instagram,
  OpinionItem,
  ThemeItem,
  Twitter,
} from './components';

const RightContainer: React.FC<IRightContainerProps> = ({
  post,
  onAddLabel,
}) => {
  const isInstagramType = post.type === 'instagram';
  const isFacebookType = post.type === 'facebook';
  const isTwitterType = post.type === 'twitter';

  const timeFormatOutput = 'MMMM DD, YYYY / HH:mm';

  const { publishDate, postUrl, labels } = post;
  let platform = '';

  if (isFacebookType) {
    platform = 'Facebook';
  }
  if (isInstagramType) {
    platform = 'Instagram';
  }
  if (isTwitterType) {
    platform = 'Twitter';
  }

  const opinions = labels.filter((el) => el.type === 'Opinion');
  const themes = labels.filter((el) => el.type === 'Theme');

  return (
    <div className="post-popup__right-container">
      <p className="post-popup__data-name">Platform</p>
      <p className="post-popup__data-content">{platform}</p>
      <p className="post-popup__data-name">Original posting</p>
      <a
        href={postUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="post-popup__data-link"
      >
        <p className="post-popup__data-content post-popup__data-content--original-post-link">
          {postUrl}
        </p>
      </a>
      <p className="post-popup__data-name">Posting date / time</p>
      <p className="post-popup__data-content">
        {moment.unix(publishDate).format(timeFormatOutput)}
      </p>
      {isFacebookType && <Facebook post={post} />}
      {isInstagramType && <Instagram post={post} />}
      {isTwitterType && <Twitter post={post} />}
      <div className="post-popup__labels-container">
        <div className="post-popup__labels-header">
          <p className="post-popup__data-name">Labels</p>
          <div
            role="button"
            tabIndex={0}
            className="post-popup__add-label-link"
            onClick={onAddLabel}
            onKeyPress={onAddLabel}
          >
            Add label
          </div>
        </div>
        <div className="post-popup__label-opinion-wrapper">
          {themes.map((label) => (
            <ThemeItem
              key={label.id}
              data={label}
              post={post}
            />
          ))}
          {opinions.map((label) => (
            <OpinionItem
              key={label.id}
              data={label}
              post={post}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RightContainer;
