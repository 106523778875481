import React, { ChangeEvent, useState } from 'react';

import SmallWhiteButton from '../../../../shared/components/buttons/small-white-button';
import SmallRedButton from '../../../../shared/components/buttons/small-red-button';
import Input from '../../../../shared/components/Input/input';

import { IRecoveryForm, IRecoveryFormProps } from './form.interfaces';

const initialForm: IRecoveryForm = {
  email: '',
};

const Form: React.FC<IRecoveryFormProps> = ({ onCancel, onSubmit }) => {
  const [fields, setFields] = useState(initialForm);

  const { email } = fields;

  const onChangeEmailInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFields((oldFields) => ({ ...oldFields, [name]: value }));
  };

  return (
    <>
      <Input
        value={email}
        type="email"
        name="email"
        placeholder="Enter email"
        onChangeCallback={onChangeEmailInput}
      />
      <div className="reg__button-wrapper">
        <SmallWhiteButton onClick={onCancel}>Cancel</SmallWhiteButton>
        <SmallRedButton type="submit" onClick={() => onSubmit(fields)}>Send</SmallRedButton>
      </div>
    </>
  );
};

export default Form;
