import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import SmallWhiteButton from '../../../../../../../../shared/components/buttons/small-white-button';
import isObjectNameIncludes from '../../../../../../../../helpers/is-object-name-includes';
import sortObjectsByName from '../../../../../../../../helpers/sort-objects-by-name';
import Input from '../../../../../../../../shared/components/Input/input';
import { IAddLabelFullscreenProps } from './add-label-fullscreen.interfaces';
import { labelToPostType } from '../../../../../../../../types/types';
import { StateType } from '../../../../../../../../store/store';
import { HeaderPost, LabelItem } from './components';

import './add-label-fullscreen.css';

const tabs: Record<string, string> = {
  Opinions: 'Opinion',
  Themes: 'Theme',
};
const tabNames = Object.keys(tabs);

const AddLabelFullscreen: React.FC<IAddLabelFullscreenProps> = ({
  post,
  onClose,
}) => {
  const [searchLabel, setSearchLabel] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const attachedLabelsIds = post.labels.map((el) => el.id);

  const labels = useSelector<StateType, Array<labelToPostType>>(
    (state) => state.addLabelPopup,
  )
    .filter((label) => label.type === tabs[tabNames[activeTab]])
    .filter((el) => !attachedLabelsIds.includes(el.id))
    .filter((el) => !el.isVoted)
    .slice()
    .sort(sortObjectsByName)
    .filter(isObjectNameIncludes(searchLabel));

  const onSwitchTab = (tabIndex: number) => () => {
    setActiveTab(tabIndex);
  };
  const onChangeSearchLabel = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearchLabel(value);
  };

  return (
    <>
      <div className="add-label-fullscreen__header">
        <HeaderPost post={post} />
        <div className="add-label-fullscreen__header-toggle-wrapper">
          {tabNames.map((tab, index) => (
            <button
              key={tab}
              type="button"
              className={
                tabNames[activeTab] === tab
                  ? 'add-label-popup__toggle-button add-label-popup__toggle-button--active'
                  : 'add-label-popup__toggle-button'
              }
              onClick={onSwitchTab(index)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="add-label-fullscreen__header-search-container">
          <Input
            value={searchLabel}
            type="searchOpinion"
            name="searchOpinion"
            placeholder="Search"
            onChangeCallback={onChangeSearchLabel}
            style={{ paddingLeft: '38px' }}
          />
        </div>
        <SmallWhiteButton onClick={onClose} style={{ height: '40px' }}>
          Exit
        </SmallWhiteButton>
      </div>
      <div className="add-label-fullscreen__content">
        {labels.map((el) => (
          <LabelItem
            key={el.id}
            name={el.name}
            id={el.id}
            type={el.type}
            postId={post.id}
            postSource={post.type}
          />
        ))}
      </div>
    </>
  );
};

export default AddLabelFullscreen;
