import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler';

import {
  LoginActionType,
  loginReducer,
} from './login/login.reducer';
import {
  MainActionType,
  mainReducer,
} from './auth/auth.reducer';
import {
  ErrorActionType,
  errorReducer,
} from './error/error.reducer';
import {
  ManageViewsActionType,
  manageViewsReducer,
} from './manage-views/manage-views.reducer';
import {
  SortByActionType,
  sortByReducer,
} from './sort-by/sort-by.reducer';
import {
  GridToggleActionType,
  gridToggleReducer,
} from './grid-toggle/grid-toggle.reducer';
import {
  addLabelPopupReducer,
  AddLabelPopupActionType,
} from './add-label-popup/add-label-popup.reducer';
import {
  addNewTopicReducer,
  AddNewTopicActionType,
} from './add-new-topic/add-new-topic.reducer';
import {
  TopicsActionType,
  topicsReducer,
} from './topics/topics.reducer';
import {
  EditTopicActionType,
  editTopicReducer,
} from './edit-topic/edit-topic.reducer';
import {
  PostsActionType,
  postsReducer,
} from './posts/posts.reducer';
import {
  LabelsActionType,
  labelsReducer,
} from './labels/labels.reducer';
import {
  LabelsHeaderFilterActionType,
  labelsHeaderFilterReducer,
} from './labels-header-filter/labels-header-filter.reducer';
import {
  FilterReducerType,
  filterReducer,
} from './filter/filter.reducer';
import {
  ContentActionType,
  contentReducer,
} from './content/content.reducer';
import { ThemeActionType, themeReducer } from './theme/theme.reducer';
import { axiosErrorHandler } from '../helpers/axios-errors';

const rootReducer = combineReducers({
  main: mainReducer,
  login: loginReducer,
  error: errorReducer,
  manageViews: manageViewsReducer,
  sortBy: sortByReducer,
  gridToggle: gridToggleReducer,
  addLabelPopup: addLabelPopupReducer,
  addNewTopic: addNewTopicReducer,
  topics: topicsReducer,
  editTopic: editTopicReducer,
  posts: postsReducer,
  labels: labelsReducer,
  labelsHeaderFilter: labelsHeaderFilterReducer,
  filter: filterReducer,
  content: contentReducer,
  theme: themeReducer,
});

const errorHandlerMiddleware = createThunkErrorHandlerMiddleware({ onError: axiosErrorHandler });

export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(errorHandlerMiddleware, thunk),
  ),
);

export type StateType = ReturnType<typeof rootReducer>;

type AppActionType =
  | LoginActionType
  | MainActionType
  | ErrorActionType
  | ManageViewsActionType
  | SortByActionType
  | GridToggleActionType
  | AddLabelPopupActionType
  | AddNewTopicActionType
  | TopicsActionType
  | EditTopicActionType
  | PostsActionType
  | LabelsActionType
  | LabelsHeaderFilterActionType
  | FilterReducerType
  | ContentActionType
  | ThemeActionType;

export type AppThunkType<ReturnType = void> = ThunkAction<
  ReturnType,
  StateType,
  unknown,
  AppActionType
>

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.store = store;
