import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  makeAgreeLabelAction,
  makeDisagreeLabelAction,
} from '../../../../../../../../../../store/add-label-popup/add-label-popup.reducer';
import SmallGreenButton from '../../../../../../../../../../shared/components/buttons/small-green-button';
import SmallRedButton from '../../../../../../../../../../shared/components/buttons/small-red-button';
import { StateType } from '../../../../../../../../../../store/store';
import { addLabelToPost } from '../../../../../../../../../../store/posts/posts.reducer';
import { ILabelItemProps } from './label-item.interfaces';

import './label-item.css';

const LabelItem: React.FC<ILabelItemProps> = ({
  name,
  type,
  id,
  postId,
  postSource,
}) => {
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );

  const onAgreeLabel = () => {
    dispatch(makeAgreeLabelAction(id));
    dispatch(addLabelToPost(loginToken, postSource, postId, id));
  };
  const onDisagreeLabel = () => {
    dispatch(makeDisagreeLabelAction(id));
    dispatch(addLabelToPost(loginToken, postSource, postId, id));
  };
  const onSelectLabel = () => {
    dispatch(makeDisagreeLabelAction(id));
    dispatch(addLabelToPost(loginToken, postSource, postId, id));
  };

  return (
    <div className="label-fullscreen-item">
      <p className="label-fullscreen-item__name">{name}</p>
      <div className="label-fullscreen-item__buttons-wrapper">
        {type === 'Opinion' && (
          <>
            <SmallGreenButton
              style={{ margin: '0 24px 0 0' }}
              onClick={onAgreeLabel}
            >
              Agree
            </SmallGreenButton>
            <SmallRedButton style={{ margin: 0 }} onClick={onDisagreeLabel}>
              Disagree
            </SmallRedButton>
          </>
        )}
        {type === 'Theme' && (
          <SmallRedButton style={{ margin: 0 }} onClick={onSelectLabel}>
            Select
          </SmallRedButton>
        )}
      </div>
    </div>
  );
};

export default LabelItem;
