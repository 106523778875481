import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';

import { registrationCredentials } from '../../store/login/login.reducer';
import {
  IPasswordStepForm,
  IUserStepForm,
  PasswordStep,
  UserStep,
} from './components';

import './registration-page.css';

const initialForm: IPasswordStepForm & IUserStepForm = {
  repeatPassword: '',
  username: '',
  password: '',
  email: '',
};

const RegistrationPage = () => {
  const [nextStep, setNextStep] = useState(true);
  const [form, setForm] = useState(initialForm);
  const history = useHistory();
  const dispatch = useDispatch();

  const onNextStepClickButton = (fields: IUserStepForm) => {
    setForm((oldForm) => ({ ...oldForm, ...fields }));
    setNextStep(!nextStep);
  };

  const onPrevStepClickButton = () => {
    setNextStep(!nextStep);
  };

  const onRegClickButton = (fields: IPasswordStepForm) => {
    const { email, username, password } = { ...form, ...fields };

    dispatch(registrationCredentials(email, username, password));
  };

  const onClickBackAuthButton = () => {
    history.push('/');
  };

  return (
    <div className="registration__wrapper">
      <form className="registration" onSubmit={(e) => e.preventDefault()}>
        <h2 className="reg_title">Create your account</h2>
        {nextStep ? (
          <UserStep
            onBackStep={onClickBackAuthButton}
            onNextStep={onNextStepClickButton}
          />
        ) : (
          <PasswordStep
            onBackStep={onPrevStepClickButton}
            onSubmit={onRegClickButton}
          />
        )}
      </form>
    </div>
  );
};

export default RegistrationPage;
