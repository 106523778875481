/* eslint-disable jsx-a11y/control-has-associated-label */
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import { StateType } from '../../../../../../../../store/store';
import {
  toggleFacebookCheckboxAction,
  toggleInstagramCheckboxAction,
  toggleNewsCheckboxAction,
  toggleTwitterCheckboxAction,
} from '../../../../../../../../store/filter/filter.reducer';

const SourcesTab = () => {
  const dispatch = useDispatch();

  const twitterCheckbox = useSelector<StateType, boolean>(
    (state) => state.filter.sources.twitter,
  );
  const facebookCheckbox = useSelector<StateType, boolean>(
    (state) => state.filter.sources.facebook,
  );
  const newsCheckbox = useSelector<StateType, boolean>(
    (state) => state.filter.sources.news,
  );
  const instagramCheckbox = useSelector<StateType, boolean>(
    (state) => state.filter.sources.instagram,
  );

  const onClickTwitterCheckbox = () => {
    dispatch(toggleTwitterCheckboxAction());
  };
  const onClickFacebookCheckbox = () => {
    dispatch(toggleFacebookCheckboxAction());
  };
  const onClickInstagramCheckbox = () => {
    dispatch(toggleInstagramCheckboxAction());
  };
  const onClickNewsCheckbox = () => {
    dispatch(toggleNewsCheckboxAction());
  };

  return (
    <div className="filter__social-network-wrapper">
      <div className="filter__social-network-block">
        <div
          role="button"
          tabIndex={0}
          onClick={onClickTwitterCheckbox}
          onKeyPress={onClickTwitterCheckbox}
          className={`filter__social-network-checkbox ${
            twitterCheckbox
              ? 'social-checkbox-twitter-active'
              : 'social-checkbox-twitter'
          }`}
        />
        <p className="filter__checkbox-source">Twitter</p>
      </div>
      <div className="filter__social-network-block">
        <div
          role="button"
          tabIndex={0}
          onClick={onClickFacebookCheckbox}
          onKeyPress={onClickFacebookCheckbox}
          className={`filter__social-network-checkbox ${
            facebookCheckbox
              ? 'social-checkbox-facebook-active'
              : 'social-checkbox-facebook'
          }`}
        />
        <p className="filter__checkbox-source">Facebook</p>
      </div>
      <div className="filter__social-network-block">
        <div
          role="button"
          tabIndex={0}
          onClick={onClickNewsCheckbox}
          onKeyPress={onClickNewsCheckbox}
          className={`filter__social-network-checkbox ${
            newsCheckbox
              ? 'social-checkbox-news-active'
              : 'social-checkbox-news'
          }`}
        />
        <p className="filter__checkbox-source">News</p>
      </div>
      <div className="filter__social-network-block">
        <div
          role="button"
          tabIndex={0}
          onClick={onClickInstagramCheckbox}
          onKeyPress={onClickInstagramCheckbox}
          className={`filter__social-network-checkbox ${
            instagramCheckbox
              ? 'social-checkbox-instagram-active'
              : 'social-checkbox-instagram'
          }`}
        />
        <p className="filter__checkbox-source">Instagram</p>
      </div>
    </div>
  );
};

export default SourcesTab;
