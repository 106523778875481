import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';

import { getLabels } from '../../store/labels/labels.reducer';
import { Header, Navbar } from '../../shared/components';
import { LabelsContent, LabelsHeader } from './components';
import { StateType } from '../../store/store';

import './labels-page.css';

const LabelsPage = () => {
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );

  useEffect(() => {
    dispatch(getLabels(loginToken));
  }, []);

  return (
    <div className="labels-main-page__wrapper">
      <Navbar />
      <Header title="Labels">
        <LabelsHeader />
      </Header>
      <LabelsContent />
    </div>
  );
};

export default LabelsPage;
