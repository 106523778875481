import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeLabelFromPost } from '../../../../../../../../../../store/posts/posts.reducer';
import CloseIcon from '../../../../../../../../../../shared/icons/close-icon';
import { StateType } from '../../../../../../../../../../store/store';
import { IThemeItemProps } from './theme-item.interfaces';

const ThemeItem: React.FC<IThemeItemProps> = ({ post, data }) => {
  const dispatch = useDispatch();

  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );

  const { id: postId, type: postSource } = post;
  const { name, id } = data;

  const onButtonDetach = () => {
    dispatch(removeLabelFromPost(loginToken, postSource, postId, id));
  };

  return (
    <div className="post-popup__label-theme">
      <div className="post-popup__label-theme-layer" />
      <h5 className="post-popup__label-theme-name">Theme</h5>
      <p className="post-popup__label-theme-title">{name}</p>
      <div
        role="button"
        tabIndex={0}
        className="post-popup__detach-label"
        onClick={onButtonDetach}
        onKeyPress={onButtonDetach}
      >
        <CloseIcon />
        <span className="visually-hidden">Detach label from post</span>
      </div>
    </div>
  );
};

export default ThemeItem;
