import { useSelector } from 'react-redux';
import React from 'react';

import LabelsContentDropdown from './labels-content-dropdown/labels-content-dropdown';
import sortObjectsByName from '../../../../helpers/sort-objects-by-name';
import { StateType } from '../../../../store/store';
import { labelType } from '../../../../types/types';
import LabelItem from './label-item/label-item';

import './labels-content.css';

const LabelsContent = () => {
  const isSortByDateCreation = useSelector<StateType, boolean>(
    (state) => state.labelsHeaderFilter.isSortByDateCreation,
  );
  const isOpinionsOn = useSelector<StateType, boolean>(
    (state) => state.labelsHeaderFilter.isOpinionsOn,
  );
  const labelsFromStore = useSelector<StateType, Array<labelType>>(
    (state) => state.labels,
  );

  const labelsFilteredByType = labelsFromStore.filter((el) => (
    isOpinionsOn ? el.type === 'Opinion' : el.type === 'Theme'
  ));
  const labelsSortedByNameOrDate = isSortByDateCreation
    ? labelsFilteredByType
    : labelsFilteredByType.slice().sort(sortObjectsByName);

  const labels = labelsSortedByNameOrDate;

  const selectedItemsIds = labels.reduce(
    (acc: Array<number>, el) => (el.isSelected ? [...acc, el.id] : acc),
    [],
  );

  return (
    <div className="labels-content__wrapper">
      <LabelsContentDropdown selectedItemsIds={selectedItemsIds} />
      <div className="label-list">
        {labels.map((el) => (
          <LabelItem
            key={el.id}
            id={el.id}
            name={el.name}
            isSelected={el.isSelected}
            isEditLabelModalOpen={el.isEditLabelModalOpen}
          />
        ))}
      </div>
    </div>
  );
};

export default LabelsContent;
