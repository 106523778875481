import { useDispatch, useSelector } from 'react-redux';
import React, {
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  createView,
  makeViewActive,
  putView,
} from '../../../../store/manage-views/manage-views.reducer';
import { triggerGetPostsAction } from '../../../../store/content/content.reducer';
import setDataFromView from '../../../../helpers/set-data-from-view';
import StarIcon from '../../../../shared/icons/star-icon';
import { addNewViewType, viewFromServerType } from '../../../../types/types';
import { resetFilterAction } from '../../../../store/filter/filter.reducer';
import ManageView from './components/manage-views/manage-views';
import { AddNewView, View, ViewActions } from './components';
import { StateType } from '../../../../store/store';
import { ISavedViewsProps } from './saved-views.interfaces';
import Modal from '../../../../shared/components/modal/modal';
import { clearStats } from '../../../../store/posts/posts.reducer';

const SavedViews: React.FC<ISavedViewsProps> = ({ topicId }) => {
  const [isSavedViewsOpen, setSavedViewsOpen] = useState(false);
  const [isManageViewsOpen, setManageViewsOpen] = useState(false);
  const [isAddNewViewOpen, setAddNewViewOpen] = useState(false);
  const dispatch = useDispatch();

  const savedViewsButtonRef = useRef<HTMLButtonElement>(null);
  const savedViewsRef = useRef<HTMLDivElement>(null);

  const views = useSelector<StateType, Array<viewFromServerType>>(
    (state) => state.manageViews,
  );
  const loginToken = useSelector<StateType, string>(
    (state) => state.login.token,
  );

  const activeView = views.filter((view) => view.is_active)[0];

  const toggleManageViewsOpen = () => setManageViewsOpen(!isManageViewsOpen);
  const toggleSavedViewsOpen = () => setSavedViewsOpen(!isSavedViewsOpen);
  const toggleAddNewViewOpen = () => setAddNewViewOpen(!isAddNewViewOpen);

  const onResetViewCallback = () => {
    toggleSavedViewsOpen();
    dispatch(clearStats());
    dispatch(resetFilterAction());
    dispatch(triggerGetPostsAction());
  };
  const onAddNewViewCallback = (newView: addNewViewType) => {
    dispatch(createView(loginToken, newView));
    setAddNewViewOpen(false);
    toggleAddNewViewOpen();
  };
  const onClickMakeActive = (view: viewFromServerType) => {
    const newView: viewFromServerType = {
      ...view,
      is_active: !view.is_active,
    };

    if (view.is_active) {
      dispatch(putView(loginToken, view.id, newView));
      dispatch(resetFilterAction());
    } else {
      dispatch(makeViewActive(loginToken, view.id, newView));
      setDataFromView(newView, dispatch);
    }

    dispatch(clearStats());
    dispatch(triggerGetPostsAction());
    setSavedViewsOpen(!isSavedViewsOpen);
  };

  useEffect(() => {
    const callback = (event: MouseEvent) => {
      if (
        isSavedViewsOpen
        && !savedViewsRef.current?.contains(event.target as Node)
        && !savedViewsButtonRef.current?.contains(event.target as Node)
      ) {
        setSavedViewsOpen(!isSavedViewsOpen);
      }
    };

    window.addEventListener('click', callback as any);

    return () => {
      window.removeEventListener('click', callback as any);
    };
  }, [isSavedViewsOpen]);

  return (
    <>
      <div className="topic__control-save-wrapper">
        <button
          type="button"
          name="save"
          className="topic__control-save"
          onClick={toggleSavedViewsOpen}
          ref={savedViewsButtonRef}
        >
          <span className="save__icon-wrapper">
            <StarIcon />
          </span>
          Saved views
        </button>
        {isSavedViewsOpen && (
          <div className="saved-views-wrapper" ref={savedViewsRef}>
            <div className="saved-views-top-container">
              {views.map((view) => (
                <View key={view.id} data={view} onClick={onClickMakeActive} />
              ))}
            </div>
            <ViewActions
              view={activeView}
              onOpenManage={toggleManageViewsOpen}
              onResetView={onResetViewCallback}
              onUpdateView={toggleSavedViewsOpen}
              onOpenAddView={toggleAddNewViewOpen}
            />
          </div>
        )}
      </div>
      <Modal open={isManageViewsOpen} onClose={toggleManageViewsOpen}>
        <ManageView
          onAddNewView={() => {
            toggleManageViewsOpen();
            toggleAddNewViewOpen();
          }}
        />
      </Modal>
      <Modal open={isAddNewViewOpen} onClose={toggleAddNewViewOpen}>
        <AddNewView
          topicId={topicId}
          onClose={toggleAddNewViewOpen}
          onSubmit={onAddNewViewCallback}
        />
      </Modal>
    </>
  );
};

export default SavedViews;
