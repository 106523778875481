import { AxiosResponse } from 'axios';

import {
  IAuthResponse,
  IAuthUserResponse,
  IForgotPasswordRequest,
  IResetPasswordRequest,
  ISignUpRequest,
} from '../interfaces/server';
import { instance } from '../api/api';

export default {
  registration: (email: string, username: string, password: string) => (
    instance.post<ISignUpRequest, AxiosResponse<IAuthResponse>>(
      'auth/sign-up/', { email, username, password },
    )
  ),
  login: (username: string, password: string) => (
    instance.post<string, AxiosResponse<IAuthResponse>>(
      'auth/sign-in/?is_privileged=false',
      `username=${username}&password=${password}`,
    )
  ),
  refreshAccessToken: (refreshToken: string) => (
    instance.post<unknown, AxiosResponse<Omit<IAuthResponse, 'refresh_token'>>>(
      'auth/refresh',
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      },
    )
  ),
  passwordRecovery: (email: string) => (
    instance.post<IForgotPasswordRequest, AxiosResponse<string>>(
      'auth/forgot-password/', { email },
    )
  ),
  setNewPassword: (password: string, token: string) => (
    instance.put<IResetPasswordRequest, AxiosResponse<string>>(
      'auth/reset-password/', { password, token },
    )
  ),
  userProfile: (token: string) => (
    instance.get<unknown, AxiosResponse<IAuthUserResponse>>('auth/user/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ),
  loginByMicrosoft:
    (token: string) => instance.get<
      unknown, AxiosResponse<IAuthResponse>
    >(
      `/external-auth/token/outlook?access_token=${token}`,
    ),
  loginByGoogle: (token: string) => instance.get<
    unknown, AxiosResponse<IAuthResponse>
  >(
    `/external-auth/token/google?access_token=${token}`,
  ),
};
