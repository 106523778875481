import React from 'react';

import { IOpinionItemSimpleProps } from './opinion-item-simple.interfaces';

const OpinionItemSimple: React.FC<IOpinionItemSimpleProps> = ({
  name,
  agree,
}) => (
  <div
    className={`${'post__label-opinion'} ${
      agree ? 'post__label-opinion--green' : 'post__label-opinion--red'
    }`}
  >
    <h5 className="post__label-opinion-title">
      Option
      <p
        className={`${'post__agree-text'} ${
          agree ? 'post__agree-text--green' : 'post__agree-text--red'
        }`}
      >
        {agree ? 'Agree' : 'Do not agree'}
      </p>
    </h5>
    <p className="post__opinion-text">{name}</p>
  </div>
);

export default OpinionItemSimple;
